import { TOptions } from "i18next";

export function parseServerResponse(code: Response["status"],bodyAsString: string): [string, TOptions | undefined] {
    const specialKeys = ["unknownError", "payloadCheck", "transactionError"];
    for (const key of specialKeys) {
        if (!bodyAsString.startsWith(key)) continue;
        const payload = bodyAsString.replace(`${key}.`, "");
        return [`400.${key}`, {payload: payload}];
    }
    if (bodyAsString === "Failed to fetch") return ["500.serverIsDown", undefined];
    if (bodyAsString.split(" ").length > 1) return [`400.${specialKeys[0]}`, {payload: bodyAsString }];
    return [`${code}.${bodyAsString}`, undefined];
};
