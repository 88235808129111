import React, { FunctionComponent, useState } from "react";
import { Container, Text, Icon } from "@common/components";
import { StyledNavbar } from "./Navbar.styles";
import { TNavbarProps } from "./Navbar.types";
import { Box } from "../Box";
import { useTheme } from "styled-components";
import { Outlet, useNavigate } from "react-router";
import { Donkey, Thing } from "../Icon";
import { StyledResponseNote } from "../ResponseNote/ResponseNote.styles";
import { colors } from "@theme";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const Navbar: FunctionComponent<TNavbarProps> = ({
    options,
    isotype,
    isotypeAction,
    children,
    ...props
}) => {
    const {t: translateNote} = useTranslation<TTranslationNameSpace>("notes");
    const theme = useTheme();
    const [noticeSeen, setNoticeSeen] = useState<boolean>();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <StyledNavbar role="navigation" {...props}>
                <Container
                    xs="0px"
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                    <Box full style={{
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Box 
                            loose
                            style={{
                                padding: 0
                            }}>
                            {isotype && (
                                <Icon
                                    size="lg"
                                    role="figure"
                                    onClick={isotypeAction}
                                    style={{
                                        transform: `scaleX(${theme.mode === "light"
                                            ? "1"
                                            : "-1"})`
                                    }}>
                                    <Donkey nature="standard" />
                                </Icon>
                            )}
                            {options?.map((option, index) => (
                                <Box
                                    loose
                                    key={index}
                                    style={{
                                        marginLeft: "0.5rem",
                                        padding: 0,
                                        width: "100%",
                                        maxWidth: "8rem",
                                        overflowX: "hidden"
                                    }}
                                    stylesFrom={{
                                        xs: {
                                            maxWidth: "none"
                                        }}}>
                                    <Text
                                        size="sm" 
                                        style={{
                                            margin: "0",
                                            wordBreak: "normal"
                                        }} 
                                        variant="active" 
                                        onClick={(e: React.MouseEvent) => {
                                            if (option.path === "") return;
                                            navigate(option.path, {
                                                replace: true
                                            });
                                        }}
                                        text={option.name} />
                                </Box>
                            ))}
                        </Box>
                        {children}
                    </Box>
                </Container>
            </StyledNavbar>
            {!noticeSeen && (
                <Box
                    full
                    style={{
                        padding: "0.5rem 1rem 0 1rem",
                        justifyContent: "center"
                    }}>
                    {/* fixed test notice */}
                    <StyledResponseNote
                        aria-label="testing notice"
                        style={{
                            backgroundColor: theme.palette.btnOption,
                            width: "100%",
                            maxWidth: "76rem",
                            paddingBottom: 0
                        }}>
                        <Box 
                            direction="row" 
                            loose
                            style={{
                                justifyContent: "space-between",
                                paddingTop: "0.5rem"
                            }}>
                            <Text 
                                caps
                                size="sm"
                                style={{color: colors.darkPurple}}
                                text={translateNote("stillTesting.title")} />
                            <Icon 
                                onClick={(e: React.MouseEvent) => setNoticeSeen(true)}
                                size="mini"
                                style={{
                                    cursor: "pointer"
                                }}>
                                <Thing nature={"close"} />
                            </Icon>
                        </Box>
                        <Box direction="column" style={{paddingTop: "0"}}>
                            <Text 
                                text={translateNote("stillTesting.text")} 
                                style={{color: colors.white}}/>
                            <a 
                                target="_blank"
                                style={{all: "revert"}}
                                href="https://sepoliafaucet.com/" rel="noreferrer">
                                <Text
                                    text={translateNote("stillTesting.link")} 
                                    onClick={(event) => null}
                                    style={{
                                        marginTop: "0.5rem",
                                        color: colors.darkPurple,
                                        cursor: "pointer"
                                    }} />
                            </a>
                        </Box>
                    </StyledResponseNote>
                </Box>
            )}
            <Outlet />
        </React.Fragment>
    );
};