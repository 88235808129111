import React, { ElementRef, FunctionComponent, useMemo } from "react";
import { Icon } from "@common/components";
import { StyledAvatar } from "./Avatar.styles";
import { TAvatarProps } from "./Avatar.types";
import { Donkey, Thing } from "../Icon";

export const Avatar: FunctionComponent<TAvatarProps> = React.forwardRef<
    ElementRef<"div">, TAvatarProps
>(({
    avatar,
    setAvatar,
    edit = false,
    item = "user",
    ...props
}, ref) => {
    const fieldId = useMemo(() => {
        return `${item}-avatar-${Math.floor(Math.random() * 1000)}`;
    }, [item]);
    return (
        <StyledAvatar 
            aria-label={`${avatar?.avatar
                ? item
                : "anon"}-avatar`}
            role={avatar?.avatar?.secureUrl ? "img" : "none"}
            avatar={avatar}
            edit={edit}
            {...props}
            ref={ref}>
            {edit ? (
                <React.Fragment>
                    <label
                        aria-label="avatar-input-label"
                        htmlFor={fieldId}
                        style={{
                            width: "100%",
                            height: "100%",
                            cursor: edit
                                ? "pointer"
                                : "default"
                        }}>
                        {!avatar?.avatar?.secureUrl && !avatar?.file && (
                            <Icon
                                aria-label="anon-iso"
                                size={props.size}>
                                {item === "user" && (
                                    <Donkey nature="anon" />
                                )}
                                {["federation", "team", "competition"].includes(item) && (
                                    <Thing nature="folder" />
                                )}
                            </Icon>
                        )}
                    </label>
                    <input
                        id={fieldId}
                        type="file"
                        style={{
                            display: "none",
                            width: "100%",
                            height: "100%"
                        }}
                        accept=".png, .jpeg, .jpg"
                        onChange={(event) => {
                            setAvatar &&
                                setAvatar((prev) => ({
                                    ...prev,
                                    file: event.target.files
                                        ? event.target.files[0]
                                        : undefined
                                }));
                        }} />
                    {avatar?.avatar && (
                        <Icon
                            size="md"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                pointerEvents: "none"
                            }}>
                            <Thing nature="folder" />
                        </Icon>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {!avatar?.avatar?.url && (
                        <Icon
                            aria-label="anon-iso"
                            size={props.size}>
                            {item === "user" && (
                                <Donkey nature="anon-color" />
                            )}
                            {["federation", "team", "competition"].includes(item) && (
                                <Thing nature="folder" />
                            )}
                        </Icon>
                    )}
                </React.Fragment>
            )}
        </StyledAvatar>
    );
});

Avatar.displayName = "Avatar";
