import React, { FunctionComponent, useMemo } from "react";
import { TNotFoundProps } from "./NotFound.types";
import { Box, Button, Container, Donkey, Icon, Text, Title } from "@common/components";
import { useLocation, useNavigate } from "react-router";
import { boxFixedWidth } from "@theme";
import { appRoutes } from "@app/routes";
import { useDocumentMeta } from "@common/hooks";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const NotFound: FunctionComponent<TNotFoundProps> = ({
    ...props
}) => {
    const {t: translateMeta} = useTranslation<
        TTranslationNameSpace,
        "metadata"
    >("titles", {keyPrefix: "metadata"});
    const [,] = useDocumentMeta({
        title: translateMeta("notFound.title"),
        description: translateMeta("notFound.description")
    });
    const location = useLocation();
    const navigate = useNavigate();
    const message: string = useMemo(() => {
        const _resources = [
            "resource",
            "chicken",
            "football",
            "job", 
            "missing president",
            "flight"
        ];
        const _resource = location && location.state && location.state.resource
            ? location.state.resource
            : _resources[Math.floor(Math.random() * _resources.length)];
        const _messages: typeof message[] = [
            `even a donkey, being as stubborn as it is, wasn't able to find this ${_resource}...`,
            `the donkey returns to the wheat, after a long and fruitless search for a ${_resource} it couldn't find...`,
            `think about the worrisome certainty that there are no more certainties. No such ${_resource}, btw...`,
            `the donkey was thrown to the mount, and the ${_resource} couldn't be found...`
        ];
        return _messages[
            Math.floor(Math.random() * _messages.length)
        ];
    }, [location]);
    return (
        <Container 
            style={{alignItems: "center"}}>
            <Box
                full
                direction="column"
                style={{
                    alignItems: "center"
                }}>
                <Title
                    text="404"
                    size="xxl" />
                <Box    
                    max={boxFixedWidth}
                    full
                    direction="column"
                    style={{
                        alignItems: "center"
                    }}>
                    <Icon
                        name="shocked donkey"
                        size="huge"
                        nature="shocked"
                        style={{
                            marginBottom: "2rem"
                        }}>
                        <Donkey nature="shocked" />
                    </Icon>
                    <Text
                        aria-label="404 subtitle"
                        caps
                        // text={`no such ${location && location.state && location.state.resource
                        //     ? location.state.resource
                        //     : "resource"}`}
                        text={"Hee-Haw"}
                        variant="title"
                        size="md"
                        component="p" />
                    <Text
                        aria-label="404 message"
                        text={message}
                        variant="special"
                        size="sm" 
                        style={{
                            marginTop: "1rem",
                            textAlign: "center"
                        }}/>
                </Box>
                <Box
                    full
                    style={{
                        justifyContent: "center"
                    }}>
                    <Button
                        caps
                        label="go home"
                        onClick={(event) => navigate(appRoutes.home.url)} />
                </Box>
            </Box>
        </Container>
    );
};
