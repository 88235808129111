import { useEffect, useState } from "react";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { IFetchGames, TFetchGamesData } from "./useFetchGames.types";
import { TGameBody, TGameResponse } from "game/types/Game.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchGames({
    _gameId,
    method,
    data,
    trigger,
    successMessage,
    inform = true,
    options,
    ignoreHeaders,
    qs,
    standings,
    ...props
}: TFetchGamesData): IFetchGames {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("texts", {keyPrefix: "successMessages"});
    const [hookData, setHookData] = useState<TFetchData<TGameBody>>({
        endpointURI: _gameId
            ? endpoints.games.buildUrl({
                _gameId: _gameId,
                standings: standings
                    ? "yes"
                    : undefined
            })
            : endpoints.games.buildUrl({
                qs: qs
            }),
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage
            ? successMessage
            : translateNote("gameSuccessfully", {
                action: method === "POST"
                    ? translateText("createdF")
                    : translateText("updatedF")
            }),
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TGameBody, TGameResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _gameId
                    ? endpoints.games.buildUrl({
                        _gameId: _gameId,
                        standings: standings
                            ? "yes"
                            : undefined
                    }) : endpoints.games.buildUrl({
                        qs: qs
                    }),
                data: data,
                method: method,
                auth: method === "GET"
                    ? false
                    : true,
                successMessage: successMessage
                    ? successMessage
                    : translateNote("gameSuccessfully", {
                        action: method === "POST"
                            ? translateText("createdF")
                            : translateText("updatedF")
                    }),
                inform: inform,
                options: options,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _gameId,
        method,
        successMessage,
        options,
        inform,
        data,
        qs,
        standings,
        translateNote,
        translateText
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};
