import React, { ChangeEvent, FunctionComponent, useMemo } from "react";
import { TLanguageSwitchProps } from "./LanguageSwitch.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { StyledLanguageSwitch, StyledSwitchLanguageInput, StyledSwitchLanguageLabel } from "./LanguageSwitch.styles";

export const LanguageSwitch: FunctionComponent<TLanguageSwitchProps> = (props) => {
    const {i18n} = useTranslation<TTranslationNameSpace>();
    const currentLanguage = useMemo(() => {
        if (i18n.language === "es") return "Español";
        return "English";
    }, [i18n]);
    return (
        <StyledLanguageSwitch
            direction="column"
            style={{
                alignItems: "center",
                padding: 0,
                margin: "0 0.5rem 0 0"
            }}
            stylesFrom={{
                xs: {
                    margin: "0 1.5rem 0 0"
                }
            }}>
            <StyledSwitchLanguageInput 
                id="language-switch" 
                type="checkbox"
                checked={i18n.language === "en"}
                onChange={(event: ChangeEvent) => i18n.changeLanguage(
                    i18n.language === "en" 
                        ? "es"
                        : "en"
                )}
                language={i18n.language}/>
            <StyledSwitchLanguageLabel
                aria-label="language-switch-container"  
                htmlFor="language-switch">
                {currentLanguage}
            </StyledSwitchLanguageLabel>
        </StyledLanguageSwitch>
    );
};
