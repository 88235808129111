import React, { FunctionComponent } from "react";
import { TIconHeaderProps } from "./IconHeader.types";
import { Box, Icon, Text } from "@common/components";

export const IconHeader: FunctionComponent<TIconHeaderProps> = ({
    title,
    _Icon,
    texts=[],
    style,
    ...props
}) => {
    return (
        <Box
            full
            direction="column"
            style={{
                marginTop: "2rem",
                alignItems: "center",
                padding: 0, 
                ...style
            }} {...props}>
            <Icon
                size="xxl">
                {_Icon}
            </Icon>
            <Text
                size="md"
                text={title}
                variant="title"
                style={{
                    textAlign: "center", 
                    marginBottom: "1.5rem"
                }}
                caps
                component="h3" />
            {texts.map((text, index, arr) => (
                <Text
                    key={index}
                    text={text}
                    size="xs"
                    style={{
                        marginBottom: arr.length - 1 > index 
                            ? "1rem"
                            : "0"
                    }} />   
            ))}
        </Box>
    );
};
