import { IFetch, TFetchData } from "@common/hooks";

export type TValidationNature = "confirm-email" |
    "confirm-email-update" |
    "confirm-password-reset-request";

export type TFetchValidationsData = TFetchData<Record<string, never>> & {
    token?: string;
    nature?: TValidationNature; 
};

export const validationNatures: Record<TValidationNature, string> = {
    "confirm-email": "confirm_email",
    "confirm-email-update": "confirm_email_update",
    "confirm-password-reset-request": "confirm_password_reset_request"
};

export type IFetchValidations = IFetch<{message: string;}>;

export const validationSuccessMessages: Record<TValidationNature, string> = {
    "confirm-email": "confirmEmail",
    "confirm-email-update": "confirmEmailUpdate",
    "confirm-password-reset-request": "confirmPasswordResetRequest"
};