import { TFetchData, useFetch } from "@common/hooks/useFetch";
import { useEffect, useState } from "react";
import { endpoints } from "@common/endpoints";
import { IFetchPasswordResetRequests, TFetchPasswordResetRequestData, TPasswordResetRequestBody, TPasswordResetRequestPatchBody } from "./useFetchPasswordResetRequests.types";

export function useFetchPasswordResetRequests({
    method,
    data,
    successMessage,
    options={},
    inform=true,
    auth=true,
    trigger=false
}: TFetchPasswordResetRequestData): IFetchPasswordResetRequests {
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchData<TPasswordResetRequestBody | TPasswordResetRequestPatchBody>>({
        endpointURI: endpoints.passwordResetRequests.url,
        method,
        data,
        inform: false,
        options, 
        auth,
        trigger
    });
    const {
        response,
        code,
        body,
        loading,
        error,
        fetch
    } = useFetch<TPasswordResetRequestBody | TPasswordResetRequestPatchBody, {message: string}>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                method,
                data,
                options,
                inform: false,
                auth,
                successMessage,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        method,
        data,
        successMessage,
        options,
        inform,
        auth
    ]);
    return {
        response,
        body,
        code,
        loading,
        error,
        fetch
    };
}