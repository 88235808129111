import React, { FunctionComponent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { IMetaMaskFormInputs, TMetaMaskBoxProps } from "./MetaMaskBox.types";
import { StyledMetaMaskBox } from "./MetaMaskBox.styles";
import { Button, Donkey, Form, IField, IFormButton, Icon } from "@common/components";
import { addResponseNote, useAppState, useNotesDispatch } from "@app/context";
import { ITxParams } from "@common/hooks/useMetaMask";
import { TFetchUsersData } from "@auth/hooks/useFetchUsers/useFetchUsers.types";
import { TFetchLoginsData, useFetchLogins, useFetchUsers } from "@auth/hooks";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { ethers, toBeHex } from "ethers";
import { endpoints } from "@common/endpoints";
import { TUserResponse } from "@auth/types";
import { ILogInFormInputs, SignUpForm } from "@auth/components";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { useNavigate } from "react-router";
import { ticketRoutes } from "@ticket/routes/TicketLayout.types";
import { TTicketResponse } from "@ticket/types";
import { userRoutes } from "@user/routes";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const MetaMaskBox: FunctionComponent<TMetaMaskBoxProps> = ({
    game,
    metaMaskHook,
    ...props
}) => {
    const {t: translateValidation} = useTranslation<
        TTranslationNameSpace
    >("validations");
    const {t: translateInput} = useTranslation<
        TTranslationNameSpace
    >("inputs");
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace
    >("buttons");
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace
    >("notes");
    const [showForm, setShowForm] = useState<boolean>();
    const notesDispatch = useNotesDispatch();
    const appState = useAppState();
    const navigate = useNavigate();
    const [logInPayload, setLogInPayload] = useState<ILogInFormInputs>();
    const [logInData, setLogInData] = useState<TFetchLoginsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: logInCode,
        error: logInError,
        loading: logInLoading,
        body: logInBody
    } = useFetchLogins(logInData);
    const [showUserSignUp, setShowUserSignUp] = useState<boolean>();
    const [buyInLoading ] = useState<boolean>();
    const [payload, setPayload] = useState<IMetaMaskFormInputs>();
    const [userData ] = useState<TFetchUsersData>({
        method: "GET",
        qs: `address=${metaMaskHook?.account}`,
        trigger: false
    });
    const [ticketsData, setTicketsData] = useState<TFetchTicketsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: ticketCode,
        body: ticketBody,
        error: ticketError,
        loading
    } = useFetchTickets(ticketsData);
    const {
        fetch: fetchExistingUser
    } = useFetchUsers(userData);
    const [, setCurrentValues] = useState<DeepPartialSkipArrayKey<IMetaMaskFormInputs>>();
    const fields: IField<IMetaMaskFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            mode: "input",
            name: "transferFrom",
            label: translateInput("transferFrom.label"),
            showLabel: true,
            validations: {
                required: translateValidation("required.originAccount")
            },
            defaultValue: metaMaskHook?.account || "",
            modeProps: {
                placeholder: translateInput("transferFrom.placeholder"),
                disabled: true
            }
        }, {
            row: 2,
            mode: "input",
            name: "transferTo",
            label: translateInput("transferTo.label"),
            showLabel: true,
            validations: {
                required: translateValidation("required.destinyAccount")
            },
            defaultValue: game?.address,
            modeProps: {
                placeholder: translateInput("transferTo.placeholder"),
                disabled: true
            }
        }, {
            row: 3,
            mode: "input",
            name: "amount",
            label: translateInput("amount.label"),
            showLabel: true,
            validations: {
                required: translateValidation("required.amount")
            },
            defaultValue: game?.entryFee.toFixed(2),
            modeProps: {
                placeholder: translateInput("amount.placeholder"),
                disabled: true
            }
        }];
        return _fields;
    }, [game, metaMaskHook, translateInput, translateValidation]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: translateButton("next"),
            size: "sm",
            caps: true,
            type: "submit",
            loading: buyInLoading || loading || logInLoading,
            disabled: metaMaskHook?.account ? false : true
        }];
        return _buttons;
    }, [buyInLoading, metaMaskHook, loading, logInLoading, translateButton]);
    const promptTransaction = useCallback(async(
        _payload: IMetaMaskFormInputs,
        _logInPayload?: ILogInFormInputs
    ) => {
        // setBuyInLoading(true);
        const _envChainId = process.env.NODE_ENV === "test"
            ? process.env.TEST_CHAIN_ID
            : process.env.CHAIN_ID;
        const _value = ethers.parseEther(_payload.amount); // toBeHex(parseFloat(_payload.amount));
        if (metaMaskHook.chainID !== _envChainId) {
            throw Error("chain ids not matching " + metaMaskHook.chainID + " " + _envChainId as string);
        }
        const txParams: ITxParams = {
            to: _payload.transferTo,
            from: _payload.transferFrom,
            value: toBeHex(_value),
            chainId: toBeHex(parseInt(metaMaskHook.chainID as string))
        };
        // send transaction
        const txHash = await metaMaskHook.sendTransaction(txParams);
        // validate transaction hash
        if (!txHash) {
            addResponseNote({
                code: 400,
                message: translateNote("errors.metaMaskTransaction")
            }, notesDispatch);
            // setBuyInLoading(false);
            return;
        }
        // now create ticket on games api
        // setTicketsData
        if (_logInPayload) setLogInPayload(_logInPayload);
        setTicketsData((prev) => ({
            ...prev,
            data: {
                _gameId: game?.id,
                hash: txHash
            },
            method: "POST",
            trigger: true
        }));
    }, [
        notesDispatch, 
        metaMaskHook, 
        game,
        translateNote
    ]);
    const onSubmit: SubmitHandler<IMetaMaskFormInputs> = useCallback(
        async(_payload) => {
            if (appState.user) {
                if (metaMaskHook?.account?.toUpperCase() !== appState.user.address.toUpperCase()) {
                    addResponseNote({
                        code: 400,
                        message: translateNote("errors.walletExistsOnAccount")
                    }, notesDispatch);
                    return;
                }
                return await promptTransaction(_payload);
            }
            if (!fetchExistingUser) return;
            const [, body] = await fetchExistingUser(
                endpoints.users.buildUrl({
                    qs: `address=${metaMaskHook?.account}`
                })
            ) as [Response, TUserResponse[]];
            if (body.length === 0) {
                // otherwise, have the user complete the create account form
                setPayload(_payload);
                setShowUserSignUp(true);
                return;
            }
            // if so, then proceed to fetch metamask and prompt transaction to user
            await promptTransaction(_payload);
        }, [
            metaMaskHook, 
            fetchExistingUser, 
            notesDispatch, 
            appState.user, 
            promptTransaction,
            translateNote
        ]);
    const connectToUsersMetaMaskAccount: MouseEventHandler = useCallback(
        async() => {
            if (!metaMaskHook.connected) {
                await metaMaskHook.connectWallet();
            }
            setShowForm(true);
        }, [metaMaskHook]); 
    useEffect(() => {
        if (!ticketCode && !ticketError) return;
        setTicketsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (ticketCode === 201) {
            if (logInPayload) {
                setLogInData((prev) => ({
                    ...prev,
                    data: logInPayload,
                    trigger: true
                }));
            } else {
                navigate(ticketRoutes.buyInSuccess.urlBuilder({
                    _gameId: (ticketBody as TTicketResponse)._gameId
                }), {
                    replace: true,
                    state: {
                        _gameId: (ticketBody as TTicketResponse)._gameId
                    }
                });
            }
            return;
        }
    }, [ticketCode, navigate, ticketBody, logInPayload, ticketError]);
    useEffect(() => {
        if (!logInCode && !logInError) return;
        setLogInData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (logInCode === 201) {
            navigate(userRoutes.oneUser.urlBuilder({
                handle: (logInBody as TUserResponse).handle
            }));
        }
    }, [logInCode, logInBody, navigate, logInError]);
    return (
        <StyledMetaMaskBox aria-label="meta-mask-box" {...props}>
            {showForm && game ? (
                <React.Fragment>
                    {showUserSignUp ? (
                        <React.Fragment>
                            <Icon
                                size="xxl">
                                <Donkey nature="no-background" />
                            </Icon>
                            <SignUpForm
                                address={metaMaskHook?.account}
                                onSuccess={async(loginPayload) => {
                                    await promptTransaction(
                                        payload as IMetaMaskFormInputs,
                                        loginPayload
                                    );
                                    setShowUserSignUp((prev) => !prev);
                                }} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Icon
                                size="xxl">
                                <Donkey nature="meta-mask" />
                            </Icon>
                            <Form
                                aria-label="buy-in with meta-mask form"
                                name={"buy-in with meta-mask form"}
                                fields={fields}
                                buttons={buttons}
                                onSubmit={onSubmit}
                                setCurrentValues={setCurrentValues} />
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Icon
                        size="xxl">
                        <Donkey nature="meta-mask" />
                    </Icon>
                    <Button
                        caps
                        onClick={connectToUsersMetaMaskAccount}
                        label={translateButton("metaMask.connect")}
                        nature="action"
                        size="sm" 
                        style={{
                            marginTop: "2rem"
                        }}/>
                </React.Fragment>
            )}
        </StyledMetaMaskBox>
    );
};
