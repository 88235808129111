import { TSize } from "./breakpoints";

export type TFontLabel = "main";

export type TFontVariant = "regular" | 
    "special" | 
    "active" | 
    "title" | 
    "info" |
    "error";

export const fonts: Record<TFontLabel, string> = {
    main: `"VT323", monospace`
};

export const fontSizes: Record<TSize, string> = {
    xxs: "1rem",
    xs: "1.5rem",
    sm: "2rem",
    md: "2.5rem",
    lg: "3rem",
    xl: "4rem",
    xxl: "5rem"
};
