import React, { FunctionComponent } from "react";
import { ResponseNote } from "./ResponseNote";
import { StyledResponseNotes } from "./ResponseNotes.styles";
import { TResponseNotesProps } from "./ResponseNotes.types";
import { useNotesState } from "@app/context/NotesContext.hooks";

export const ResponseNotes: FunctionComponent<TResponseNotesProps> = ({
    style,
    ...props
}) => {
    const notesState = useNotesState();
    return (
        <StyledResponseNotes {...props} style={{
            padding: notesState.responseNotes.length < 1 ? 0 : "0.5rem 2rem 2rem 2rem",
            ...style
        }}>
            {notesState.responseNotes.map((note) => (
                <ResponseNote key={note.hash} {...note} {...props} />
            ))}
        </StyledResponseNotes>
    );
};