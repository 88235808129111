import React, { FunctionComponent } from "react";
import { Box, Icon, Thing } from "@common/components";
import { TScoringStarSetProps } from "./ScoringStarSet.types";

export const ScoringStarSet: FunctionComponent<TScoringStarSetProps> = ({
    score,
    size,
    off=false,
    doubleDeck=false,
    ...props
}) => {
    return (
        <React.Fragment>
            {doubleDeck ? (
                <Box
                    aria-label="score-badge"
                    direction="column"
                    style={{
                        margin: 0,
                        padding: 0
                    }}>
                    <Box
                        aria-label="score-badge-row"
                        direction="row"
                        style={{
                            padding: "0"
                        }}>
                        {[0, 1, 2].map((index) => (
                            <Icon
                                key={index}
                                size={size}
                                nature={off 
                                    ? "off-star"
                                    : score > index
                                        ? "gold-star" 
                                        : "gray-star"}>
                                <Thing
                                    aria-label={off 
                                        ? "off-star"
                                        : score > index
                                            ? "gold-star" 
                                            : "gray-star"}
                                    nature={off
                                        ? "off-star"
                                        : score > index
                                            ? "gold-star" 
                                            : "gray-star"} />
                            </Icon>
                        ))}
                    </Box>
                    <Box
                        aria-label="score-badge-row"
                        direction="row"
                        style={{
                            padding: "0"
                        }}>
                        {[3, 4, 5].map((index) => (
                            <Icon
                                key={index}
                                size={size}
                                nature={off 
                                    ? "off-star"
                                    : score > index
                                        ? "gold-star" 
                                        : "gray-star"}>
                                <Thing
                                    aria-label={off 
                                        ? "off-star"
                                        : score > index
                                            ? "gold-star" 
                                            : "gray-star"}
                                    nature={off
                                        ? "off-star"
                                        : score > index
                                            ? "gold-star" 
                                            : "gray-star"} />
                            </Icon>
                        ))}
                    </Box>
                </Box>
            ) :(    
                <Box
                    aria-label="score-badge"
                    direction="row"
                    style={{
                        padding: "0.5rem 0"
                    }}>
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <Icon
                            key={index}
                            size={size}
                            nature={off 
                                ? "off-star"
                                : score > index
                                    ? "gold-star" 
                                    : "gray-star"}>
                            <Thing
                                aria-label={off 
                                    ? "off-star"
                                    : score > index
                                        ? "gold-star" 
                                        : "gray-star"}
                                nature={off
                                    ? "off-star"
                                    : score > index
                                        ? "gold-star" 
                                        : "gray-star"} />
                        </Icon>
                    ))}
                </Box>
            )}
        </React.Fragment>
    );
};
