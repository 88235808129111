import { RouteMapper } from "@common/components";
import { TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";
import { Routes } from "react-router";
import { TGameRoutes, gameRoutes } from "./GameLayout.types";

export const GameLayout: FunctionComponent<TLayoutProps> = (props) => {
    return (
        <Routes>
            {RouteMapper<TGameRoutes>({
                routes: gameRoutes
            })}
        </Routes>
    );
};
