import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IPasswordResetFormInputs, TPasswordResetRequestFormProps } from "./PasswordResetRequestForm.types";
import { Box, Button, Form, IField, IFormButton, Text } from "@common/components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { TFetchPasswordResetRequestData, useFetchPasswordResetRequests } from "@auth/hooks";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { useTriStateSwitch } from "@common/hooks";
import { useNavigate } from "react-router";
import { appRoutes } from "@app/routes";

export const PasswordResetRequestForm: FunctionComponent<TPasswordResetRequestFormProps> = ({
    ...props
}) => {
    const navigate = useNavigate();
    const [currentValues, setCurrentValues] = useState<
        DeepPartialSkipArrayKey<IPasswordResetFormInputs>
    >();
    const {state: showSuccess, flip} = useTriStateSwitch();
    const {t: translateInput} = useTranslation<TTranslationNameSpace>("inputs");
    const {t: translateButton} = useTranslation<
    TTranslationNameSpace, "passwordReset"
>("buttons", {keyPrefix: "passwordReset"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace, "passwordReset"
    >("texts", {keyPrefix: "passwordReset"});
    const [hookData, setHookData] = useState<TFetchPasswordResetRequestData>({
        trigger: false,
        method: "POST"
    });
    const {
        code,
        error,
        loading
    } = useFetchPasswordResetRequests(hookData);
    const fields: IField<IPasswordResetFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            name: "handle",
            row: 1,
            mode: "input",
            defaultValue: "",
            modeProps: {
                type: "text",
                placeholder: translateInput("handle.placeholder")
            }
        }];
        // const _fields: typeof fields = [{
        //     name: "email",
        //     row: 1,
        //     mode: "input",
        //     defaultValue: "",
        //     modeProps: {
        //         type: "email",
        //         placeholder: translateInput("email.placeholder")
        //     }
        // }];
        return _fields;
    }, [translateInput]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            caps: true,
            label: translateButton("requestReset"),
            size: "sm",
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "regular",
                variant: "white",
                loaderLength: "regular"
            },
            nature: "action",
            disabled: !currentValues?.handle
        }];
        return _buttons;
    }, [loading, translateButton, currentValues]);
    const onSubmit: SubmitHandler<IPasswordResetFormInputs> = useCallback(
        async (payload) => {
            setHookData((prev) => ({
                ...prev,
                data: payload,
                trigger: true
            }));
        }, []);
    useEffect(() => {
        if (!code && !error) return;
        setHookData((prev) => ({
            ...prev,
            trigger: false
        }));
        flip();
    }, [code, flip, error]);
    return (
        <Box
            full
            style={{
                justifyContent: "center",
                padding: 0
            }}>
            {!showSuccess && (
                <React.Fragment>
                    <Form
                        aria-label="password reset request form"
                        name="password-reset-request-form"
                        fields={fields}
                        buttons={buttons}
                        setCurrentValues={setCurrentValues}
                        onSubmit={onSubmit}>
                        <Text
                            aria-label="password reset disclaimer"
                            variant="regular"
                            text={translateText("disclaimer.0")}
                            size="sm"
                            style={{
                                textAlign: "center",
                                marginTop: "1rem"
                            }} />
                    </Form>
                    <Text
                        variant="active"
                        text={translateText("disclaimer.1")}
                        size="xs" 
                        style={{
                            textAlign: "center"
                        }} />
                </React.Fragment>
            )}
            {showSuccess && (
                <React.Fragment>
                    <Text
                        size="sm"
                        text={translateText("success.0")}
                        variant="regular"
                        style={{
                            textAlign: "center"
                        }} />
                    <Text
                        size="sm"
                        text={translateText("success.1")}
                        variant="regular"
                        style={{
                            margin: "1rem 0 1.5rem 0",
                            textAlign: "center"
                        }} />
                    <Button
                        size="sm"
                        nature="action"
                        caps
                        onClick={(event) => navigate(appRoutes.home.url)}
                        label={translateButton("goHome")} />
                </React.Fragment>
            )}
        </Box>
    );
};
