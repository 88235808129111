import React, { FunctionComponent } from "react";
import { Box } from "../Box";
import { TPaginatedProps } from "./Paginated.types";
import { Arrow, Icon } from "../Icon";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const Paginated: FunctionComponent<TPaginatedProps> = ({
    style,
    children,
    onPrevious,
    onNext,
    page = 1,
    perPage = 10,
    total = 0,
    hide,
    ...props
}) => {
    const {t: translateText} = useTranslation<TTranslationNameSpace>(
        "texts"
    );
    return(
        <Box
            aria-label="pagination wrapper"
            direction="column"
            full
            style={{
                position: "relative",
                padding: 0,
                margin: 0,
                ...style
            }}>
            {children}
            <Box
                full
                loose
                style={{
                    display: hide ? "none" : "flex",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                    margin: "1.5rem 0 0 0"
                }}>
                {/* arrow previous */}
                <Icon 
                    aria-label="previous items"
                    tabIndex={0}
                    nature={"top-left"}
                    role="button"
                    size="sm"
                    onClick={onPrevious}>
                    <Arrow nature={"top-left"} />
                </Icon>
                {/* count */}
                <Text
                    aria-label="item count"
                    text={total === 0
                        ? translateText("noResults.paginated")
                        : `${(
                            (page - 1) * perPage
                        ) + 1} - ${total < page * perPage
                            ? total
                            : page * perPage} of ${total}`}
                    size="sm"
                    variant="special"
                    style={{
                        minWidth: "4rem"
                    }} />
                {/* arrow right */}
                <Icon 
                    aria-label="next items"
                    tabIndex={0}
                    nature={"top-right"}
                    role="button"
                    size={"sm"}
                    onClick={onNext}>
                    <Arrow nature={"top-right"} />
                </Icon>
            </Box>
        </Box>
    );
};
