export function parseToOrdinal(
    value: number, 
    lng: "en" | "es" | string = "en"
): string {
    const modTen: number = value % 10;
    const modHundred: number = value % 100;
    if (lng === "en") {
        if (modTen === 1 && modHundred != 11) return `${value}st`;
        if (modTen === 2 && modHundred != 12) return `${value}nd`;
        if (modTen === 3 && modHundred != 13) return `${value}rd`;
        return `${value}th`;
    }
    if (lng === "es") {
        if (modTen === 1 || modTen === 3) return `${value}ro`;
        if (modTen === 2) return `${value}do`;
        if (modTen === 7 || modTen === 0) return `${value}mo`;
        if (modTen === 8) return `${value}vo`;
        if (modTen === 9) return `${value}no`; 
        //  modTen === 4 || modTen === 5 || modTen === 6
        return `${value}to`;
    }
    return `${value}`;
};
