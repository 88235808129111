import React, { FunctionComponent } from "react";
import { TGamePrizeRowProps } from "./GamePrizeRow.types";
import { Avatar, Box, Text } from "@common/components";
import { parseToOrdinal } from "@utils/parsers";
import { IconBadge, Thing, Trophy } from "@common/components/Icon";
import { asPercentage } from "@utils/parsers/strings";
import { userRoutes } from "@user/routes";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const GamePrizeRow: FunctionComponent<TGamePrizeRowProps> = ({
    prize,
    player,
    setShowPrize,
    ...props
}) => {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    return (
        <Box
            full
            direction="row"
            style={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.3rem 0"
            }}
            {...props}>
            {/* position */}
            <Box
                direction="row"
                style={{
                    width: "auto", 
                    padding: 0
                }}>
                <Box
                    direction="column"
                    style={{
                        padding: 0,
                        alignItems: "start",
                        cursor: "pointer"
                    }}
                    onClick={(event) => player?.handle
                        ? navigate(userRoutes.oneUser.urlBuilder({
                            handle: player?.handle
                        }))
                        : null}>
                    <Avatar
                        size="sm"
                        avatar={{
                            avatar: player?.avatar || undefined // prize.winner.avatar
                        }}
                        style={{
                            cursor: "pointer"
                        }} />
                    <Text
                        aria-label={`position: ${parseToOrdinal(prize.position)}`}
                        caps
                        component="p"
                        size="xs"
                        text={parseToOrdinal(
                            prize.position, 
                            i18n.language
                        )}  />
                    {/* text={translatePositions("standalone", {
                            count: prize.position,
                            ordinal: true
                        })} /> */}
                </Box>
                {/* user handle */}
                <Text
                    aria-label={`player name: ${player?.handle 
                        ? player.handle 
                        : "none yet"}`} // prize.winner.name
                    size="xs"
                    text={player?.handle 
                        ? player.handle
                        : "@none_yet"} // prize.winner.name
                    variant="title"
                    style={{
                        marginLeft: "1rem"
                    }}/>
            </Box>
            <Box
                style={{
                    padding: 0,
                    cursor: prize.receipt ? "pointer" : "auto"
                }}
                onClick={(event) => setShowPrize && setShowPrize((prev) => !prev
                    ? prize
                    : undefined
                )}>
                {/* prize */}
                <IconBadge
                    text={asPercentage({
                        value: prize.fee
                    })}
                    style={{
                        marginRight: "0.3rem",
                        padding: 0
                    }}
                    textWeight="bold"
                    textSize="xs">
                    <Trophy nature={prize.position === 1
                        ? "golden"
                        : prize.position === 2
                            ? "silver"
                            : "bronze"} />
                </IconBadge>
                {/* amount */}
                <IconBadge
                    text={prize.purse?.toFixed(4) || "-"} // prize.purse ??
                    style={{
                        marginLeft: "0.3rem",
                        padding: 0
                    }}
                    textWeight="bold"
                    textSize="xs">
                    <Thing nature="purse" />
                </IconBadge>
            </Box>
        </Box>
    );
};
