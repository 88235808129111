import { ThemeType } from ".";
import { colors } from "./colors";

export type TPaletteColor = "textRegular" | "textActive" | "textSpecial" | "textTitle" | "textError" | "textInfo" 
    | "itemLightText" | "itemDarkText"
    | "btnAccept" | "btnDecline" | "btnAction" | "btnOption" | "btnInfo" | "btnDisabled" | "btnCollapsed"
    | "borderTheme" | "borderContext"
    | "toastPositive" | "toastNegative" | "toastNeutral"
    | "background" | "backgroundNav" | "backgroundContext"
    | "inputBackground"
    | "tabTextActive" | "tabTextRegular" | "tabTextDisabled"
    | "tabBackgroundActive" | "tabBackgroundRegular" | "tabBackgroundDisabled" 
    | "terminalBg" | "terminalFg"
    | "topArrowLight" | "topArrowMain" | "topArrowDark"
    | "subArrowLight" | "subArrowMain" | "subArrowDark"
    | "tableDefFirstColor" | "tableDefSecondColor"
;

export type TPalette = Record<TPaletteColor, string>;

export const palettes: Record<ThemeType["mode"], TPalette> = {
    light: {
        textRegular: colors.black,
        textActive: colors.green,
        textSpecial: colors.purple,
        textTitle: colors.darkPurple,
        textError: colors.red,
        textInfo: colors.blue,
        topArrowLight: colors.purple,
        topArrowMain: colors.midPurple,
        topArrowDark: colors.darkPurple,
        subArrowLight: colors.purple,
        subArrowMain: colors.midPurple,
        subArrowDark: colors.darkPurple,
        itemLightText: colors.white,
        itemDarkText: colors.black,
        btnAccept: colors.green,
        btnDecline: colors.red,
        btnAction: colors.blue,
        btnOption: colors.pink,
        btnCollapsed: colors.lightBrown,
        btnInfo: colors.purple,
        btnDisabled: colors.lightGray,
        toastPositive: colors.green,
        toastNegative: colors.red,
        toastNeutral: colors.blue,
        background: colors.lightBrown,
        backgroundNav: colors.purple,
        backgroundContext: colors.white,
        inputBackground: colors.white,
        borderContext: colors.darkPurple,
        borderTheme: colors.black,
        tabBackgroundActive: colors.white,
        tabBackgroundRegular: colors.opaqueBrown,
        tabBackgroundDisabled: colors.black30,
        tabTextActive: colors.darkPurple,
        tabTextRegular: colors.black,
        tabTextDisabled: colors.black40,
        terminalBg: colors.purple,
        terminalFg: colors.green,
        tableDefFirstColor: colors.white30,
        tableDefSecondColor: colors.lightGray
    },
    dark: {
        textRegular: colors.white,
        textActive: colors.green,
        textSpecial: colors.white,
        textTitle: colors.green,
        textError: colors.pink,
        textInfo: colors.white,
        topArrowLight: colors.green,
        topArrowMain: colors.midGreen,
        topArrowDark: colors.darkGreen,
        subArrowLight: colors.white,
        subArrowMain: colors.lightGray,
        subArrowDark: colors.midGray,
        itemLightText: colors.white,
        itemDarkText: colors.black,
        btnAccept: colors.green,
        btnDecline: colors.red,
        btnAction: colors.blue,
        btnOption: colors.pink,
        btnInfo: colors.darkPurple,
        btnDisabled: colors.lightGray,
        btnCollapsed: colors.lightBrown,
        toastPositive: colors.green,
        toastNegative: colors.red,
        toastNeutral: colors.blue,
        background: colors.purple,
        backgroundNav: colors.pink,
        backgroundContext: colors.darkPurple,
        inputBackground: colors.lightGray,
        borderContext: colors.white,
        borderTheme: colors.white,
        tabBackgroundActive: colors.darkPurple,
        tabBackgroundRegular: colors.purple,
        tabBackgroundDisabled: colors.black30,
        tabTextActive: colors.green,
        tabTextRegular: colors.white,
        tabTextDisabled: colors.black40,
        terminalBg: colors.darkPurple,
        terminalFg: colors.green,
        tableDefFirstColor: colors.white30,
        tableDefSecondColor: colors.transparent
    }
};
