import { endpoints } from "@common/endpoints";
import { TFetchData, useFetch } from "@common/hooks";
import { useEffect, useState } from "react";
import { IFetchUsers, TFetchUsersData } from "./useFetchUsers.types";
import { TUserBody, TUserResponse } from "@auth/types/User.types";
import { authenticate, useAppDispatch } from "@app/context";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchUsers({
    method,
    data,
    trigger,
    successMessage,
    inform=true,
    options,
    ignoreHeaders,
    qs,
    _userId,
    bypassAuth,
    ...props
}: TFetchUsersData): IFetchUsers {
    const appDispatch = useAppDispatch();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchData<TUserBody>>({
        endpointURI: endpoints.users.buildUrl({
            _userId: _userId,
            qs: qs
        }),
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage:  successMessage
            ? successMessage
            : translateNote("userSuccessfully", {
                action: method === "POST" 
                    ? "signed up" 
                    : "updated"
            }),
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TUserBody, TUserResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: endpoints.users.buildUrl({
                    _userId: _userId,
                    qs: qs
                }),
                trigger: true,
                data: data,
                method: method,
                auth: method === "GET"
                    ? false
                    : true,
                successMessage: successMessage
                    ? successMessage
                    : translateNote("userSuccessfully", {
                        action: method === "POST" 
                            ? "signed up" 
                            : "updated"
                    }),
                inform: inform,
                options: options
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        method,
        successMessage,
        options,
        inform,
        data,
        qs,
        translateNote,
        _userId
    ]);
    useEffect(() => {
        if (!response && !error) return;
        if (response?.status === 201 && body && !bypassAuth) {
            authenticate(body as TUserResponse, appDispatch);
            return;
        }
    }, [response, error, body, appDispatch, bypassAuth]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};
