import { Box, Navbar } from "@common/components";
import { TDonkeyBarProps } from "./DonkeyBar.types";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { toggleTheme, useAppDispatch, useAppState } from "@app/context";
import { UserMenu } from "../UserMenu/UserMenu";
import { TContexMenuSection, TContextMenuItem } from "../ContextMenu";
import { useLocation, useNavigate } from "react-router";
import { appRoutes } from "@app/routes";
import { TFetchLoginsData, useFetchLogins } from "@auth/hooks";
import { userRoutes } from "@user/routes";
import { useLocalStorage } from "@common/hooks";
import { ThemeTypeMode } from "@theme";
import { helpCenterRoutes } from "@helpCenter/routes/HelpCenterLayout.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { LanguageSwitch } from "../LanguageSwitch";

export const DonkeyBar: FunctionComponent<TDonkeyBarProps> = ({
    ...props
}) => {
    const appDispatch = useAppDispatch();
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const {t: translateOption} = useTranslation<TTranslationNameSpace>("options");
    const appState = useAppState();
    const location = useLocation();
    const navigate = useNavigate();
    const {setCurrent} = useLocalStorage<ThemeTypeMode>({key: "theme"});
    const [hookData, setHookData] = useState<TFetchLoginsData>({
        method: "DELETE",
        trigger: false
    });
    const {
        code
    } = useFetchLogins(hookData);
    const userMenuContent: TContexMenuSection[] = useMemo(() => {
        const commonOptions: TContextMenuItem[] = [{
            name: translateOption("userMenu.home"),
            action: (event: React.MouseEvent) => navigate(appRoutes.home.url)
        }];
        const commonFooter: TContexMenuSection = {
            name: "",
            items: [{
                name: translateOption("userMenu.intro"),
                action: (event: React.MouseEvent) => {
                    navigate(helpCenterRoutes.introTutorial.url);
                }
            },  {
                name: translateOption("userMenu.helpCenter"),
                action: (event: React.MouseEvent) => {
                    navigate(helpCenterRoutes.helpCenter.url);
                }
            }, {
                name: translateOption("userMenu.toggleTheme"),
                action: (event: React.MouseEvent) => {
                    setCurrent(appState.theme === "light" ? "dark" : "light"); 
                    toggleTheme(appDispatch);
                }
            }]
        };
        if (appState.authenticated) return [{
            name: translateText("userMenu.loggedIn", {
                handle: appState.user?.handle as string
            }) // `logged in as @${appState.user?.handle as string}`
        }, {
            name: "",
            items: [...commonOptions, {
                name: translateOption("userMenu.profile"),
                action: (event: React.MouseEvent) => navigate(userRoutes.oneUser.urlBuilder({
                    handle: appState.user?.handle
                }))
            }]
        }, {
            name: "",
            items: [...(commonFooter.items as TContextMenuItem[]), {
                name: translateOption("userMenu.logOut"),
                action: (event: React.MouseEvent) => setHookData((prev) => ({
                    ...prev,
                    trigger: true
                }))
            }]
        }];
        return [{
            name: translateText("userMenu.notLoggedIn")
        }, {
            name: "",
            items: [...commonOptions, {
                name: translateOption("userMenu.logIn"),
                action: (event) => navigate(appRoutes.logIn.urlBuilder(), {
                    state: {
                        _gameId: location.pathname.includes("/games/")
                            ? location.pathname.replace("/games/", "")
                            : undefined
                    }
                })
            }, {
                name: translateOption("userMenu.signUp"),
                action: (event) => navigate(appRoutes.signUp.urlBuilder())
            }]
        }, {
            name: "",
            items: [...(commonFooter.items as TContextMenuItem[])]
        }];
    }, [
        appState.authenticated,
        appState.user,
        navigate,
        appDispatch,
        location,
        appState.theme,
        setCurrent,
        translateOption,
        translateText
    ]);
    useEffect(() => {
        if (code) {
            setHookData((prev) => ({
                ...prev,
                trigger: false
            }));
        }
    }, [code]);
    return (
        <Navbar
            isotype
            role={"navigation"}
            isotypeAction={(e) => navigate(appRoutes.home.url)}
            options={[{
                name: `@${appState.user?.handle || "anon"}`,
                path: ""
            }]}>
            <Box
                direction="row"
                style={{padding: 0}}>
                <LanguageSwitch />
                <UserMenu content={userMenuContent} />
            </Box>
        </Navbar>
    );
};