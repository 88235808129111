import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const OneGame = lazy(() => import("@game/pages").then(({
    OneGame
}) => ({default: OneGame})));

export type TGameRoutes = "oneGame";

export const gameRoutes: Record<TGameRoutes, ILayoutRoute> = {
    oneGame: {
        _id: 1,
        patterns: ["/:_gameId"],
        url: "/games/:_gameId",
        element: OneGame,
        protect: false,
        urlBuilder: (params) => `/games/${params?._gameId}`
    }
};
