
import styled, { css, keyframes } from "styled-components";
import { TStyledLoaderCellProps, TStyledLoaderProps } from "./Loader.types";
import { TSnakeFrame } from "@common/animations/Snake/Snake.types";


const loadingAnimation = (version: TSnakeFrame) => {
    return keyframes`${version.keyframesString}`;
};

export const StyledLoader = styled.div<TStyledLoaderProps>`
    position: relative;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    display: flex;
    padding: 0;
    align-self: center;
    justify-self: center;
    ${props => props.inBox
        ? "margin: 4rem 0;"
        : ""}
`;

export const StyledLoaderCell = styled.span.attrs<
    TStyledLoaderCellProps,
    TStyledLoaderCellProps
>(props => ({
    style: {
        top: `${props.cell.origin.top}px`,
        left: `${props.cell.origin.left}px`,
        width: `${props.size}px`,
        height: `${props.size}px`,
        backgroundColor: `${props.version.color}`
    },
    ...props
}))`
    position: absolute;
    opacity: 0;
    ${props => css` 
        animation: ${loadingAnimation(props.version)} ${props.speed === "regular"
    ? "1s"
    : props.speed === "fast"
        ? "800ms"
        : "1.6s"} linear infinite;
    `}
    z-index: 5000;
`;
