import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ILogInFormInputs, TLogInFormProps } from "./LogInForm.types";
import { Box, Form, IField, IFormButton, Text } from "@common/components";
import { TFetchLoginsData, useFetchLogins } from "@auth/hooks";
import { SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { gameRoutes } from "@game/routes";
import { userRoutes } from "@user/routes";
import { TUserResponse } from "@auth/types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const LogInForm: FunctionComponent<TLogInFormProps> = ({
    success,
    ...props
}) => {
    const {t: translateValidation} = useTranslation<TTranslationNameSpace>("validations");
    const {t: translateButton} = useTranslation<TTranslationNameSpace>("buttons");
    const {t: translateInput} = useTranslation<TTranslationNameSpace>("inputs");
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const [hookData, setHookData] = useState<TFetchLoginsData>({
        trigger: false,
        method: "POST"
    });
    const location = useLocation();
    const navigate = useNavigate();
    const {
        loading,
        body,
        code,
        error
    } = useFetchLogins(hookData);
    const fields: IField<ILogInFormInputs>[] = useMemo(() => {
        return [{
            name: "handle",
            row: 1,
            validations: {
                required: translateValidation("required.handle")
            },
            defaultValue: "",
            mode: "input",
            modeProps: {
                type: "text",
                autoComplete: "auto",
                placeholder: translateInput("handle.placeholder")
            }
        }, {
            name: "password",
            row: 2,
            validations: {
                required: translateValidation("required.password"),
                validate: (value: string) => value.length >= 8 ? true : "password must be at least 8 chars long 😐" 
            },
            defaultValue: "",
            mode: "input",
            modeProps: {
                type: "password",
                autoComplete: "current-password",
                placeholder: translateInput("password.placeholder")
            }
        }] as typeof fields;
    }, [translateInput, translateValidation]);
    const buttons: IFormButton[] = useMemo(() => {
        return [{
            row: 1,
            label: translateButton("logIn"),
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "xs",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            }
        }];
    }, [loading, translateButton]);
    const onSubmit: SubmitHandler<ILogInFormInputs> = useCallback(
        async(payload) => {
            setHookData((prev) => ({
                ...prev,
                data: payload,
                auth: true,
                trigger: true
            }));
        }, []);
    useEffect(() => {
        if (!code && !error) return;
        setHookData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 201) {
            navigate(location.state._gameId
                ? gameRoutes.oneGame.urlBuilder({
                    _gameId: location.state._gameId as string
                })
                : userRoutes.oneUser.urlBuilder({
                    handle: (body as TUserResponse).handle
                })
            );
        }
    }, [code, navigate, body, location, error]);
    return (
        <React.Fragment>
            <Box
                full
                style={{
                    justifyContent: "center",
                    padding: "0"
                }}>
                {success && (
                    <Box
                        full
                        direction="column"
                        style={{
                            alignItems: "center",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                        }}>
                        <Text
                            size="md"
                            text={translateText("logIn.ticketSuccess.0")}
                            variant="regular"
                            caps />
                        <Text
                            caps
                            size="xs"
                            variant="regular"
                            style={{margin: "1rem 0 0.5rem 0"}}
                            text={translateText("logIn.ticketSuccess.1")} />
                        <Text
                            caps
                            size="xs"
                            variant="regular"
                            style={{margin: "0.5rem 0 1rem 0"}}
                            text={translateText("logIn.ticketSuccess.2")} />
                    </Box>
                )}
            </Box>
            <Form
                aria-label="log in form"
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit} />
        </React.Fragment>
    );
};