import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { TGameMatchPicksTableProps } from "./GameMatchPicksTable.types";
import { Box, ITableHeader, ITableRow, Icon, Paginated, SearchBar, Table, Text, Thing } from "@common/components";
import { TGameStanding, TPickResponse } from "@game/types";
import { TFetchGameMatchesData, TFetchGamesData, 
    // TFetchPicksData, useFetchPicks 
    useFetchGameMatches, useFetchGames, 
} from "@game/hooks";
import { parseToOrdinal } from "@utils/parsers";
import { PlayerPick } from "../PlayerPick";
import { usePagination } from "@common/hooks";

export const GameMatchPicksTable: FunctionComponent<TGameMatchPicksTableProps> = ({
    _gameId,
    _gameMatchId,
    competitionsMatch,
    ...props
}) => {
    const [filteredStandings, setFilteredStandings] = useState<TGameStanding[]>();
    const [standings, setStandings] = useState<TGameStanding[]>();
    // const [gameMatchPicks, setGameMatchPicks] = useState<TPickResponse[]>();
    // const [picksData, setPicksData] = useState<TFetchPicksData>({
    //     method: "GET",
    //     qs: `_gameMatchId=${_gameMatchId}`,
    //     trigger: true
    // });
    const [gameMatchPicksData, setGameMatchPicksData] = useState<TFetchGameMatchesData>({
        method: "GET",
        picks: true,
        trigger: true,
        _gameMatchId: _gameMatchId
    });
    const {
        paginatedResponse,
        code: gameMatchPicksCode
    } = useFetchGameMatches(gameMatchPicksData);
    const [standingsData, setStandingsData] = useState<TFetchGamesData>({
        trigger: false,
        standings: true,
        _gameId: _gameId
    });
    const {
        code: standingsCode,
        body: standingsBody
    } = useFetchGames(standingsData);
    // const {
    //     code: picksCode,
    //     paginatedResponse
    //     // loading: picksLoading
    // } = useFetchPicks(picksData);
    const {
        page,
        perPage,
        total,
        items: paginatedStandings,
        previousPage,
        nextPage
    } = usePagination<TGameStanding>({
        items: standings
    });
    const rows: ITableRow[] = useMemo(() => {
        if (!paginatedStandings) return [];
        if (
            !paginatedResponse || 
            (paginatedResponse.items as TPickResponse[]).length === 0
        ) return [];
        const _standingsToShow = filteredStandings
            ? filteredStandings
            : paginatedStandings;
        const paginatedPicks = paginatedResponse.items as TPickResponse[];
        const _rows: typeof rows = [];
        for (const standing of _standingsToShow) {
            const _pick = paginatedPicks.find(
                (_p => _p._ticketId === standing._ticketId)
            );
            const values: ITableRow["values"] = [
                <Text
                    key={standing._ticketId}
                    aria-label={`position for ${standing.handle}: ${standing.position
                        ? parseToOrdinal(standing.position) 
                        : 0}`}
                    text={`${standing.position
                        ? parseToOrdinal(standing.position) 
                        : 0}`}
                    size="xs"
                    style={{margin: "auto"}} />,
                <Box
                    key={standing._ticketId}
                    full
                    direction="column"
                    style={{padding: 0}}>
                    <Text
                        aria-label={`player handle: ${standing.handle}`}
                        text={`@${standing.handle}`}
                        size="xs" />
                    <Box
                        full
                        style={{
                            justifyContent: "start",
                            padding: 0,
                            marginTop: "0.3rem"
                        }}>
                        <Icon
                            nature="gold-star"
                            size="xs">
                            <Thing nature="gold-star" />
                        </Icon>
                        <Text
                            aria-label={`points without this game & this game points: ${
                                standing.score - (_pick?.points || 0)
                            } & ${_pick?.points || 0}`}
                            text={`x${standing.score - (_pick?.points || 0)} + ${_pick?.points || 0}`}
                            size="xs" />
                    </Box>
                </Box>,
                <PlayerPick
                    key={standing._ticketId}
                    home={competitionsMatch?.home}
                    away={competitionsMatch?.away} 
                    pick={_pick}
                    size="sm"
                    textSize="xs" />
            ];
            _rows.push({
                values: values
            });
        }
        return _rows;
    }, [paginatedResponse, paginatedStandings, filteredStandings, competitionsMatch]);
    const headers: ITableHeader[] = useMemo(() => {
        const _headers: typeof headers = [{
            alignment: "left",
            width: "20%"
        }, {
            alignment: "left",
            width: "50%"
        }, {
            alignment: "center",
            width: "30%"
        }];
        return _headers;
    }, []);
    const queryPicksFromStandings = useCallback(
        (_standings: TGameStanding[]): void => {
            let _qs = "";
            for (const standing of _standings) {
                if (_qs === "") {
                    _qs = `_ticketId=${standing._ticketId}`;
                } else {
                    _qs += `&_ticketId=${standing._ticketId}`;
                }
            }
            // _qs += `&_gameMatchId=${_gameMatchId}`;
            setGameMatchPicksData((prev) => ({
                ...prev,
                qs: _qs,
                _gameMatchId: _gameMatchId,
                method: "GET",
                picks: true,
                trigger: true
            }));
            // setPicksData((prev) => ({
            //     ...prev,
            //     qs: _qs,
            //     method: "GET",
            //     trigger: true
            // }));
        }, [_gameMatchId]);
    const refreshListOfPlayerPicks = useCallback((_items: TGameStanding[]): void => {
        if (!_items) queryPicksFromStandings(paginatedStandings);
        setFilteredStandings(_items);
        // queryPicksFromStandings(_items);
    }, [paginatedStandings, queryPicksFromStandings]);
    useEffect(() => {
        if (standings) return;
        setStandingsData((prev) => ({
            ...prev,
            trigger: true,
            _gameId: _gameId
        }));
    }, [standings, _gameId]);
    useEffect(() => {
        if (!standingsCode) return;
        setStandingsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (standingsCode === 200) {
            setStandings(standingsBody as TGameStanding[]);
        }
    }, [standingsCode, standingsBody]);
    useEffect(() => {
        if (paginatedStandings) queryPicksFromStandings(paginatedStandings);
    }, [paginatedStandings, queryPicksFromStandings]);
    useEffect(() => {
        // if (!picksCode) return;
        if (!gameMatchPicksCode) return;
        setGameMatchPicksData((prev) => ({
            ...prev,
            trigger: false
        }));
        // setPicksData((prev) => ({
        //     ...prev,
        //     trigger: false
        // }));
    }, [
        gameMatchPicksCode
        // picksCode
    ]);
    useEffect(() => {
        if (!filteredStandings) return;
        queryPicksFromStandings(filteredStandings);
    }, [queryPicksFromStandings, filteredStandings]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0
            }}>
            <SearchBar
                mode="filter"
                filterBy={"handle"}
                onFilterChange={refreshListOfPlayerPicks}
                items={standings}>
                <Paginated
                    onNext={nextPage}
                    onPrevious={previousPage}
                    page={page}
                    perPage={perPage}
                    total={total}
                    hide={filteredStandings ? true : false}>
                    <Table
                        aria-label="game match picks table"
                        headers={headers || []}
                        rows={rows || []} />
                </Paginated>
            </SearchBar>
        </Box>
    );
};
