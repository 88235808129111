import React, { ChangeEvent, useEffect, useMemo } from "react";
import { IFieldRow, TFormProps, IButtonRow } from "./Form.types";
import { StyledForm } from "./Form.styles";
import { Button } from "../Button";
import { Box } from "../Box";
import { Controller, DeepPartial, DeepPartialSkipArrayKey, DeepRequired, FieldErrorsImpl, FieldName, Path, PathValue, useForm, useWatch } from "react-hook-form";
import { Input } from "../Input";
import { ErrorMessage, FieldValuesFromFieldErrors } from "@hookform/error-message";
import { Text } from "../Text";

export function Form<
    TFormInputs extends object
>({
    fields,
    buttons,
    onSubmit,
    setCurrentValues,
    ...props
}: TFormProps<TFormInputs>): React.ReactComponentElement<"form"> {
    const {
        handleSubmit, 
        control,
        setValue,
        formState: { errors }
    } = useForm<TFormInputs>({
        defaultValues: fields.reduce(
            (total, field) => ({
                ...total,
                [field.name]: field.defaultValue
            }), {}
        ) as DeepPartial<TFormInputs>
    });
    const allFields = useWatch<TFormInputs>({
        control: control,
        defaultValue: fields.reduce(
            (total, field) => ({
                ...total,
                [field.name]: field.defaultValue
            }), {}
        ) as DeepPartialSkipArrayKey<TFormInputs>
    });
    const buttonRows: IButtonRow[] = useMemo(() => {
        const _buttonRows: typeof buttonRows = [];
        if (!buttons) return _buttonRows;
        for (const btn of buttons) {
            let found = false;
            for (const row of _buttonRows) {
                if (btn.row === row.order) {
                    row.buttons.push(btn);
                    found = true;
                    break;
                }
            }
            if (!found) {
                _buttonRows.push({
                    order: btn.row,
                    buttons: [btn]
                });
            }
        }
        return _buttonRows.sort((a, b) => a.order - b.order);
    }, [buttons]);
    const rows: IFieldRow<TFormInputs>[] = useMemo(() => {
        const _rows: typeof rows = [];
        for (const field of fields) {
            let found = false;
            for (const row of _rows) {
                if (field.row === row.order) {
                    row.inputs.push(field);
                    found = true;
                }
            }
            if (!found) { 
                _rows.push({
                    order: field.row,
                    inputs: [field]
                });
            }
        }
        return _rows.sort((a, b) => a.order - b.order);
    }, [fields]);
    useEffect(() => {
        setCurrentValues && setCurrentValues(allFields);
    }, [allFields, setCurrentValues]);
    return (
        <StyledForm
            {...props}
            onSubmit={handleSubmit(onSubmit)}
            id={props.name}
            role="form">
            {rows.map((row) => (
                <Box 
                    full
                    loose
                    direction="row" 
                    key={row.order}
                    style={{
                        margin: 0,
                        padding: 0
                    }}>
                    {row.inputs.map((input) => (
                        <Box 
                            key={input.name as string}
                            full
                            direction="column"
                            style={input.modeProps?.hidden ? {
                                padding: "0",
                                margin: "0",
                                display: "none" 
                            } : { 
                                display: "flex",
                                padding: "0.5rem"
                            }}>
                            {input.label && input.showLabel && (
                                <Text
                                    aria-label={`label: ${input.label}`}
                                    caps
                                    size="sm"
                                    text={input.label}
                                    variant="regular" />
                            )}
                            <Controller
                                render={(controllerProps) => (
                                    <Input
                                        form={props.name}
                                        mode={input.mode}
                                        removeStepper={input.removeStepper}
                                        value={controllerProps.field.value}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
                                        ) => {
                                            setValue(
                                                input.name, 
                                            e.target.value as PathValue<TFormInputs, Path<TFormInputs>>
                                            );}}
                                        name={input.name}
                                        style={input.style}
                                        modeProps={input.modeProps}
                                        select={input.select}
                                    />
                                )}
                                control={control}
                                rules={input.validations}
                                defaultValue={input.defaultValue}
                                name={input.name} />
                            <ErrorMessage
                                errors={errors}
                                name={input.name as unknown as FieldName<
                                    FieldValuesFromFieldErrors<
                                        Partial<FieldErrorsImpl<DeepRequired<TFormInputs>>>
                                    >>}
                                render={({message}) => (
                                    <Text
                                        size="xs"
                                        variant="error"
                                        text={message} />
                                )} />
                        </Box>
                    ))}
                </Box>
            ))}
            {props.children}
            {buttonRows.map((row) => (
                <Box
                    key={row.order}
                    direction="row"
                    style={{
                        margin: "0"
                    }}>
                    {row.buttons.map((button, index) => (
                        <Button
                            form={props.name}
                            key={`${button.row}.${index}`}
                            {...button} />
                    ))}
                </Box>
            ))}
        </StyledForm>
    );
};
