import React, { FunctionComponent, useMemo, useReducer } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { appRoutes, TAppRoutes } from "./AppLayout.types";
import { DonkeyBar } from "@app/components";
import { TLayoutProps } from "@common/types";
import { RouteMapper, ResponseNotes } from "@common/components";
import { NotesDispatchContext, NotesStateContext } from "@app/context/NotesContext";
import { INIT_NOTES_STATE, initNotesState, notesContextReducer } from "@app/context/NotesContext.reducer";
import { NotFound } from "@app/pages";

export const AppLayout: FunctionComponent<TLayoutProps> = (props) => {
    const router = useMemo(() => {
        return createBrowserRouter(
            createRoutesFromElements(
                <Route 
                    errorElement={(
                        <React.Fragment>
                            <DonkeyBar />
                            <NotFound />
                        </React.Fragment>)} 
                    element={<DonkeyBar />}>
                    {RouteMapper<TAppRoutes>({
                        routes: appRoutes
                    })}
                </Route>
            )
        );
    }, []); 
    const [state, dispatch] = useReducer(
        notesContextReducer,
        INIT_NOTES_STATE,
        initNotesState
    );
    const _RouterProvider = useMemo(() => {
        return <RouterProvider router={router} />;
    }, [router]);
    return (
        <React.Fragment>
            <NotesDispatchContext.Provider value={dispatch}>
                <NotesStateContext.Provider value={state}>
                    <ResponseNotes />
                </NotesStateContext.Provider>
                {_RouterProvider}
            </NotesDispatchContext.Provider>
        </React.Fragment>
        
    );
};
