import React, { FunctionComponent, useEffect, useState } from "react";
import { TGamePanelProps } from "./GamePanel.types";
import { Avatar, Box, Text, Thing } from "@common/components";
import { Donkey, IconBadge, Trophy } from "@common/components/Icon";
import { useNavigate } from "react-router";
import { gameRoutes } from "@game/routes";
import { useAppState } from "@app/context";
import { parseToOrdinal } from "@utils/parsers";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { TTicketResponse } from "@ticket/types";

export const GamePanel: FunctionComponent<TGamePanelProps> = ({
    game,
    ...props
}) => {
    const navigate = useNavigate();
    const appState = useAppState();
    const [ticket, setTicket] = useState<TTicketResponse>();
    const [ticketData, setTicketData] = useState<TFetchTicketsData>({
        method: "GET",
        trigger: false
    });
    const {
        code: ticketCode,
        body: ticketBody
    } = useFetchTickets(ticketData);
    useEffect(() => {
        if (!appState.user || !appState.user.tickets?.find(
            _t => _t._gameId === game._id
        )) return setTicket(undefined);
        if (ticket) return;
        const _ticket = appState.user.tickets.find(
            _t => _t._gameId === game._id
        );
        if (!_ticket) return;
        setTicketData((prev) => ({
            ...prev,
            _ticketId: _ticket._id,
            trigger: true
        }));
    }, [appState, game, ticket]);
    useEffect(() => {
        if (!ticketCode) return;
        setTicketData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (ticketCode === 200) {
            setTicket(ticketBody as TTicketResponse);
        }
    }, [ticketCode, ticketBody]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0
            }}>
            <Box
                aria-label={`game-panel: ${game.id}`}
                direction="row"
                full
                onClick={(event) => navigate(gameRoutes.oneGame.urlBuilder({_gameId: game._id}))}
                style={{
                    cursor: "pointer",
                    padding: 0,
                    flexWrap: "nowrap"
                }}>
                <Avatar
                    squared
                    size="xl"
                    avatar={{
                        avatar: {
                            _id: game.edition._tournamentId,
                            id: game.edition._tournamentId,
                            publicId: game.edition.tournament?.avatarSecureUrl || "",
                            secureUrl: game.edition.tournament?.avatarSecureUrl || "",
                            url: game.edition.tournament?.avatarUrl || "",
                            createdAt: game.edition.tournament?.createdAt,
                            updatedAt: game.edition.tournament?.updatedAt
                        },
                        file: undefined
                    }} />
                <Box
                    full
                    direction="column"
                    style={{
                        justifyContent: "center",
                        alignItems: "start",
                        paddingRight: 0
                    }}>
                    <Text
                        aria-label={`tournament-name: ${game.edition.tournament?.name}`}
                        component="h3"
                        size="sm"
                        text={game.edition.tournament?.name || "tournament name?"} />
                    <Text
                        aria-label={`edition-name: ${game.edition?.name}`}
                        component="h4"
                        size="sm"
                        text={game.edition.name} />
                    <Text
                        aria-label={`game-name: ${game.name}`}
                        component="h5"
                        size="sm"
                        text={game.name}
                        variant="special" />
                </Box>
            </Box>
            <Box
                full
                direction="row"
                style={{
                    justifyContent: "space-between",
                    padding: 0,
                }}>
                <IconBadge
                    text={`${game.entryFee.toFixed(2)}`}
                    textWeight="normal"
                    textSize="sm"
                    textVariant="title"
                    nature="coin"
                    size="md">
                    <Thing nature="coin" />
                </IconBadge>
                <IconBadge
                    text={`${game.players}`}
                    textWeight="normal"
                    textSize="sm"
                    textVariant="title"
                    nature="no-background"
                    size="md">
                    <Donkey nature="no-background" />
                </IconBadge>
                <IconBadge
                    text={`${game.purse?.toFixed(2)}`}
                    textWeight="normal"
                    textSize="sm"
                    textVariant="title"
                    nature="purse"
                    size="md">
                    <Thing nature="purse" />
                </IconBadge>
                <IconBadge
                    text={ticket
                        ? ticket.position !== undefined
                            ? parseToOrdinal(ticket.position)
                            : "-"
                        : "-"}
                    textWeight="normal"
                    textSize="sm"
                    textVariant="title"
                    nature="golden"
                    size="md">
                    <Trophy nature="golden" />
                </IconBadge>
            </Box>
        </Box>
    );
};
