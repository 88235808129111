import React, { FunctionComponent, useMemo, useState } from "react";
import { ITutorialSlide, TTutorialSlidesProps } from "./TutorialSlides.types";
import { Box } from "../Box";
import { boxFixedWidth } from "@theme";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const TutorialSlides: FunctionComponent<TTutorialSlidesProps> = ({
    slides,
    slideObjects,
    onExit,
    onFinish,
    style,
    ...props
}) => {
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "tutorialSlides"
    >("buttons", {keyPrefix: "tutorialSlides"});
    const _slides: typeof slides = useMemo(() => {
        if (slides) return slides;
        if (!slideObjects) return [];
        return slideObjects.map((_slideObject, index, array) => ({
            id: _slideObject.id,
            title: _slideObject.title,
            content: (
                <React.Fragment key={_slideObject.id}>
                    <Box full style={{justifyContent: "center"}}>
                        <img 
                            src={_slideObject.imageUrl} 
                            alt={_slideObject.altImage} 
                            role="img"
                            style={{
                                width: "75%",
                                height: "auto"
                            }} />
                    </Box>
                    {_slideObject.paragraphs?.map((paragraph, _index) => (
                        <Text
                            key={_index}
                            text={paragraph}
                            size="xs" style={{marginTop: "1rem", textAlign: "center"}} />
                    ))}
                </React.Fragment>
            ),
            previous: _slideObject.id === 1
                ? undefined
                : _slideObject.id - 1,
            next: _slideObject.id === array.length
                ? undefined
                : _slideObject.id + 1
        }));
    }, [slides, slideObjects]);
    const [active, setActive] = useState<ITutorialSlide["id"]>(1);
    return (
        <Box
            full
            loose
            max={boxFixedWidth}
            style={{
                overflow: "hidden",
                justifyContent: "center",
                padding: 0,
                position: "relative",
                height: "150%",
                ...style
            }} {...props}>
            {_slides.map((slide) => (
                <Box
                    key={slide.id}
                    direction="column"
                    style={{
                        padding: "1rem",
                        transition: "translate 1s, opacity 1.5s",
                        opacity: slide.id === active
                            ? 1
                            : 0,
                        alignItems: "center",
                        position: slide.id === active
                            ? "inherit"
                            : "absolute",
                        left: 0,
                        top: 0,
                        translate: slide.id === active
                            ? 0
                            : slide.id === active - 1
                                ? "-100% 0"
                                : slide.id === active + 1
                                    ? "100% 0"
                                    : `${100 * (slide.id - active)}% 0` 
                    }}>
                    {slide.icon && (
                        <Icon
                            size="xxl">
                            {slide.icon}
                        </Icon>
                    )}
                    <Text
                        text={slide.title}
                        component="h2"
                        size="lg"
                        variant="title"
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "1rem",
                            textAlign: "center"
                        }} />
                    {slide.content}
                    <Box
                        direction="column"
                        style={{
                            marginTop: "0.5rem",
                            alignItems: "stretch"
                        }}>
                        <Button
                            label={slide.next 
                                ? translateButton("readMore")
                                : translateButton("gotIt")}
                            caps
                            nature="action"
                            onClick={(event) => {
                                if (slide.next) return setActive(slide.next);
                                onFinish && onFinish();
                            }} />
                        {(slide.previous !== undefined || onExit) && (
                            <Button
                                label={slide.previous
                                    ? translateButton("previous")
                                    : translateButton("exit")}
                                caps
                                nature="decline"
                                onClick={(event) => {
                                    if (slide.previous) return setActive(slide.previous);
                                    onExit && onExit();
                                }} />
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
