import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { TGamePrizesProps } from "./GamePrizes.types";
import { Box, Loader, Paginated } from "@common/components";
import { GamePrizeRow } from "../GamePrizeRow";
import { TUserResponse } from "@auth/types";
import { TGameStanding, TPrizeResponse } from "@game/types";
import { GameStatusesEnum } from "@common/enums";
import { useFetchGames } from "@game/hooks";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { TTicketResponse } from "@ticket/types";
import { PrizeDetail } from "../PrizeDetail";
import { usePagination } from "@common/hooks";

export const GamePrizes: FunctionComponent<TGamePrizesProps> = ({
    game,
    ...props
}) => {
    const [mounted, setMounted] = useState<boolean>();
    const [showPrize, setShowPrize] = useState<TPrizeResponse>();
    const {
        fetch: fetchStandings
    } = useFetchGames({
        method: "GET",
        standings: true,
        _gameId: game._id,
        trigger: false
    });
    const [winningTickets, setWinningTickets] = useState<(TTicketResponse | undefined)[]>();
    const [ticketsData, setTicketsData] = useState<TFetchTicketsData>({
        method: "GET",
        trigger: false
    });
    const {
        code: ticketsCode,
        body: ticketsBody
    } = useFetchTickets(ticketsData);
    const {
        page,
        perPage,
        nextPage: handleNext,
        previousPage: handlePrevious,
        total,
        items: paginatedPrizes
    } = usePagination({
        items: game.prizes,
    });
    const winnersAndPrizes: {
        prize: TPrizeResponse, 
        player?: Partial<TUserResponse>
    }[] | undefined = useMemo(() => {
        if (!winningTickets) return;
        const _winnerAndPrizes: typeof winnersAndPrizes = paginatedPrizes.map(
            (prize) => ({
                prize: {
                    ...prize,
                    purse: game.purse
                        ? prize.fee * game.purse 
                        : undefined
                },
                player: winningTickets.find((winnerTicket) => {
                    if (!winnerTicket) return false;
                    if (winnerTicket._id === prize._ticketId) return true;
                    return false;
                })?.player || {
                    handle: "-",
                    avatar: undefined
                }
            })
        );
        return _winnerAndPrizes;
    }, [game, paginatedPrizes, winningTickets]);
    const fetchTickets = useCallback(async(playing?: boolean): Promise<void> => {
        let qs = "";
        if (playing) {
            // get standings for game, then
            if (!fetchStandings) return;
            const [standingsResponse, standingsBody] = await fetchStandings();
            if (standingsResponse?.status !== 200) return;
            // get ticket data from first prizes.length
            for (const standing of (standingsBody as TGameStanding[])
                .slice(0, game.prizes.length)
            ) {
                if (qs === "") {
                    qs += `_ticketId=${standing._ticketId}`;
                } else qs += `&_ticketId=${standing._ticketId}`;
            }
        } else {
            for (const prize of game.prizes) {
                if (qs === "") {
                    qs += `_ticketId=${prize._ticketId}`;
                } else {
                    qs += `&_ticketId=${prize._ticketId}`;
                }
            }
        }
        // fetch tickets...
        setTicketsData((prev) => ({
            ...prev, 
            qs,
            perPage: 100,
            trigger: true
        }));
    }, [fetchStandings, game]);
    useEffect(() => {
        if (mounted) return;
        setMounted(true);
        if (!game || !game.prizes) return;
        if (winningTickets) return;
        if ([GameStatusesEnum.PLAYING].includes(game.status)) {
            fetchTickets(true);
        } else if([
            GameStatusesEnum.FINISHED,
            GameStatusesEnum.AWARDED
        ].includes(game.status)) {
            fetchTickets();
        } else {
            setWinningTickets(game.prizes.map((_prize) => undefined));
        }
    }, [game, fetchTickets, winningTickets, mounted]);
    useEffect(() => {
        if (!ticketsCode) return;
        setTicketsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (ticketsCode === 200) {
            setWinningTickets(ticketsBody as TTicketResponse[]);
        }
    }, [ticketsCode, ticketsBody]);
    return (
        <Box
            full
            direction="column"
            style={{
                alignItems: "center",
                paddingRight: 0,
                paddingLeft: 0
            }}>
            {winnersAndPrizes ? (
                <Paginated
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    total={total}
                    page={page}
                    perPage={perPage}
                    hide={showPrize ? true : false}>
                    {winnersAndPrizes
                        .filter(wAndP => !showPrize
                            ? true
                            : wAndP.prize._id === showPrize._id)
                        .map((winnerAndprize) => {
                            return (
                                <GamePrizeRow
                                    key={winnerAndprize.prize._id}
                                    prize={winnerAndprize.prize}
                                    player={winnerAndprize.player}
                                    setShowPrize={setShowPrize} />
                            );
                        })}
                </Paginated>
            ) : <Loader inBox />}
            {showPrize && (
                <PrizeDetail
                    game={game}
                    ticket={showPrize._ticketId 
                        ? winningTickets?.find((_ticket) => {
                            if (!_ticket) return false;
                            return _ticket._id === showPrize._ticketId;
                        })
                        : undefined
                    }
                    prize={showPrize} 
                    style={{
                        marginTop: "1rem"
                    }} />
            )}
        </Box>
    );
};
