import React, { FunctionComponent } from "react";
import { TSignUpProps } from "./SignUp.types";
import { Box, Container, Donkey } from "@common/components";
import { SignUpForm } from "@auth/components";
import { useNavigate } from "react-router";
import { appRoutes } from "@app/routes";
import { boxFixedWidth } from "@theme";
import { IconHeader } from "@app/components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { useDocumentMeta } from "@common/hooks";

export const SignUp: FunctionComponent<TSignUpProps> = (props) => {
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const {t: translateMeta} = useTranslation<
        TTranslationNameSpace,
        "metadata"
    >("titles", {keyPrefix: "metadata"});
    const [,] = useDocumentMeta({
        title: translateMeta("signUp.title"),
        description: translateMeta("signUp.description")
    });
    const navigate = useNavigate();
    return (
        <Container
            style={{alignItems: "center"}}>
            <Box
                direction="column"
                max={boxFixedWidth}
                full
                style={{alignItems: "center"}}>
                <IconHeader
                    title="hee-haw!"
                    _Icon={<Donkey nature="no-background" />}
                    texts={[
                        translateText("signUp.description")
                    ]}
                    style={{
                        marginBottom: "1rem"
                    }} />
                <SignUpForm
                    onSuccess={() => navigate(appRoutes.home.url)} />
            </Box>
        </Container>
    );
};
