import React, { FunctionComponent } from "react";
import { TFirstTimeUseProps } from "./FirstTimeUse.types";
import { Box, Button, Container, Donkey, Icon, Loader, Text, Title } from "@common/components";
import { boxFixedWidth } from "@theme";
import { useNavigate } from "react-router";
import { appRoutes } from "@app/routes";
import { useDocumentMeta, useLocalStorage } from "@common/hooks";
import { helpCenterRoutes } from "@helpCenter/routes/HelpCenterLayout.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const FirstTimeUse: FunctionComponent<TFirstTimeUseProps> = (props) => {
    const navigate = useNavigate();
    const {t: translateIntro} = useTranslation<
        TTranslationNameSpace,
        "firstTime"
    >("intro", {keyPrefix: "firstTime"});
    const {t: translateMeta} = useTranslation<
        TTranslationNameSpace,
        "metadata"
    >("titles", {keyPrefix: "metadata"});
    const [,] = useDocumentMeta({
        title: translateMeta("firstTimeUse.title"),
        description: translateMeta("firstTimeUse.description")
    });
    const {setCurrent} = useLocalStorage<boolean>({
        key: "alreadyBeenHere",
        initialValue: false
    });
    return (
        <React.Suspense fallback={(
            <Loader block />
        )}>
            <Container 
                style={{
                    alignItems: "center"
                }}>
                <Icon
                    aria-label="welcome figure"
                    size="xxl">
                    <Donkey nature="no-background" />
                </Icon>
                <Box
                    full
                    direction="column"
                    max={boxFixedWidth}
                    style={{
                        paddingTop: 0,
                        alignItems: "center"
                    }}>
                    <Title
                        size="xxl"
                        text={translateIntro("welcome")}
                        style={{
                            marginBottom: "1rem"
                        }} />
                    <Text
                        text={translateIntro("texts.0")}
                        size="sm" 
                        style={{
                            marginBottom: "1rem"
                        }}/>
                    <Text
                        text={translateIntro("texts.1")} 
                        size="sm"
                        style={{
                            marginBottom: "2rem"
                        }} />
                    <Button
                        label={translateIntro("buttons.new")}
                        caps
                        nature="action"
                        onClick={() => {
                            setCurrent(true);
                            navigate(helpCenterRoutes.introTutorial.url);
                        }} />
                    <Button
                        label={translateIntro("buttons.already")}
                        caps
                        nature="accept"
                        onClick={() => {
                            setCurrent(true);
                            navigate(appRoutes.home.url);
                        }} />
                </Box>
            </Container>
        </React.Suspense>
    );
};
