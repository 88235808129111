import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TGameInfoProps } from "./GameInfo.types";
import { Box, Button, Terminal } from "@common/components";
import { GameStatusesEnum } from "@common/enums";
import { hoursLeft, isImminent, translateDate } from "@utils/helpers";
import { ticketRoutes } from "@ticket/routes/TicketLayout.types";
import { useNavigate } from "react-router";
import { TGameStanding } from "@game/types";
import { TFetchGamesData, useFetchGames } from "@game/hooks";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GameInfo: FunctionComponent<TGameInfoProps> = ({
    game,
    player,
    setActive,
    ...props
}) => {
    const {t: translateGameInfo} = useTranslation<
        TTranslationNameSpace,
        "gameInfo"
    >("gameBoxes", {keyPrefix: "gameInfo"});
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "gameBox"
    >("buttons", {
        keyPrefix: "gameBox"
    });
    const [standings, setStandings] = useState<TGameStanding[]>();
    const [standingsData, setStandingsData] = useState<TFetchGamesData>({
        method: "GET",
        standings: true,
        _gameId: game._id,
        trigger: false
    });
    const {
        code: standingsCode,
        body: standingsBody,
        loading: standingsLoading
    } = useFetchGames(standingsData);
    const navigate = useNavigate();
    const gameTerminalMessage: string = useMemo(() => {
        if (game.status === GameStatusesEnum.OPEN) {
            const _hoursLeft = hoursLeft(
                translateDate({
                    start: game.startsAt,
                    ms: - 1 * 3600 * 1000
                })
            );
            if (player) return translateGameInfo("terminal.userIsPlayer", {
                count: parseInt(_hoursLeft) > 0
                    ? parseInt(_hoursLeft)
                    : 0
            });
            return translateGameInfo("terminal.openForInscriptions", {
                count: parseInt(_hoursLeft) > 0
                    ? parseInt(_hoursLeft)
                    : 0
            });
        }
        if ([
            GameStatusesEnum.PLAYING,
            GameStatusesEnum.FINISHED,
            GameStatusesEnum.AWARDED
        ].includes(game.status)) {
            if (!standings) {
                if (standingsLoading) {
                    return translateGameInfo("terminal.loadingStandings");
                } else return translateGameInfo("terminal.standingsFail");
            } 
            const _standings = standings.length > 7
                ? standings.slice(0, 7)
                : [...standings];
            let text = translateGameInfo("terminal.topScores");
            for (const _standing of _standings) {
                text += `⭐x${_standing.score}\t\t@${_standing.handle}\n`;
            }
            return text;
        }
        return translateGameInfo("terminal.underConstruction");
    }, [game, player, standings, standingsLoading, translateGameInfo]);
    useEffect(() => {
        if (![
            GameStatusesEnum.CREATED,
            GameStatusesEnum.OPEN
        ].includes(game.status)) {
            setStandingsData((prev) => ({
                ...prev,
                trigger: true
            }));
        }
    }, [game]);
    useEffect(() => {
        if (!standingsCode) return;
        setStandingsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (standingsCode === 200) {
            setStandings(standingsBody as TGameStanding[]);
        }
    }, [standingsBody, standingsCode]);
    return (
        <Box
            aria-label={`game-info: ${game.id}`}
            full
            direction="column"
            style={{
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 0,
                paddingLeft: 0
            }}
            {...props}>
            <Terminal
                caps
                noScroll
                item="game"
                itemId={game.id}
                content={gameTerminalMessage} />
            {[GameStatusesEnum.OPEN, GameStatusesEnum.PLAYING].includes(game.status) && player && (
                <Button
                    style={{
                        marginTop: "2rem"
                    }}
                    label={translateButton("makePicks")}
                    onClick={(event) => setActive && setActive("picks")} 
                    caps
                    nature="accept"
                    size="sm" />
            )}
            {[GameStatusesEnum.OPEN].includes(game.status) && (
                game.startsAt && !isImminent(game.startsAt)
            ) && !player && (
                <Button
                    style={{
                        marginTop: "2rem"
                    }}
                    label={translateButton("buyIn")}
                    onClick={(event) => navigate(ticketRoutes.buyIn.urlBuilder({
                        _gameId: game.id
                    }))}
                    caps
                    nature="action"
                    size="sm" />)}
        </Box>
    );
};
