import styled, { DefaultTheme, StyledComponent, css } from "styled-components";
import { TTextComponent, TTextProps } from "./Text.types";
import { fontSizes, fonts, palettes } from "@theme";
import theme from "styled-theming";

const baseStyles = css`
    width: auto;
    padding: 0;
    margin: 0;
    font-family: ${fonts.main};
    font-size: ${fontSizes.xs};
`;

const colorStyles = theme.variants(
    "mode",
    "variant", {
        active: {
            light: css`color: ${palettes.light.textActive};`,
            dark: css`color: ${palettes.dark.textActive};`
        },
        regular: {
            light: css`color: ${palettes.light.textRegular};`,
            dark: css`color: ${palettes.dark.textRegular};`
        },
        special: {
            light: css`color: ${palettes.light.textSpecial};`,
            dark: css`color: ${palettes.dark.textSpecial};`
        },
        title: {
            light: css`color: ${palettes.light.textTitle};`,
            dark: css`color: ${palettes.dark.textTitle};`
        },
        error: {
            light: css`color: ${palettes.light.textError};`,
            dark: css`color: ${palettes.dark.textError};`
        },
        info: {
            light: css`color: ${palettes.light.textInfo};`,
            dark: css`color: ${palettes.dark.textInfo};`
        }
    }
);

function styleAsLink(please?: boolean) {
    if (!please) return "";
    return css`
    text-decoration: underline;
    &:hover {
        opacity: 0.5;
        }
    `;
};

export const textElements: Record<TTextComponent, {
    Element: StyledComponent<
        TTextComponent, DefaultTheme, TTextProps
    >;
}> = {
    h1: {
        Element: styled.h1<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `
                font-size: ${fontSizes[props.size]};
                font-weight: normal !important;    
            `}
            ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
    h2: {
        Element: styled.h2<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `font-size: ${fontSizes[props.size]};
            font-weight: normal !important;    
        `}
        ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
    h3: {
        Element: styled.h3<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `font-size: ${fontSizes[props.size]};
            font-weight: normal !important;    
        `}
        ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
    h4: {
        Element: styled.h4<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `font-size: ${fontSizes[props.size]};
            font-weight: normal !important;    
        `}
        ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
    h5: {
        Element: styled.h5<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `font-size: ${fontSizes[props.size]};
            font-weight: normal !important;    
        `}
        ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
    p: {
        Element: styled.p<TTextProps>`
            ${baseStyles}
            ${colorStyles}
            ${props => props.size && `font-size: ${fontSizes[props.size]};
            font-weight: normal !important;    
        `}
        ${props => props.whiteSpace
        ? `white-space: ${props.whiteSpace};`
        : "white-space: pre-line;"}
        word-break: break-word; 
        ${props => styleAsLink(props.asLink)}
    `},
};
