import { TBoxProps, TTerminalProps } from "@common/components";
import React from "react";

export interface IRuleSlide {
    id: number;
    name: string;
    terminal?: string;
    children?: React.ReactNode;
    caps?: boolean;
    subtitle?: string;
};

export type THomeRuleSlideProps = TBoxProps & {
    slide: IRuleSlide;
    item?: TTerminalProps["item"];
};

export const homeRules: IRuleSlide[] = [{
    id: 1,
    name: "GENERAL",
    terminal: "terminal.general" // `⌛ USERS MAY PICK A SCORE FOR EACH MATCH UP TO TEN MINUTES BEFORE SCHEDULED KICKOFF\n\n🤓 SCORES WILL BE ALLOWED AS INTEGER POSITIVE NUMBERS\n\n🏆 GAMES PRIZE STRUCTURE WILL PROPORTIONALLY READJUST IF THERE ARE LESS THAN 7 PLAYERS ON A PUBLIC GAME\n\n⚽ A PLAYER MAY BUY ONE TICKET FOR A GAME, AND ITS SCORE WILL BE THE SUM OF EACH MATCH PREDICTION POINTS\n\n👮🏻‍♀️ SCHEDULED MATCH KICKOFF DATE AND TIME REFERS TO OFFICIAL FEDERATIONS INFORMATION \n`
}, {
    id: 2,
    name: "SCORING",
    terminal: "terminal.scoring" // `YOU GET:\n\n⭐ X3 FOR GUESSING MATCH RESULT (HOME, TIE, AWAY)\n\n⭐ X1 FOR GUESSING HOME OR AWAY SCORE\n\n⭐ X3 FOR GUESSING BOTH SCORES\n`,
}, {
    id: 3,
    name: "WINNING",
    terminal: "terminal.winning" // `🏆 WINNING POSITIONS WILL BE DETERMINED BASED ON THE TOTAL POINTS ACCUMULATED BY A PLAYER THROUGH EACH MATCH INCLUDED IN THE GAME.\n\n⭐ IF TWO OR MORE PLAYERS WERE TO HAVE THE SAME AMOUNT OF POINTS AT ANY POINT DURING THE GAME, THE FOLLOWING CRITERIA WILL BE USED TO SOLVE THE TIE:\n\n1. PLAYER WITH MORE FULL POINTS PICKS (MORE 6 POINT MATCHES)\n\n2. PLAYER WITH LESS NO POINT PICKS (LESS 0 POINT MATCHES)\n\n3. PLAYER WITH MORE GUESSED MATCH OUTCOMES (MORE 3, 4 & 6 POINTS MATCHES)\n\n4. FORMULA IS AS FOLLOWS: 2 * 6-POINTERS + 4-POINTERS + 3-POINTERS - NO-POINTERS\n\n🥇 TIED PLAYER POSITIONS WILL BE SORTED BASED ON THE RESULT FOR THE PREVIOUS FORMULA; IF STILL TIED, PLAYERS WILL EQUALLY SHARE THE SUM OF PRIZES FOR THE POSITIONS THEY OCCUPY.\n`
}, {
    id: 4,
    name: "EXCEPTIONS",
    terminal: "terminal.exceptions" // `👮🏻‍♀️ PICKS SCORES WILL BE DEFINITELY SET AFTER MATCH RESULTS ARE OFFICIALLY CONFIRMED\n\n⌚ IF A MATCH IS DELAYED FOR MORE THAN 24 HOURS, SCHEDULED TIME WILL BE UPDATED SO THAT SCORE PICKING RESTRICTIONS ADJUST TO NEW KICKOFF DATE AND TIME\n\n`
}];
