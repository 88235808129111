import React, { FunctionComponent, useMemo } from "react";
import { StyledLoader, StyledLoaderCell } from "./Loader.styles";
import { TLoaderProps, loaderLengths, loaderSizes, loaderVariants } from "./Loader.types";
import { useTheme } from "styled-components";
import { Box } from "../Box";
import { Snake } from "@common/animations/Snake";

export const Loader: FunctionComponent<TLoaderProps> = ({
    variant="rainbow",
    speed="regular",
    loaderSize="xl",
    loaderLength="regular",
    block,
    pattern = "circle",
    inBox,
    ...props
}) => {
    const theme = useTheme();
    const snake = useMemo(() => {
        return Snake.start(
            pattern,
            loaderSizes[inBox 
                ? "md" 
                : loaderSize || "xs"],
            loaderVariants[variant || "standard"][theme.mode],
            loaderLengths[loaderLength || "regular"]
        );
    }, [pattern, loaderSize, variant, loaderLength, theme.mode, inBox]); 
    return (
        <React.Fragment>
            {block && (
                <Box
                    full
                    direction="column"
                    loose
                    style={{
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <StyledLoader
                        aria-label="loader" 
                        size={snake.size * 7}
                        inBox={inBox}
                        {...props}> 
                        {snake.cells.map((cell) => cell
                            .versions.map((version, index) => (
                                <StyledLoaderCell
                                    key={`${cell.id}-${index}`}
                                    cell={cell}
                                    version={version}
                                    speed={speed}
                                    size={snake.size} />
                            ))
                        )}
                    </StyledLoader>
                </Box>
            )} {!block && (
                <StyledLoader
                    aria-label="loader" 
                    size={snake.size * 7}
                    inBox={inBox}
                    {...props}> 
                    {snake.cells.map((cell) => cell
                        .versions.map((version, index) => (
                            <StyledLoaderCell
                                key={`${cell.id}-${index}`}
                                cell={cell}
                                version={version}
                                speed={speed}
                                size={snake.size} />
                        ))
                    )}
                </StyledLoader>
            )}
        </React.Fragment>
    );
};
