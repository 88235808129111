import styled from "styled-components";
import { Box } from "@common/components";
import { boxFixedWidth, breakpoints } from "@theme";

export const StyledHomeRuleSlide = styled(Box)`
    max-width: ${boxFixedWidth};
    @media screen and ${breakpoints.md} {
        max-width: 100ch;
    }
`;
