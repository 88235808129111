import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TGameStandingsProps } from "./GameStandings.types";
import { Box, ITableHeader, ITableRow, Icon, Loader, Paginated, SearchBar, Table, Text, Thing } from "@common/components";
import { TGameStanding, TPickResponse } from "@game/types";
import { TFetchGamesData, useFetchGames } from "@game/hooks";
// import { parseToOrdinal } from "@utils/parsers";
import { GameMatchBox, TGameMatchBoxProps, TSetShowPicksFor } from "../GameMatchBox";
import { TCompetitionsMatchResponse, TFetchMatchesData, useFetchMatches } from "@competitionsApi/hooks";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { usePagination } from "@common/hooks";
import { useTranslation } from "react-i18next";
import { parseToOrdinal } from "@utils/parsers";

export const GameStandings: FunctionComponent<TGameStandingsProps> = ({
    game,
    player,
    ...props
}) => {
    const {i18n} = useTranslation();
    const [filteredItems, setFilteredItem] = useState<TGameStanding[]>();
    const [ticketPicksData, setTicketPicksData] = useState<TFetchTicketsData>({
        method: "GET",
        picks: true,
        trigger: false
    });
    const {
        code: ticketPicksCode,
        body: ticketPicksBody,
        loading: ticketPicksLoading
    } = useFetchTickets(ticketPicksData);
    const [showPicksFor, setShowPicksFor] = useState<TSetShowPicksFor<"ticket">>();
    const [standings, setStandings] = useState<TGameStanding[]>();
    const [picks, setPicks] = useState<TPickResponse[]>();
    const [standingsData, setStandingsData] = useState<TFetchGamesData>({
        method: "GET",
        _gameId: game._id,
        trigger: true,
        standings: true
    });
    const {
        body: standingsBody,
        code: standingsCode,
        loading: standingsLoading
    } = useFetchGames(standingsData);
    const [competitionsMatches, setCompetitionsMatches] = useState<
        TCompetitionsMatchResponse[]
    >();
    const [competitionsMatchesData, setCompetitionsMatchesData] = useState<
        TFetchMatchesData
    >({
        trigger: false
    });
    const {
        code: competitionsMatchesCode,
        body: competitionsMatchesBody,
        loading: competitionsMatchesLoading
    } = useFetchMatches(competitionsMatchesData);
    const headers: ITableHeader[] = useMemo(() => {
        const _headers: typeof headers = [{
            width: "20%",
            alignment: "left"
        }, {
            width: "60%",
            alignment: "left"
        }, {
            width: "20%",
            alignment: "center"
        }];
        return _headers;
    }, []);
    const {
        page,
        perPage,
        nextPage: handleNext,
        previousPage: handlePrevious,
        total,
        items: paginatedStandings
    } = usePagination({
        items: standings,
    });
    const rows: ITableRow[] = useMemo(() => {
        if (!paginatedStandings) return [];
        const _standingsToShow = filteredItems
            ? filteredItems.filter((_item) => showPicksFor
                ? _item._ticketId === showPicksFor._ticketId
                : true)
            : paginatedStandings.filter((_item) => showPicksFor
                ? _item._ticketId === showPicksFor._ticketId
                : true);
        const _rows: typeof rows = _standingsToShow.map((_standing, index) => ({
            values: [
                `${_standing.position
                    ? parseToOrdinal(
                        _standing.position, 
                        i18n.language) 
                    : 0}`,
                `@${_standing.handle}`,
                <Box
                    key={index}
                    full
                    style={{
                        justifyContent: "start",
                        padding: 0,
                        marginTop: "0.3rem"
                    }}>
                    <Icon
                        nature={showPicksFor?._ticketId === _standing._ticketId 
                            ? "gold-star" 
                            : "gray-star"}
                        size="xs"
                        onClick={(event) => setShowPicksFor((prev) => (prev 
                            ? undefined
                            : {
                                _ticketId: _standing._ticketId
                            }))}>
                        <Thing nature={showPicksFor?._ticketId === _standing._ticketId 
                            ? "gold-star" 
                            : "gray-star"} />
                    </Icon>
                    <Text
                        text={`x${_standing.score}`}
                        size="xs" />
                </Box>
            ]
        }));
        return _rows;
    }, [paginatedStandings, showPicksFor, filteredItems, i18n]);
    const picksAndMatches: Required<
    Pick<
        TGameMatchBoxProps, 
        "competitionsMatch" | "userPick"
    >>[] | undefined = useMemo(() => {
        if (!competitionsMatches) return;
        const _picksAndMatches: typeof picksAndMatches = competitionsMatches.map(
            (_competitionMatch) => {
                const _gameMatch = game.matches.find(
                    (_gm) => _gm.match._matchId === _competitionMatch._id
                );
                return {
                    competitionsMatch: _competitionMatch,
                    userPick: picks?.find(_pk => _pk._gameMatchId === _gameMatch?._id) as TPickResponse
                };
            }
        );
        return _picksAndMatches;
    }, [picks, competitionsMatches, game]);
    useEffect(() => {
        if (!standingsCode) return;
        setStandingsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (standingsCode === 200) {
            setStandings(standingsBody as TGameStanding[]);
        }
    }, [standingsBody, standingsCode]);
    useEffect(() => {
        if (!ticketPicksCode) return;
        setTicketPicksData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (ticketPicksCode === 200) {
            setPicks(ticketPicksBody as TPickResponse[]);
        }
    }, [ticketPicksCode, ticketPicksBody]);
    useEffect(() => {
        if (!showPicksFor) {
            setPicks(undefined);
            return;
        }
        setTicketPicksData((prev) => ({
            ...prev,
            _ticketId: showPicksFor._ticketId,
            trigger: true,
            picks: true
        }));
    }, [showPicksFor]);
    useEffect(() => {
        if (competitionsMatches) return;
        let qs = "";
        for (const _gameMatch of game.matches) {
            if (qs === "") {
                qs = `_matchId=${_gameMatch.match._matchId}`;
            } else {
                qs += `&_matchId=${_gameMatch.match._matchId}`;
            }
        }
        setCompetitionsMatchesData((prev) => ({
            ...prev,
            method: "GET",
            qs: qs,
            trigger: true
        }));
    }, [game, competitionsMatches]);
    useEffect(() => {
        if (!competitionsMatchesCode) return;
        setCompetitionsMatchesData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (competitionsMatchesCode === 200) {
            setCompetitionsMatches(competitionsMatchesBody as TCompetitionsMatchResponse[]);
        }
    }, [competitionsMatchesBody, competitionsMatchesCode]);
    return (
        <Box    
            full
            direction="column"
            style={{
                alignItems: "center",
                padding: 0,
                minHeight: "10rem",
                justifyContent: "center"
            }}>
            {standingsLoading && (
                <Loader inBox />
            )}
            {standings && (
                <SearchBar
                    mode="filter"
                    items={standings}
                    filterBy="handle"
                    disabled={showPicksFor === undefined ? false : true}
                    onFilterChange={(_items) => setFilteredItem(_items)}>
                    <Paginated
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        page={page}
                        perPage={perPage}
                        total={total}
                        hide={(showPicksFor || filteredItems) ? true : false}>
                        <Table
                            aria-label="standings table"
                            headers={headers || []}
                            rows={rows || []} />
                    </Paginated>
                </SearchBar>
            )}
            {showPicksFor && (
                <React.Fragment>
                    {!ticketPicksLoading && !competitionsMatchesLoading && picksAndMatches ? (
                        <React.Fragment>
                            {picksAndMatches.map((pick, index) => (
                                <GameMatchBox
                                    key={index}
                                    competitionsMatch={pick.competitionsMatch}
                                    userPick={pick.userPick}
                                    publicView />
                            ))}
                        </React.Fragment>
                    ) : (
                        <Loader inBox />
                    )}
                </React.Fragment>
            )}
        </Box>
    );
};