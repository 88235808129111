import { breakpoints } from "@theme";
import styled from "styled-components";
import { TContainerProps } from "./Container.types";

export const StyledContainer = styled.div<Partial<TContainerProps>>`
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    padding: 2rem ${props => props.fluid
        ? 0
        : props.xxs 
            ? props.xxs 
            : "1rem"
};
    margin: 0;
    @media screen and ${breakpoints.xs} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.xs 
            ? props.xs 
            : "5rem"
};
    }
    @media screen and ${breakpoints.sm} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.sm 
            ? props.sm 
            : "7rem"
};
    }
    @media screen and ${breakpoints.md} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.md 
            ? props.md 
            : "12rem"
};
    }
    @media screen and ${breakpoints.lg} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.lg 
            ? props.lg 
            : "15rem"
};
    }
    @media screen and ${breakpoints.xl} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.xl 
            ? props.xl 
            : "17rem"
};
    }
    @media screen and ${breakpoints.xxl} {
        padding: 2rem ${props => props.fluid
        ? 0
        : props.xxl 
            ? props.xxl 
            : "20rem"
};
    }
`;