import { Box, TBoxProps } from "@common/components";
import { boxFixedWidth } from "@theme";
import styled from "styled-components";

export const StyledMetaMaskBox = styled(Box).attrs<Partial<TBoxProps>>({
    direction: "column",
    min: boxFixedWidth,
    max: boxFixedWidth,
})`
    background-color: ${props => props.theme.palette.backgroundContext};
    border: 3px solid ${props => props.theme.palette.borderContext};
    box-shadow: 4px 4px 0px 0px #bbb inset, -4px -4px 0px 0px #666 inset;
    border-radius: 0.5rem;
    align-items: center;
    margin: 0.5rem;
    justify-content: space-between;
    align-items: center;
`;
