import { useEffect, useState } from "react";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { IFetchGameMatches, TFetchGameMatchesData } from "./useFetchGameMatches.types";
import { TGameMatchResponse, TPickResponse } from "@game/types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchGameMatches({
    _gameMatchId,
    method,
    data,
    trigger,
    successMessage,
    inform = true,
    options,
    ignoreHeaders,
    qs,
    picks,
    page,
    perPage,
    ...props
}: TFetchGameMatchesData): IFetchGameMatches {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("texts", {keyPrefix: "successMessages"});
    const [hookData, setHookData] = useState<TFetchData<Record<string, never>>>({
        endpointURI: _gameMatchId
            ? endpoints.gameMatches.buildUrl({
                _gameMatchId: _gameMatchId,
                picks: picks 
                    ? "yes" 
                    : undefined,
                qs: qs,
                page: page?.toString(),
                perPage: perPage?.toString()
            })
            : endpoints.gameMatches.buildUrl({
                qs: qs,
                page: page?.toString(),
                perPage: perPage?.toString()
            }),
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage
            ? successMessage
            : translateNote("gameMatchSuccessfully", {
                action: method === "POST"
                    ? translateText("created")
                    : translateText("updated")
            }),
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error,
        paginatedResponse
    } = useFetch<Record<string, never>, TGameMatchResponse | TPickResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _gameMatchId
                    ? endpoints.gameMatches.buildUrl({
                        _gameMatchId: _gameMatchId,
                        picks: picks 
                            ? "yes" 
                            : undefined,
                        qs: qs,
                        page: page?.toString(),
                        perPage: perPage?.toString()
                    })
                    : endpoints.gameMatches.buildUrl({
                        qs: qs,
                        page: page?.toString(),
                        perPage: perPage?.toString()
                    }),
                data: data,
                method: method,
                auth: method === "GET"
                    ? false
                    : true,
                successMessage: successMessage
                    ? successMessage
                    : translateNote("gameMatchSuccessfully", {
                        action: method === "POST"
                            ? translateText("created")
                            : translateText("updated")
                    }),
                inform: inform,
                options: options,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _gameMatchId,
        method,
        successMessage,
        options,
        inform,
        data,
        qs,
        picks,
        page,
        perPage,
        translateNote,
        translateText
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error,
        paginatedResponse
    };
};
