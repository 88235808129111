import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TGamePicksProps } from "./GamePicks.types";
import { Box, Button, Loader } from "@common/components";
import { UserPicks } from "../UserPicks";
import { GameMatches } from "../GameMatches";
import { TTicketResponse } from "@ticket/types";
import { TCompetitionsMatchResponse, TFetchMatchesData, useFetchMatches } from "@competitionsApi/hooks";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GamePicks: FunctionComponent<TGamePicksProps> = ({
    game,
    player,
    ...props
}) => {
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "gamePicks"
    >("buttons", {keyPrefix: "gamePicks"});
    const [competitionsMatches, setCompetitionsMatches] = useState<
        TCompetitionsMatchResponse[]
    >();
    const [competitionsMatchesData, setCompetitionsMatchesData] = useState<
        TFetchMatchesData
    >({
        trigger: false,
        method: "GET"
    });
    const {
        code: competitionsMatchesCode,
        body: competitionsMatchesBody,
        loading: competitionsMatchesLoading
    } = useFetchMatches(competitionsMatchesData);
    const [active, setActive] = useState<"my-picks" | "matches">("my-picks");
    const ticket: TTicketResponse | undefined = useMemo(() => {
        return player?.tickets.find((_ticket) => _ticket._gameId === game._id);
    }, [player, game]);
    useEffect(() => {
        if (competitionsMatches) return;
        let qs = "";
        for (const _gameMatch of game.matches) {
            if (qs === "") {
                qs = `_matchId=${_gameMatch.match._matchId}`;
            } else {
                qs += `&_matchId=${_gameMatch.match._matchId}`;
            }
        }
        setCompetitionsMatchesData((prev) => ({
            ...prev,
            method: "GET",
            qs: qs,
            trigger: true
        }));
    }, [game, competitionsMatches]);
    useEffect(() => {
        if (!competitionsMatchesCode) return;
        setCompetitionsMatchesData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (competitionsMatchesCode === 200) {
            setCompetitionsMatches(competitionsMatchesBody as TCompetitionsMatchResponse[]);
        }
    }, [competitionsMatchesBody, competitionsMatchesCode]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0,
                alignItems: "center"
            }}>
            {player && (
                <Box
                    full
                    direction="row"
                    style={{
                        padding: 0,
                        justifyContent: "center"
                    }}>
                    <Button
                        size="xs"
                        nature={active === "my-picks" ? "accept" : "disabled"}
                        caps
                        label={translateButton("myPicks")}
                        onClick={(event) => setActive("my-picks")}
                        pushed={active === "my-picks"}
                        style={{
                            margin: "1rem 0.5rem 1rem 0"
                        }} />
                    <Button
                        size="xs"
                        nature={active === "matches" ? "accept" : "disabled"}
                        caps
                        label={translateButton("matches")}
                        onClick={(event) => setActive("matches")}
                        pushed={active === "matches"}
                        style={{
                            margin: "1rem 0rem 1rem 0.5"
                        }} />
                </Box>
            )}
            {competitionsMatchesLoading ? (
                <Box
                    full
                    style={{
                        justifyContent: "center",
                        minHeight: "10rem"
                    }}>
                    <Loader inBox />
                </Box>
            ) : (
                <React.Fragment>
                    {player && active === "my-picks" && competitionsMatches && 
                        <UserPicks 
                            competitionsMatches={competitionsMatches}
                            game={game} 
                            ticket={ticket} />}
                    {player && active === "matches" && competitionsMatches && 
                        <GameMatches 
                            competitionsMatches={competitionsMatches}
                            game={game} />}
                    {!player && competitionsMatches && (
                        <GameMatches
                            competitionsMatches={competitionsMatches}
                            game={game} />
                    )}
                </React.Fragment>
            )}
        </Box>
    );
};
