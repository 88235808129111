import { ILayoutRoute } from "@common/types";
import { lazy } from "react";

const AuthLayout = lazy(() => import("@auth/routes").then(({
    AuthLayout
}) => ({default: AuthLayout})));
const GameLayout = lazy(() => import("@game/routes").then(({
    GameLayout
}) => ({default: GameLayout})));
const UserLayout = lazy(() => import("@user/routes").then(({
    UserLayout
}) => ({default: UserLayout})));
const TicketLayout = lazy(() => import("@ticket/routes").then(({
    TicketLayout
}) => ({default: TicketLayout})));
const HelpCenterLayout = lazy(() => import("@helpCenter/routes").then(({
    HelpCenterLayout
}) => ({default: HelpCenterLayout})));

const FirstTimeUse = lazy(() => import("@app/pages").then(({
    FirstTimeUse
}) => ({default: FirstTimeUse})));
const Landing = lazy(() => import("@app/pages").then(({
    Landing
}) => ({default: Landing})));
const LogIn = lazy(() => import("@app/pages").then(({
    LogIn
}) => ({default: LogIn})));
const SignUp = lazy(() => import("@app/pages").then(({
    SignUp
}) => ({default: SignUp})));
const NotFound = lazy(() => import("@app/pages").then(({
    NotFound
}) => ({
    default: NotFound
})));

export type TAppRoutes = "home"
    | "logIn"
    | "tickets"
    | "games"
    | "users"
    | "notFound"
    | "welcome"
    | "helpCenter"
    | "signUp"
    | "validate";

export const appRoutes: Record<TAppRoutes, ILayoutRoute> = {
    home: {
        _id: 1,
        patterns: ["/", "/home"],
        url: "/",
        element: Landing,
        urlBuilder: (params) => params?.hash
            ? `/home#${params.hash}`
            : "/"
    },
    logIn: {
        _id: 2,
        patterns: ["/log-in"],
        url: "/log-in",
        urlBuilder: () => "/log-in",
        element: LogIn
    },
    tickets: {
        _id: 3,
        patterns: ["/tickets/*"],
        url: "/tickets",
        urlBuilder: () => "/tickets",
        element: TicketLayout,
        mode: "layout"
    },
    games: {
        _id: 4,
        patterns: ["/games/*"],
        url: "/games",
        urlBuilder: () => "/games",
        element: GameLayout,
        mode: "layout"
    },
    users: {
        _id: 5,
        patterns: ["/users/*"],
        url: "/users",
        urlBuilder: () => "/users",
        element: UserLayout,
        mode: "layout"
    },
    welcome: {
        _id: 6,
        patterns: ["/welcome"],
        url: "/welcome",
        urlBuilder: () => "/welcome",
        mode: "page",
        element: FirstTimeUse
    },
    helpCenter: {
        _id: 7,
        patterns: ["/help-center/*"],
        url: "/help-center",
        urlBuilder: () => "/help-center",
        mode: "layout",
        element: HelpCenterLayout
    },
    signUp: {
        _id: 9,
        patterns: ["/sign-up"],
        url: "/sign-up",
        urlBuilder: () => "/sign-up",
        element: SignUp
    },
    validate: {
        _id: 10,
        patterns: ["/validate/*"],
        url: "/validate",
        urlBuilder: () => "/help-center",
        mode: "layout",
        element: AuthLayout
    },
    notFound: {
        _id: 99,
        patterns: ["/not-found"],
        url: "/not-found",
        urlBuilder: () => "not-found",
        element: NotFound
    }
};
