export function parseTo(
    value: string, 
    output: "kebab" | "snake" | "camel",
    input: "camel" | "regular" = "camel"
) {
    let clean: string[] = [];
    let payload: string[] = [];
    if (input === "camel") {
        clean = value
            .replace(/[\s-_]/, "")
            .split(/(?=[A-Z][a-z]+)/);
        for (const word of clean) {
            const _words = word
                .split(/(?<![A-Z])(?=[A-Z])/);
            if (_words.length > 1) {
                payload.push(..._words);
                continue;
            } 
            payload.push(word);
        }
    } else {
        clean = value
            .toLowerCase()
            .split(" ");
        payload = clean;
    }
    if (payload.length < 1) throw new Error(
        "invalid input, unable to transform 😐"
    );
    let parsed = (payload.shift() as string)
        .toLowerCase();
    if (output === "camel") {
        for (const word of payload) {
            parsed += `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
        }
    } else {
        for (const word of payload) {
            parsed += `${output === "kebab" 
                ? "-" 
                : "_"}${word.toLowerCase()}`;
        }
    }
    return parsed;
};

export function asPercentage({
    value,
    hundredBased,
    decimals=0
}: {
    value: string | number;
    hundredBased?: boolean;
    decimals?: number;
}): string {
    let _value = 0;
    if (typeof value === 'string') _value = parseFloat(value);
    if (typeof value === 'number') _value = value;
    if (!hundredBased) _value = _value * 100;
    return `${_value.toFixed(decimals)}%`;
};
