import { useEffect, useState } from "react";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { IFetchPicks, TFetchPicksData } from "./useFetchPicks.types";
import { TPickPatchBody, TPickResponse } from "@game/types/Picks.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchPicks({
    _pickId,
    method,
    data,
    trigger,
    successMessage,
    inform = true,
    options,
    ignoreHeaders,
    qs,
    ...props
}: TFetchPicksData): IFetchPicks {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("texts", {keyPrefix: "successMessages"});
    const [hookData, setHookData] = useState<TFetchData<TPickPatchBody>>({
        endpointURI: _pickId
            ? endpoints.picks.buildUrl({
                _pickId: _pickId
            })
            : endpoints.picks.buildUrl({
                qs: qs
            }),
        trigger: trigger,
        data: data,
        method: method,
        auth: true,
        successMessage: successMessage
            ? successMessage
            : translateNote("pickSuccessfully", {
                action: method === "POST"
                    ? translateText("createdF")
                    : translateText("updatedF")
            }),
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error, 
        paginatedResponse
    } = useFetch<TPickPatchBody, TPickResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _pickId
                    ? endpoints.picks.buildUrl({
                        _pickId: _pickId
                    })
                    : endpoints.picks.buildUrl({
                        qs: qs
                    }),
                data: data,
                method: method,
                auth: true,
                successMessage: successMessage
                    ? successMessage
                    : translateNote("pickSuccessfully", {
                        action: method === "POST"
                            ? translateText("createdF")
                            : translateText("updatedF")
                    }),
                inform: inform,
                options: options,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _pickId,
        method,
        successMessage,
        options,
        inform,
        data,
        qs,
        translateNote,
        translateText
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error,
        paginatedResponse
    };
};
