import { useCallback, useMemo, useState } from "react";
import { IUsePagination, TUsePaginationData } from "./usePagination.types";

export function usePagination<ItemType extends object | string | number>({
    items,
    response,
    startingPage=1,
    customPerPage=10,
    dynamic=false,
    total,
    ...props
}: TUsePaginationData<ItemType>): IUsePagination<ItemType> {
    const [page, setPage] = useState<number>(startingPage);
    const [perPage ] = useState<number>(customPerPage);
    const previousPage = useCallback(() => {
        setPage((prev) => prev === 1 ? 1 : prev - 1);
    }, []);
    const nextPage = useCallback(() => {
        if (!items) return;
        const lastPage = Math.ceil(total || items.length / perPage);
        setPage((prev) => lastPage - prev > 0 
            ? prev + 1
            : prev 
        );
    }, [items, perPage, total]);
    const paginatedItems = useMemo(() => {
        if (!items) return [];
        if (!dynamic) return items.slice(
            (page - 1) * perPage,
            page * perPage
        );
        return items;
    }, [items, page, perPage, dynamic]);
    return {
        page: page,
        perPage: perPage,
        total: total || items?.length || 0,
        items: paginatedItems,
        previousPage,
        nextPage,
    };
};
