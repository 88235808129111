import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IResetPasswordFormInputs, TResetPasswordFormProps } from "./ResetPasswordForm.types";
import { Box, Form, IField, IFormButton } from "@common/components";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { TFetchPasswordResetRequestData, useFetchPasswordResetRequests } from "@auth/hooks";
import { useNavigate } from "react-router";
import { userRoutes } from "@user/routes";
import { useAppState } from "@app/context";

export const ResetPasswordForm: FunctionComponent<TResetPasswordFormProps> = ({
    ...props
}) => {
    const navigate = useNavigate();
    const appState = useAppState();
    const {t: translateValidation} = useTranslation<TTranslationNameSpace>("validations");
    const {t: translateInput} = useTranslation<TTranslationNameSpace>("inputs");
    const {t: translateButton} = useTranslation<TTranslationNameSpace>("buttons");
    const [passwordResetData, setPasswordResetData] = useState<TFetchPasswordResetRequestData>({
        method: "PATCH",
        trigger: false
    });
    const {
        code,
        error,
        loading
    } = useFetchPasswordResetRequests(passwordResetData);
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<IResetPasswordFormInputs>>();
    const fields: IField<IResetPasswordFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 3,
            mode: "input",
            name: "password",
            validations: {
                required: translateValidation("required.password"),
                validate: (value: string) => value.length >= 8 
                    ? true 
                    : translateValidation("validate.passwordLength") 
            },
            defaultValue: "",
            modeProps: {
                type: "password",
                autoComplete: "new-password",
                placeholder: translateInput("password.placeholder")
            }
        }, {
            row: 4,
            mode: "input",
            name: "confirm",
            validations: {
                required: translateValidation("required.confirmPassword"),
                validate: (value: string) => currentValues?.password === value 
                    ? true 
                    : translateValidation("validate.passwordMatch")
            },
            defaultValue: "",
            modeProps: {
                type: "password",
                autoComplete: "new-password",
                placeholder: translateInput("confirmPassword.placeholder")
            }
        }];
        return _fields;
    }, [translateInput, translateValidation, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: translateButton("passwordReset.requestReset"),
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "regular",
                variant: "white",
                loaderLength: "regular"
            },
            disabled: currentValues?.password !== currentValues?.confirm,
            nature:"accept"
        }];
        return _buttons;
    }, [loading, translateButton, currentValues]);
    const onSubmit: SubmitHandler<IResetPasswordFormInputs> = useCallback(
        async(payload) => {
            delete payload.confirm;
            setPasswordResetData((prev) => ({
                ...prev,
                method: "PATCH",
                data: payload,
                trigger: true
            }));
        }, []);
    useEffect(() => {
        if (!code && !error) return;
        setPasswordResetData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) return navigate(userRoutes.oneUser.urlBuilder({
            handle: appState.user?.handle
        }));
    }, [navigate, code, appState.user, error]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0
            }}>
            <Form
                aria-label="reset password form"
                name={"reset-password-form"}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues} />
        </Box>
    );
};
