import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { TReportTransactionProps } from "./ReportTransaction.types";
import { Box, Button, Input, Text } from "@common/components";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { TFetchUsersData } from "@auth/hooks/useFetchUsers/useFetchUsers.types";
import { useFetchUsers } from "@auth/hooks";
import { endpoints } from "@common/endpoints";
import { TUserResponse } from "@auth/types";
import { SignUpForm } from "@auth/components";
import { useNavigate } from "react-router";
import { addResponseNote, useAppState, useNotesDispatch } from "@app/context";
import { ticketRoutes } from "@ticket/routes/TicketLayout.types";
import { TTicketResponse } from "@ticket/types";
import { boxFixedWidth } from "@theme";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const ReportTransaction: FunctionComponent<TReportTransactionProps> = ({
    game,
    metaMaskHook,
    ...props
}) => {
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "metaMask"
    >("buttons", {keyPrefix: "metaMask"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "metaMask"
    >("texts", {keyPrefix: "metaMask"});
    const {t: translateInput} = useTranslation<
        TTranslationNameSpace
    >("inputs");
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace
    >("notes");
    const appState = useAppState();
    const notesDispatch = useNotesDispatch();
    const [account, setAccount] = useState<string>();
    const navigate = useNavigate();
    const [showUserSignUp, setShowUserSignUp] = useState<boolean>();
    const [txHash, setTxHash] = useState<string>("");
    const [ticketsData, setTicketsData] = useState<TFetchTicketsData>({
        method: "POST",
        trigger: false
    });
    const [userData ] = useState<TFetchUsersData>({
        method: "GET",
        trigger: false
    });
    const {
        fetch: fetchExistingUser
    } = useFetchUsers(userData);
    const {
        code: ticketCode,
        body: ticketBody,
        loading
    } = useFetchTickets(ticketsData);
    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        setTxHash(event.target.value);
    }, []);
    const onSubmit: React.MouseEventHandler = useCallback(async (event) => {
        // check user address
        if (!game) return;
        if (!txHash) return;
        const tx = await metaMaskHook.checkTransaction(txHash);
        if (!tx) {
            addResponseNote({
                code: 400,
                message: translateNote("errors.invalidTransaction")
            }, notesDispatch);
            navigate(ticketRoutes.buyInError.urlBuilder({
                _gameId: game.id
            }));
        }
        if (appState.user) {
            if (tx?.from?.toUpperCase() !== appState.user.address.toUpperCase()) {
                addResponseNote({
                    code: 400,
                    message: translateNote("errors.walletExistsOnAccount")
                }, notesDispatch);
                return;
            }
            setTicketsData((prev) => ({
                ...prev,
                method: "POST",
                data: {
                    _gameId: game.id,
                    hash: txHash
                },
                trigger: true
            })); 
            return; 
        }
        if (!fetchExistingUser) return;
        const [, body] = await fetchExistingUser(
            endpoints.users.buildUrl({
                qs: `address=${tx?.from}`
            })
        ) as [Response, TUserResponse[]];
        if (body.length === 0) {
            setShowUserSignUp(true);
            setAccount(tx?.from as string);
        } else {
            setTicketsData((prev) => ({
                ...prev,
                method: "POST",
                data: {
                    _gameId: game.id,
                    hash: txHash
                },
                trigger: true
            })); 
        }
    }, [
        game, 
        metaMaskHook, 
        fetchExistingUser, 
        txHash, 
        notesDispatch, 
        appState.user, 
        navigate,
        translateNote
    ]);
    useEffect(() => {
        if (!ticketCode) return;
        setTicketsData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (ticketCode === 201) {
            navigate(ticketRoutes.buyInSuccess.urlBuilder({
                _gameId: (ticketBody as TTicketResponse)._gameId
            }), {
                replace: true
            });
            return;
        }
    }, [navigate, ticketCode, ticketBody]);
    return (
        <React.Fragment>
            {showUserSignUp ? (
                <SignUpForm 
                    address={account}
                    onSuccess={() => setTicketsData((prev) => ({
                        ...prev,
                        method: "POST",
                        data: {
                            _gameId: game.id,
                            hash: txHash
                        },
                    }))} />
            ) : (
                <Box
                    full
                    style={{
                        padding: 0,
                        alignItems: "center",
                        maxWidth: boxFixedWidth,
                        marginTop: "2rem"
                    }}
                    direction="column">
                    <Text
                        text={translateText("alreadyPaid")}
                        variant="special"
                        component="p"
                        style={{
                            textAlign: "center",
                            marginBottom: "1rem"
                        }}
                        size="xs" />
                    <Input
                        mode="input"
                        modeProps={{
                            placeholder: translateInput("transactionHash.placeholder")
                        }}
                        value={txHash}
                        onChange={onChange} />
                    <Button
                        caps
                        nature="accept"
                        size="sm"
                        onClick={onSubmit}
                        loading={loading}
                        label={translateButton("checkHash")} />
                </Box>
            )}
        </React.Fragment>
    );
};
