import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { endpoints } from "@common/endpoints";
import { TFetchData, useFetch } from "@common/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFetchMatches, TFetchMatchesData, TCompetitionsMatchBody, TCompetitionsMatchResponse, TCompetitionsMatchScoreBody } from "./useFetchMatches.types";

export function useFetchMatches({
    _matchId,
    method,
    data,
    trigger,
    successMessage,
    inform=false,
    options,
    ignoreHeaders,
    qs,
    ...props
}: TFetchMatchesData): IFetchMatches {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("texts", {keyPrefix: "successMessages"});
    const [hookData, setHookData] = useState<TFetchData<TCompetitionsMatchBody | TCompetitionsMatchScoreBody>>({
        endpointURI: _matchId
            ? endpoints.competitionsApiMatches.buildUrl({
                _matchId: _matchId
            })
            : endpoints.competitionsApiMatches.buildUrl({
                qs: qs
            }),
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage
            ? successMessage
            : translateNote("matchSuccessfully", {
                action: method === "POST" 
                    ? translateText("created")
                    : translateText("updated")
            }),
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TCompetitionsMatchBody | TCompetitionsMatchScoreBody, TCompetitionsMatchResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _matchId
                    ? endpoints.competitionsApiMatches.buildUrl({
                        _matchId: _matchId
                    })
                    : endpoints.competitionsApiMatches.buildUrl({
                        qs: qs
                    }),
                method: method,
                auth: method !== "GET",
                successMessage:  successMessage
                    ? successMessage
                    : translateNote("matchSuccessfully", {
                        action: method === "POST" 
                            ? translateText("created") 
                            : translateText("updated")
                    }),
                options: options,
                inform: inform,
                data: data,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _matchId,
        method,
        successMessage,
        options,
        inform,
        data,
        qs,
        translateNote,
        translateText
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};
