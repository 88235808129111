import styled from "styled-components";
import { TSearchBarDropdownProps } from "./SearchBar.dropdown.types";

export const StyledSearchBarDropdown = styled.ul<TSearchBarDropdownProps>`
    width: 100%;
    padding: 0.5rem 1rem;
    max-width: 16rem;
    background-color: ${props => props.theme.palette.backgroundContext};
    color: ${props => props.theme.mode === "light"
        ? props.theme.palette.itemDarkText
        : props.theme.palette.itemLightText
};
    border: 2px solid ${props => props.theme.palette.borderContext};
    border-radius: 0.5rem;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translate(0%, 1rem);
    list-style: none;
    & hr {
        border: 1px solid ${props => props.theme.palette.borderContext};
        width: auto;
        margin: 0.5rem -1rem;
    }
    &::before, &::after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        border-right: 0.6rem solid transparent;
        border-left: 0.6rem solid transparent;
        border-top: 1rem solid transparent;
    }
    &::before {
        top: 0;
        left: calc(20%);
        transform: translate(calc(-100% - 0.7rem), -100%);
        border-bottom: 1rem solid ${props => props.theme.palette.borderContext};
    }
    &::after {
        top: 4px;
        left: calc(20% - 0.7rem);
        transform: translate(calc(-100%), -100%);
        border-bottom: 1rem solid ${props => props.theme.palette.backgroundContext};
    }
    z-index: 2000;
`;


export const StyledSearchBarDropdownItem = styled.li`
    color: ${props => props.theme.mode === "light"
        ? props.theme.palette.itemDarkText
        : props.theme.palette.itemLightText
};
    background-color: transparent;
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0.5rem 0;
    cursor: pointer;
`;