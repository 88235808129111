import { TBoxProps } from "@common/components";
import { IRuleSlide } from "../HomeRuleSlide";

export type TAboutUsProps = TBoxProps;

export const aboutUsSegments: IRuleSlide[] = [{
    id: 1,
    name: "whoAreWe", // "WHO ARE WE?",
    terminal: "terminal.whoAreWe" 
}, {
    id: 2,
    name: "ourGoal", // "OUR GOAL?",
    terminal: "terminal.ourGoal"
}, {
    id: 3,
    name: "yourData", // "YOUR DATA?",
    terminal: "terminal.yourData"
}, {
    id: 4,
    name: "whyTrustUs", // "WHY TRUST US?",
    terminal: "terminal.whyTrustUs",
    caps: false
}, {
    id: 5,
    name: "keepInTouch", // "KEEP IN TOUCH",
    terminal: "terminal.keepInTouch",
    caps: false
}];