import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { Box, Text, ScoringStarSet, TScore } from "@common/components";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const ScoringRules: FunctionComponent<object | undefined> = ({
    ...props
}) => {
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const scores: {
        score: TScore,
        text: string
    }[] = useMemo(() => {
        return [{
            score: 0,
            text: translateText("scoringRules.0")
        }, {
            score: 1,
            text: translateText("scoringRules.1")
        }, {
            score: 3,
            text: translateText("scoringRules.3")
        }, {
            score: 4,
            text: translateText("scoringRules.4")
        }, {
            score: 6,
            text: translateText("scoringRules.6")
        }];
    }, [translateText]);
    return (
        <React.Fragment>
            {scores.map((starSet, index) => (
                <Box
                    key={index}
                    full
                    direction="row"
                    style={{
                        marginBottom: "1.5rem",
                        justifyContent: "space-between",
                        padding: 0
                    }}>
                    <ScoringStarSet
                        size="xs"
                        score={starSet.score} />
                    <Text
                        size="xs"
                        text={starSet.text} />
                </Box>
            ))}
        </React.Fragment>
    );
};
