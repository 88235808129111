import { TColorLabel } from "@theme";
import { TPattern, TPatternName, TSnakeCell, TSnakeDirection, TSnakeFrame, TSnakePosition } from "./Snake.types";
import { snakePatterns } from "./Snake.patterns";

export class Snake {
    cells: TSnakeCell[];
    size: number;
    length: number;
    pattern: TPattern;
    colors: TColorLabel[];
    labels: string[];
    direction: TSnakeDirection;
    constructor(
        kind: TPatternName = "circle", 
        size = 5,
        colors: TColorLabel[],
        length: number,
        direction: TSnakeDirection = "right"
    ) {
        this.size = size;
        this.length = length;
        this.direction = direction;
        this.pattern = snakePatterns[kind];
        this.colors = this._generateColors(
            colors,
            length
        );
        this.labels = this._getLabels();
        this.cells = this._prepareCells();
    };
    private _prepareCells(): TSnakeCell[] {
        const cells: TSnakeCell[] = [];
        for (
            let itemId = 1; 
            itemId < 16 + 1; 
            itemId++
        ) {
            const position = this.pattern.order[itemId - 1];
            const boardRow: number = Math.floor(
                position / 7 // board matrix is always 7x7
            );
            const rowIndex = position - 7 * Math.floor(
                position / 7
            );
            const origin: TSnakePosition = {
                left: rowIndex * this.size,
                top: boardRow * this.size
            };
            const colors = [
                ...this.colors.slice(itemId - 1),
                ...this.colors.slice(0, itemId - 1)
            ];
            const versions = this._generateVersionsFor(
                itemId,
                colors
            );
            const cell: TSnakeCell = {
                id: itemId,
                position: position,
                origin: origin,
                versions: versions
            };
            cells.push(cell);
        }
        return cells;
    };
    private _generateVersionsFor(id: number, colors: TColorLabel[]) {
        const versions: TSnakeFrame[] = [];
        if (colors.length !== this.labels.length) throw Error(
            ">>> 📛 error building snake loader keyframes."
        );
        let transparentAlready = false;
        for (let index = 0; index < colors.length; index++) {
            if (colors[index] === "transparent" && transparentAlready) continue;
            const versionLabels = [
                this.labels[index - 1 > -1
                    ? index - 1
                    : this.labels.length - 1],
                this.labels[index],
                this.labels[index + 1 < this.labels.length
                    ? index + 1
                    : 0]
            ];
            const version: TSnakeFrame = {
                color: colors[index],
                labels: versionLabels,
                keyframesString: this._generateString(
                    versionLabels
                )
            };
            versions.push(version);
            if (version.color === "transparent") transparentAlready = true;
        }
        return versions;
    };
    private _generateColors(
        colors: TColorLabel[],
        length: number
    ) {
        function _nextColor(current: number): number {
            if (current === colors.length - 1) {
                return 0;
            }
            return current + 1;
        }
        let currentColorIndex = -1;
        const orderedColors: TColorLabel[] = [];
        for (let color = 0; color < length; color++) {
            currentColorIndex = _nextColor(currentColorIndex);
            orderedColors.push(colors[currentColorIndex]);
        }
        const startingIndex = orderedColors.length;
        for (
            let index = startingIndex; 
            index < 17; // this has to do with kerframe labels... 
            index++
        ) {
            orderedColors.push("transparent");
        }
        return this.direction === "right"
            ? orderedColors.reverse()
            : orderedColors;
    };
    static start(
        kind: TPatternName,
        size: number,
        colors: TColorLabel[],
        length: number
    ): Snake {
        // create instance and return it;
        const snake = new Snake(
            kind,
            size,
            colors,
            length
        );
        return snake;
    };
    private _getLabels(): string[] {
        const labels: string[] = [];
        const step: number = 100 / 16;
        let label = 0;
        while (label <= 100) {
            labels.push(`${label}%`);
            label += step;
        }
        return this.direction ===  "right"
            ? labels.reverse()
            : labels;
    };
    private _generateString(labels: string[]): string {
        return `${labels.map((label, index) => {
            return `${label} {
                opacity: ${index === 1 ? 1 : 0}
            }`;
        }).join("\n")}`;
    };
};
