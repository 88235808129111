import { TUserResponse } from "@auth/types";
import { ActionMap } from "@common/types";
import { ThemeType } from "@theme";

export interface IAppState {
    theme: ThemeType["mode"];
    user?: TUserResponse;
    authenticated: boolean;
};

export enum EAppActionType {
    toggleTheme = "TOGGLE_THEME",
    authenticate = "AUTHENTICATE",
    unauthenticate = "UNAUTHENTICATE"
};

interface IAppActionPayload {
    [EAppActionType.toggleTheme]: undefined;
    [EAppActionType.authenticate]: TUserResponse;
    [EAppActionType.unauthenticate]: undefined;
};

export type TAppAction = ActionMap<
    IAppActionPayload
>[keyof IAppActionPayload];
