import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TGamesOverviewProps } from "./GamesOverview.types";
import { Box, HorizontalSlider, Text } from "@common/components";
import { TStringSliderObject, useEndlessSlider } from "@common/hooks";
import { GameStatusesEnum, SportsEnum } from "@common/enums";
import { TFetchGamesData, useFetchGames } from "@game/hooks";
import { TGameResponse } from "game/types/Game.types";
import { GameBox } from "../GameBox/GameBox";
import { NoResultsBox } from "@app/components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GamesOverview: FunctionComponent<TGamesOverviewProps> = ({
    style,
    ...props
}) => {
    const [gamesData, setGamesData] = useState<TFetchGamesData>({
        method: "GET",
        qs: `status=${GameStatusesEnum.OPEN}`,
        trigger: true
    });
    const {
        body: gamesBody,
        // loading: gamesLoading,
        code: gamesCode
    } = useFetchGames(gamesData);
    const [games, setGames] = useState<TGameResponse[]>();
    const sports: TStringSliderObject[] = useMemo(() => {
        return Object.entries(SportsEnum).map(([key, value]) => ({
            label: value.toUpperCase(),
            value: value
        })).filter(({label, value}) => [
            SportsEnum.SOCCER
        ].includes(value));
    }, []);
    const {
        current: sport,
        next: nextSport,
        prev: previousSport
    } = useEndlessSlider<TStringSliderObject>(sports);
    const gameStatuses: TStringSliderObject[] = useMemo(() => {
        return Object.entries(GameStatusesEnum).filter(
            ([key, value]) => (value === GameStatusesEnum.OPEN || value === GameStatusesEnum.PLAYING)
        ).map(([key, value]) => ({
            label: value.toUpperCase(),
            value: value
        }));
    }, []);
    const {
        current: gameStatus,
        next: nextGameStatus,
        prev: previousGameStatus
    } = useEndlessSlider<TStringSliderObject>(gameStatuses);
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const {t: translateSport} = useTranslation<TTranslationNameSpace>("sports");
    const {t: translateTitle} = useTranslation<TTranslationNameSpace>("titles");
    useEffect(() => {
        if (!gamesCode) return;
        setGamesData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (gamesCode === 200) {
            setGames(gamesBody as TGameResponse[]);
        }
    }, [gamesBody, gamesCode, gameStatus]);
    useEffect(() => {
        setGamesData((prev) => ({
            ...prev,
            trigger: true,
            qs: `status=${gameStatus?.value}`
        }));
    }, [gameStatus]);
    return (
        <Box
            full
            aria-label="games section"
            direction="column"
            style={{
                paddingRight: 0,
                paddingLeft: 0,
                alignItems: "center",
                ...style
            }} {...props}>
            <Text
                aria-label="section header: games"
                text={translateTitle("gamesOverview")}
                size="xl"
                component="h2"
                variant="title"
                style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "1.5rem"
                }} />
            {sport && (
                <HorizontalSlider
                    selectorName="sport"
                    selectorDescription="to filter games in games section."
                    current={translateSport(sport.label)}
                    onNext={nextSport}
                    onPrev={previousSport} />)}
            {gameStatus && (
                <HorizontalSlider
                    layer={2}
                    selectorName="game status"
                    selectorDescription="to filter games in games section."
                    current={translateText("gameStatus." + gameStatus.label)}
                    onNext={nextGameStatus}
                    onPrev={previousGameStatus} />)}
            <Box
                full
                direction="row"
                loose
                style={{
                    marginTop: "1rem",
                    overflowX: "scroll",
                    justifyContent: "start",
                    alignItems: "center",
                    paddingRight: games?.length || [].length > 1
                        ? 0
                        : "0.5rem",
                    paddingLeft: games?.length || [].length > 1
                        ? 0
                        : "0.5rem"
                }}
                stylesFrom={{
                    md: {
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "start",
                        maxWidth: "100rem",
                        overflowY: "auto",
                        maxHeight: "none",
                        overflowX: "scroll",
                        padding: "0.5rem"
                    }
                }}>
                {games && games.map((_game, index) => {
                    return (
                        <GameBox 
                            key={_game.id}
                            game={_game} 
                            style={{
                                margin: `3rem 1rem 1rem ${index === 0 ? 0 : "1rem"}`
                            }}
                            stylesFrom={{
                                md: {margin: "5rem 1rem 2rem 1rem"}
                            }}/>
                    );
                })}
                {games && games.length === 0 && (
                    <NoResultsBox
                        resource={`${gameStatus?.label} games`} />
                )}           
            </Box>
        </Box>
    );
};