import { TSize, breakpoints } from "@theme";
import { parseTo } from "@utils/parsers";
import styled from "styled-components";
import { TBoxProps } from "./Box.types";

export const StyledBox = styled.div<TBoxProps>`
    display: flex;
    width: ${props => props.full
        ? "100%"
        : "auto"
};
    padding: 1rem;
    margin: 0;
    flex-direction: ${props => props.direction
        ? props.direction
        : "row"
};
    flex-wrap: ${props => props.loose
        ? "nowrap"
        : "wrap"
};
    ${props => props.min && `min-width: ${props.min};`}
    ${props => props.max && `max-width: ${props.max};`}
    justify-content: ${props => props.direction
        ? props.direction === "column"
            ? "center"
            : "start"
        : "start"
};
    align-items: ${props => props.direction
        && props.direction === "column"
        ? "start"
        : "center"
};
    ${props => props.stylesFrom
        ? Object.entries(props.stylesFrom).map(
            ([breakpoint, styles]) => {
                return `@media screen and ${breakpoints[breakpoint as TSize]} {
                    ${Object.entries(styles).map(
        ([property, value]) => {
            return `${parseTo(property, "kebab")}: ${value} !important;`;
        }
    ).join("\n")}
                }`;
            }
        ) : ""
}
`;
