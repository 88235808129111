import { AboutUs, GetStarted, Rules } from "@app/components";
import { GamesOverview } from "@game/components";
import { Container, Loader } from "@common/components";
import { useDocumentMeta, useLocalStorage } from "@common/hooks";
import { TPageProps } from "@common/types";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useCheckUser } from "@auth/hooks";
import { TCheckUserHookData } from "@auth/hooks/useCheckUser/useCheckUser.types";
import { appRoutes } from "@app/routes";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const Landing: FunctionComponent<TPageProps> = (props) => {
    const location = useLocation();
    const {t: translateMeta} = useTranslation<
        TTranslationNameSpace,
        "metadata"
    >("titles", {keyPrefix: "metadata"});
    const [,] = useDocumentMeta({
        title: translateMeta("landing.title"),
        description: translateMeta("landing.description")
    });
    const [checkUserData, setCheckUserData] = useState<TCheckUserHookData>({
        trigger: false
    });
    const {getCurrent} = useLocalStorage<boolean>({
        key: "alreadyBeenHere",
        initialValue: false
    });
    const navigate = useNavigate();
    useCheckUser(checkUserData);
    useEffect(() => {
        if (!getCurrent()) navigate(appRoutes.welcome.url);
    }, [getCurrent, navigate]);
    useEffect(() => {
        if (location && location.state && location.state.refreshUser) {
            setCheckUserData((prev) => ({
                ...prev,
                trigger: true
            }));
        }
        if (location && location.hash) {
            document.querySelector(`${location.hash}`)?.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth"
            });
        }
    }, [location]);
    return (
        <React.Suspense fallback={(
            <Loader block />
        )}>
            <Container
                style={{
                    alignItems: "center"
                }}>
                {/* <Welcome /> */}
                <GetStarted />
                <GamesOverview id="games" />
                {/* <HowTo /> */}
                <Rules />
                <AboutUs />
            </Container>
        </React.Suspense>
    );
};
