import styled from "styled-components";
import { Box, Text } from "@common/components";
import { TTabProps, TTabbedBoxProps } from "./TabbedBox.types";
import { TTextProps } from "../Text/Text.types";
import { boxFixedWidth } from "@theme";


export const StyledTabbedBox = styled(Box).attrs<TTabbedBoxProps>({
    direction: "column",
    min: boxFixedWidth,
    max: boxFixedWidth,
    full: false,
    loose: true
})`
    overflow-anchor: none;
    margin-top: 5rem;
    padding: 1rem 1.5rem 2rem 1.5rem;
    position: relative;
    background-color: ${props => props.theme.palette.backgroundContext};
    border: 3px solid ${props => props.theme.palette.borderContext};
    box-shadow: 4px 4px 0px 0px #bbb inset, -4px -4px 0px 0px #666 inset;
`;

export const StyledTab = styled(Box).attrs({
    role: "tab"
})<TTabProps>`
    background-color: ${props => props.active
        ? props.theme.palette.tabBackgroundActive
        : props.disabled
            ? props.theme.palette.tabBackgroundDisabled
            : props.theme.palette.tabBackgroundRegular};
    margin: 0;
    padding: 0.6rem 0.6rem 0.5rem 0.6rem;
    border: 3px solid ${props => props.theme.palette.borderContext};
    border-bottom: none;
    z-index: ${props => props.active
        ? 1000
        : 0};
    box-shadow: ${props => props.active
        ? "4px 4px 0px 0px #bbb inset, -4px 0px 0px 0px #666 inset"
        : "4px 4px 0px 0px #666 inset, -4px 0px 0px 0px #666 inset"};
    position: relative;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 8px);
        ${props => props.active && `
            background-color: ${props.theme.palette.tabBackgroundActive};
            height: 6px;
            transform: translate(4px, calc(100% - 1px));
        `}
        ${props => !props.active && `
            background-color: ${props.theme.palette.borderContext};
            width: calc(100% + 4px);
            height: 3px;
            transform: translate(0, calc(100% - 3px));
        `}
    }
`;

export const StyledTabText = styled(Text).attrs<TTextProps>({})`
    color: ${props => props.variant === "active"
        ? props.theme.palette.tabTextActive
        : props.disabled
            ? props.theme.palette.tabTextDisabled
            : props.theme.palette.tabTextRegular};
`;

export const StyledTabsBox = styled(Box).attrs<TTabbedBoxProps>({
    role: "tablist",
    full: true,
    direction: "row",
    style: {
        justifyContent: "start",
        alignItems: "stretch"
    },
    loose: true,
})`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-2.5px, -98%);
`;
