import React, { FunctionComponent } from "react";
import { TPrizeDetailProps } from "./PrizeDetail.types";
import { Box, Text } from "@common/components";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const PrizeDetail: FunctionComponent<TPrizeDetailProps> = ({
    prize,
    game,
    ticket,
    ...props
}) => {
    const {t: translatePrizeDetail} = useTranslation<
        TTranslationNameSpace,
        "ticketDetail"
    >("gameBoxes", {keyPrefix: "ticketDetail"});
    const theme = useTheme();
    return (
        <Box
            full
            direction="column"
            style={{
                padding: "0.5rem 0 0 0",
                alignItems: "center",
                borderTop: `dotted 2px ${theme.palette.textSpecial}`,
                ...props.style
            }}>
            <Text
                text={game.edition.tournament.name}
                caps
                size="sm"
                component="h4" />
            <Text
                text={game.edition.name}
                caps
                size="xs"
                component="p" />
            <Text
                text={game.name}
                caps
                size="xs"
                component="p" />
            <Text
                text={`@${ticket?.player.handle
                    ? ticket.player.handle 
                    : translatePrizeDetail("noHandle")}`}
                size="xs"
                component="p" 
                style={{
                    textAlign: "right"
                }} />
            <Box
                full
                direction="column"
                style={{
                    padding: "0.5rem 0 0 0",
                    margin: "0.5rem 0",
                    borderTop: `dotted 2px ${theme.palette.textSpecial}`
                }}>
                <Text
                    caps
                    text={translatePrizeDetail("hash")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`hash: ${prize.receipt?.hash}`}
                    text={prize.receipt?.hash as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translatePrizeDetail("from")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`from: ${prize.receipt?.from}`}
                    text={prize.receipt?.from as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translatePrizeDetail("to")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`to: ${prize.receipt?.to}`}
                    text={prize.receipt?.to as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translatePrizeDetail("amount")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`amount: ${prize.receipt?.amount}`}
                    text={prize.receipt?.amount as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translatePrizeDetail("date")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`date: ${prize.receipt?.date}`}
                    text={(prize.receipt?.date || prize.receipt?.createdAt)
                        ? new Date(prize.receipt?.date || prize.receipt?.createdAt)
                            .toLocaleString(undefined, { dateStyle: "full" })
                        : ""}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
        </Box>
    );
};
