import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const OneUser = lazy(() => import("@user/pages").then(({
    OneUser
}) => ({
    default: OneUser
})));

export type TUserRoutes = "oneUser";

export const userRoutes: Record<TUserRoutes, ILayoutRoute> = {
    oneUser: {
        _id: 1,
        patterns: ["/:handle"],
        url: "/users",
        element: OneUser,
        protect: false,
        urlBuilder(params) {
            return `/users/${params?.handle}`;
        },
    }
};
