import React, { FunctionComponent, useMemo } from "react";
import { TAboutUsProps, aboutUsSegments } from "./AboutUs.types";
import { useEndlessSlider } from "@common/hooks";
import { HomeRuleSlide, IRuleSlide } from "../HomeRuleSlide";
import { Box, HorizontalSlider, Text } from "@common/components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const AboutUs: FunctionComponent<TAboutUsProps> = (props) => {
    const {t: translateTitle} = useTranslation<TTranslationNameSpace>("titles");
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const segments: IRuleSlide[] = useMemo(() => {
        return aboutUsSegments.map((segment) => segment);
    }, []);
    const {
        current,
        next,
        prev
    } = useEndlessSlider(segments);
    return (
        <Box
            full
            aria-label="about us section"
            direction="column"
            style={{
                alignItems: "center",
                padding: "1.5rem 0"
            }}>
            <Text 
                component="h2"
                variant="title"
                size="xl"
                text={translateTitle("aboutUs")}
                aria-label="about us header"
                style={{
                    textAlign: "center",
                    marginTop: "0",
                    marginBottom: "1.5rem"
                }} />
            <HorizontalSlider
                selectorName="about us subject"
                selectorDescription="to navigate through all about us sections."
                current={current 
                    ? translateText("aboutUs." + current.name)
                    : ""}
                onNext={next}
                onPrev={prev} />
            {current && <HomeRuleSlide
                item="aboutUs"
                slide={current} />}
        </Box>
    );
};