import { authenticate, unauthenticate, useAppDispatch } from "@app/context";
import { TFetchData, useFetch } from "@common/hooks/useFetch";
import { useEffect, useState } from "react";
import { IFetchLogins, TFetchLoginsData, TLoginsBody } from "./useFetchLogins.types";
import { TUserResponse } from "@auth/types";
import { endpoints } from "@common/endpoints";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchLogins({
    method,
    data,
    successMessage,
    options={},
    inform=true,
    auth=true,
    trigger=false
}: TFetchLoginsData): IFetchLogins {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const appDispatch = useAppDispatch();
    const [hookData, setHookData] = useState<TFetchData<TLoginsBody>>({
        endpointURI: endpoints.logins.url,
        method,
        data,
        successMessage: method === "DELETE" 
            ? translateNote("seeYou")
            : translateNote("welcomeBack"),
        options,
        inform, 
        auth,
        trigger
    });
    const {
        response,
        code,
        body,
        loading,
        error,
        fetch
    } = useFetch<TLoginsBody, TUserResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                method,
                data,
                successMessage: method === "DELETE" 
                    ? translateNote("seeYou")
                    : translateNote("welcomeBack"),
                options,
                inform,
                auth,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        method,
        data,
        successMessage,
        options,
        inform,
        auth,
        translateNote
    ]);
    useEffect(() => {
        if (!response && !error) return;
        if (response?.status === 201 && body) {
            authenticate(body as TUserResponse, appDispatch);
            return;
        }
        unauthenticate(appDispatch);
    }, [response, error, body, appDispatch]);
    return {
        response,
        body,
        code,
        loading,
        error,
        fetch
    };
}