export function getFromCookie(name: "csrf_token" | "access_token"): string | undefined {
    if (!document.cookie || document.cookie === "") return;
    const cookies: string[] = document.cookie.split(";");
    for (const cookie of cookies) {
        const _cookie = cookie.trim();
        if (_cookie.substring(0, name.length + 1) === name + "=") {
            return decodeURIComponent(
                _cookie.substring(name.length + 1)
            );
        }
    }
    return;
};
