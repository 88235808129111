import React, { FunctionComponent } from "react";
import { TTextProps } from "./Text.types";
import { useMemo } from "react";
import { textElements } from "./Text.styles";

export const Text: FunctionComponent<TTextProps> = ({
    text,
    size="xs",
    variant="regular",
    component="p",
    caps=false,
    ...props
}) => {
    const Component = useMemo(() => {
        return textElements[component].Element;
    }, [component]);
    return (
        <Component
            size={size}
            variant={component === "h1"
                ? "title"
                : variant}
            text={text}
            role={component === "h1"
                ? "heading"
                : component === "p"
                    ? "paragraph"
                    : undefined}
            {...props}>
            {caps
                ? text.toUpperCase()
                : text}
        </Component>
    );
};
