import { lazy } from "react";
const HelpCenter = lazy(() => import("@helpCenter/pages").then(({
    HelpCenter
}) => ({default: HelpCenter})));
const IntroTutorial = lazy(() => import("@helpCenter/pages").then(({
    IntroTutorial
}) => ({default: IntroTutorial})));
const GameTutorial = lazy(() => import("@helpCenter/pages").then(({
    GameTutorial
}) => ({default: GameTutorial})));
const CreateAccountHelp = lazy(() => import("@helpCenter/pages").then(({
    CreateAccountHelp
}) => ({default: CreateAccountHelp})));
const BuyInHelp = lazy(() => import("@helpCenter/pages").then(({
    BuyInHelp
}) => ({default: BuyInHelp})));
const ReportTransactionTutorial = lazy(() => import("@helpCenter/pages").then(({
    ReportTransactionTutorial
}) => ({default: ReportTransactionTutorial})));
const MetaMaskTutorial = lazy(() => import("@helpCenter/pages").then(({
    MetaMaskTutorial
}) => ({default: MetaMaskTutorial})));
const UpdatePicksTutorial = lazy(() => import("@helpCenter/pages").then(({
    UpdatePicksTutorial
}) => ({default: UpdatePicksTutorial})));
const OtherPicksTutorial = lazy(() => import("@helpCenter/pages").then(({
    OtherPicksTutorial
}) => ({default: OtherPicksTutorial})));
const GameStandingsTutorial = lazy(() => import("@helpCenter/pages").then(({
    GameStandingsTutorial
}) => ({default: GameStandingsTutorial})));
const NewToCryptoHelp = lazy(() => import("@helpCenter/pages").then(({
    NewToCryptoHelp
}) => ({default: NewToCryptoHelp})));
import { ILayoutRoute } from "@common/types";

export type THelpCenterRoutes = "helpCenter" 
    | "introTutorial"
    | "gameTutorial"
    | "createAccount"
    | "buyInHelp"
    | "metaMaskHelp"
    | "reportTxHelp"
    | "updatePicksTutorial"
    | "otherPlayersPicks"
    | "gameStandings"
    | "newToCryptoHelp";

export const helpCenterRoutes: Record<THelpCenterRoutes, ILayoutRoute> = {
    helpCenter: {
        _id: 1,
        patterns: [""],
        url: "/help-center",
        urlBuilder: () => "/help-center",
        element: HelpCenter
    },
    introTutorial: {
        _id: 2,
        patterns: ["/intro"],
        url: "/help-center/intro",
        urlBuilder: () => "/help-center/intro",
        element: IntroTutorial
    },
    gameTutorial: {
        _id: 3,
        patterns: ["/game-tutorial"],
        url: "/help-center/game-tutorial",
        urlBuilder: () => "/help-center/game-tutorial",
        element: GameTutorial
    },
    createAccount: {
        _id: 4,
        patterns: ["/create-account"],
        url: "/help-center/create-account",
        urlBuilder: () => "/help-center/create-account",
        element: CreateAccountHelp
    },
    buyInHelp: {
        _id: 5,
        patterns: ["/buying-in"],
        url: "/help-center/buying-in",
        urlBuilder: () => "/help-center/buying-in",
        element: BuyInHelp
    }, 
    metaMaskHelp: {
        _id: 6,
        patterns: ["/meta-mask"],
        url: "/help-center/meta-mask",
        urlBuilder: () => "/help-center/meta-mask",
        element: MetaMaskTutorial
    },
    reportTxHelp: {
        _id: 7,
        patterns: ["/report-transaction"],
        url: "/help-center/report-transaction",
        urlBuilder: () => "/help-center/report-transaction",
        element: ReportTransactionTutorial
    },
    updatePicksTutorial: {
        _id: 8,
        patterns: ["/update-picks"],
        url: "/help-center/update-picks",
        urlBuilder: () => "/help-center/update-picks",
        element: UpdatePicksTutorial
    },
    otherPlayersPicks: {
        _id: 9,
        patterns: ["/other-players-picks"],
        url: "/help-center/other-players-picks",
        urlBuilder: () => "/help-center/other-players-picks",
        element: OtherPicksTutorial
    },
    gameStandings: {
        _id: 10,
        patterns: ["/game-standings"],
        url: "/help-center/game-standings",
        urlBuilder: () => "/help-center/game-standings",
        element: GameStandingsTutorial
    },
    newToCryptoHelp: {
        _id: 11,
        patterns: ["/new-to-crypto"],
        url: "/help-center/new-to-crypto",
        urlBuilder: () => "/help-center/new-to-crypto",
        element: NewToCryptoHelp
    }
};