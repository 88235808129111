import React, { FunctionComponent, useMemo, useState } from "react";
import { TGameMatchesProps } from "./GameMatches.types";
import { Box } from "@common/components";
import { GameMatchBox } from "../GameMatchBox";
import { TGameMatchResponse } from "@game/types";
import { TGameMatchBoxProps, TSetShowPicksFor } from "../GameMatchBox";
import { GameMatchPicksTable } from "../GameMatchPicksTable";

export const GameMatches: FunctionComponent<TGameMatchesProps> = ({
    // fetch to competitions api get @/matches for game.matches[index]._matchId
    // its kid will fetch to get @/picks?_gameMatchId=
    game,
    competitionsMatches,
    ...props
}) => {
    const [showPicksFor, setShowPicksFor] = useState<TSetShowPicksFor<"gameMatch">>();
    const matches: Required<
        Pick<
            TGameMatchBoxProps, 
            "competitionsMatch" | "gameMatch"
    >>[] | undefined = useMemo(() => {
        if (!competitionsMatches) return;
        const _matches: typeof matches = competitionsMatches.map(
            (_competitionsMatch) => {
                return {
                    competitionsMatch: _competitionsMatch,
                    gameMatch: game.matches.find(_gm => _gm.match._matchId === _competitionsMatch._id) as TGameMatchResponse
                };
            }).filter(
            (_match) => showPicksFor
                ? _match.gameMatch._id === showPicksFor._gameMatchId
                : true
        );
        return _matches;
    }, [competitionsMatches, game, showPicksFor]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0
            }}>
            {matches?.map((match, index) => (
                <GameMatchBox
                    key={index}
                    gameMatch={match.gameMatch}
                    competitionsMatch={match.competitionsMatch}
                    showingPicks={showPicksFor?._gameMatchId === match.gameMatch._id}
                    setShowPicksFor={setShowPicksFor} />
            ))}
            {showPicksFor && <GameMatchPicksTable
                competitionsMatch={showPicksFor.competitionsMatch}
                _gameId={game._id}
                _gameMatchId={showPicksFor._gameMatchId} />}
        </Box>
    );
};
