import { IconHeader } from "@app/components";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { LogInForm, PasswordResetRequestForm } from "@auth/components";
import { Box, Container, Donkey, Text } from "@common/components";
import { useDocumentMeta, useTriStateSwitch } from "@common/hooks";
import { TPageProps } from "@common/types";
import { boxFixedWidth } from "@theme";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const LogIn: FunctionComponent<TPageProps> = (props) => {
    const {t: translateText} = useTranslation<
        TTranslationNameSpace, "passwordReset"
    >("texts", {keyPrefix: "passwordReset"});
    const {t: translateMeta} = useTranslation<
        TTranslationNameSpace,
        "metadata"
    >("titles", {keyPrefix: "metadata"});
    const [,] = useDocumentMeta({
        title: translateMeta("logIn.title"),
        description: translateMeta("logIn.description")
    });
    const {state: resetPassword, flip} = useTriStateSwitch();
    return (
        <Container
            style={{
                alignItems: "center"
            }}>
            <Box
                direction="column"
                max={boxFixedWidth}
                style={{
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <IconHeader
                    title="hee-haw!"
                    _Icon={<Donkey nature="no-background" />} />
                {!resetPassword && (
                    <React.Fragment>
                        <LogInForm />
                        <Text 
                            text={translateText("forgot")}
                            variant="special"
                            size="xs"
                            component="p"
                            onClick={flip}
                            asLink
                            style={{cursor: "pointer"}} />
                    </React.Fragment>
                )}
                {resetPassword && (
                    <PasswordResetRequestForm />
                )}    
            </Box>
        </Container>
    );
};
