import React, { FunctionComponent } from "react";
import { Text, Terminal } from "@common/components";
import { StyledHomeRuleSlide } from "./HomeRuleSlide.styles";
import { THomeRuleSlideProps } from "./HomeRuleSlide.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const HomeRuleSlide: FunctionComponent<THomeRuleSlideProps> = ({
    slide,
    item,
    ...props
}) => {
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    return (
        <StyledHomeRuleSlide
            full
            direction="column"
            style={{
                margin: "1rem 0 0 0",
                padding: "1rem 0.5rem"
            }}
            {...props}>
            {slide.subtitle && (
                <Text
                    aria-label={`rule subtitle: ${slide.subtitle}`}
                    caps
                    size="md"
                    // text={translateText("rules" + slide.subtitle)}
                    text={slide.subtitle}
                    variant="special" 
                    style={{
                        width: "100%",
                        marginBottom: "1rem",
                        textAlign: "center",
                        wordBreak: "break-word"
                    }} />
            )}
            {slide.terminal && (
                <Terminal
                    item={item || "slide"}
                    noScroll
                    content={translateText(item === "rule" 
                        ? "rules." + slide.terminal
                        : item === "aboutUs"
                            ? "aboutUs." + slide.terminal
                            : slide.terminal
                    )}
                    caps={slide.caps} />
            )}
            {slide.children}
        </StyledHomeRuleSlide>
    );
};
