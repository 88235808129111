import React, { ElementType, ReactElement } from "react";
import { Box, Loader } from "@common/components";
import { TWithLoaderProps } from "./WithLoader.types";

export function WithLoader<T extends ElementType>({
    loading,
    loaderSize,
    loaderLength,
    variant,
    speed,
    pattern,
    height,
    children,
    ...props    
}: TWithLoaderProps<T>): ReactElement {
    return(
        <React.Fragment>
            {loading ? (
                <Box 
                    direction="row" 
                    full 
                    style={{
                        justifyContent: "center",
                        position:"relative", 
                        padding: "0",
                        margin: "0", 
                        color: "transparent",
                        height: height || "auto"
                    }}>
                    {height === "auto" 
                        && children}
                    <Box
                        direction="column"
                        style={{
                            position: "absolute",
                            padding: 0,
                            width: "auto",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)"
                        }}>
                        <Loader 
                            loaderSize={loaderSize || "xs"} 
                            variant={variant || "standard"}
                            speed={speed || "regular"}
                            pattern={pattern || "circle"}
                            loaderLength={loaderLength || "regular"}
                            {...props} />
                    </Box>
                </Box>
                
            ) : (
                <React.Fragment>{children}</React.Fragment>
            )}
        </React.Fragment>
    );
};
