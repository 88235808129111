import React, { FunctionComponent, useMemo } from "react";
import { TTerminalProps } from "./Terminal.types";
import { StyledTerminal } from "./Terminal.styles";
import { Text } from "../Text";

export const Terminal: FunctionComponent<TTerminalProps> = ({
    content,
    item,
    itemId,
    caps = true,
    ...props
}) => {
    const text: string = useMemo(() => {
        if (caps) return content.toUpperCase();
        return content;
    }, [caps, content]);
    return (
        <StyledTerminal
            direction="row"
            aria-label={`${item}-terminal: ${itemId || Math.random() * 9999}`}
            {...props}
            full>
            <Text
                text={text}
                aria-label={`${item}-terminal-message: ${itemId || Math.random() * 9999}`}
                size="sm"
                variant="active"
                style={{
                    width: "100%",
                    wordBreak: "break-word",
                    textAlign: "left"
                }}
                component="p" />
        </StyledTerminal>
    );
};