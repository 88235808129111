import React, { ElementRef, FunctionComponent } from "react";
import { TSearchBarDropdownProps } from "./SearchBar.dropdown.types";
import { StyledSearchBarDropdown, StyledSearchBarDropdownItem } from "./SearchBar.dropdown.styles";

export const SearchBarDropdown: FunctionComponent<TSearchBarDropdownProps> = React.forwardRef<
    ElementRef<"ul">, TSearchBarDropdownProps
>(({
    items,
    flip,
    state,
    ...props
}, ref) => {
    return (
        <StyledSearchBarDropdown 
            ref={ref}
            role="list"
            items={items}
            flip={flip}
            {...props}>
            {items.map((item, index) => (
                <StyledSearchBarDropdownItem
                    key={index} 
                    role="listitem"
                    onClick={(event) => {
                        item.action && item.action(event);
                        flip && flip();
                    }}>
                    {item.label}
                </StyledSearchBarDropdownItem>
            ))}
        </StyledSearchBarDropdown>
    );
});

SearchBarDropdown.displayName = "SearchBarDropdown";
