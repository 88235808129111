import { RouteMapper } from "@common/components";
import { TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";
import { Routes } from "react-router";
import { TUserRoutes, userRoutes } from "./UserLayout.types";

export const UserLayout: FunctionComponent<TLayoutProps> = ({
    ...props
}) => {
    return (
        <Routes>
            {RouteMapper<TUserRoutes>({
                routes: userRoutes
            })}
        </Routes>
    );
};
