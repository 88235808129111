import React, { FunctionComponent, useMemo, useState } from "react";
import { TGameBoxProps } from "./GameBox.types";
import { Icon, TTab, TabbedBox, Thing } from "@common/components";
import { GamePanel } from "../GamePanel/GamePanel";
import { GameInfo } from "../GameInfo/GameInfo";
import { Donkey, Trophy } from "@common/components/Icon"; // Donkey,
import { GamePrizes } from "../GamePrizes";
import { GamePlayers } from "../GamePlayers";
import { useAppState } from "@app/context";
import { TUserResponse } from "@auth/types";
import { GamePicks } from "../GamePicks";
import { GameStatusesEnum } from "@common/enums";
import { GameStandings } from "../GameStandings";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GameBox: FunctionComponent<TGameBoxProps> = ({
    game,
    ...props
}) => {
    const {t: translateGameBox} = useTranslation<TTranslationNameSpace>("gameBoxes");
    const appState = useAppState();
    const [active, setActive] = useState<string>();
    const player: TUserResponse | undefined = useMemo(() => {
        if (
            !appState.user || 
            !appState.user.tickets?.find(
                _ticket => _ticket._gameId.toString() === game._id.toString()
            )) {
            setActive("info");
            return;
        };
        return appState.user;
    }, [appState.user, game]);
    const tabs: TTab[] = useMemo(() => {
        const _tabs: typeof tabs = [{
            id: "info",
            name: translateGameBox("tabs.info"),
            Component: (props) => (
                <GameInfo
                    player={player}
                    game={game}
                    refresh={() => ""}
                    setActive={setActive}
                    {...props} />
            ),
            Icon: (props) => (
                <Icon size="xs" nature="info" {...props}>
                    <Thing nature="info" />
                </Icon>
            ),
            mode: "data" 
        }];
        if (![
            GameStatusesEnum.CREATED,
            GameStatusesEnum.OPEN
        ].includes(game.status)) {
            _tabs.push({
                id: "standings",
                name: translateGameBox("tabs.standings"),
                Component: (props) => (
                    <GameStandings
                        game={game}
                        player={player}
                        {...props} />
                ),
                Icon: (props) => (
                    <Icon size="xs" nature="standings" {...props}>
                        <Thing nature="standings" />
                    </Icon>
                )
            });
        }
        _tabs.push({
            id: "prizes",
            name: translateGameBox("tabs.prizes"),
            Component: (props) => (
                <GamePrizes
                    game={game}
                    {...props} />
            ),
            Icon: (props) => (
                <Icon size="xs" nature="golden" {...props}>
                    <Trophy nature="golden" />
                </Icon>
            ),
            mode: "data"
        });
        _tabs.push({
            id: "players",
            name: translateGameBox("tabs.players"),
            Component: (props) => (
                <GamePlayers
                    game={game}
                    {...props} />
            ),
            Icon: (props) => (
                <Icon size="xs" nature="no-background" {...props}>
                    <Donkey nature="no-background" />
                </Icon>
            ),
            mode: "data"
        });
        if (player) _tabs.push({ // picks tab will only be deployed for auth players
            id: "picks",
            name: translateGameBox("tabs.picks"),
            Component: (props) => (
                <GamePicks
                    player={player}
                    game={game}
                    {...props} />
            ),
            Icon: (props) => (
                <Icon size="xs" nature="gold-star" {...props}>
                    <Thing nature="gold-star" />
                </Icon>
            ),
            mode: "data"
        });
        if (!player) _tabs.push({
            id: "matches",
            name: translateGameBox("tabs.matches"),
            Component: (props) => (
                <GamePicks
                    game={game}
                    {...props} />
            ),
            Icon: (props) => (
                <Icon size="xs" nature="gold-star" {...props}>
                    <Thing nature="gold-star" />
                </Icon>
            ),
            mode: "data" 
        });
        return _tabs;
    }, [game, player, translateGameBox]);
    const _CommonHeader = useMemo(() => {
        return <GamePanel game={game} />;
    }, [game]);
    return (
        <React.Fragment>
            {game && (
                <TabbedBox
                    aria-label={`game-box: ${game.id}`}
                    tablistName={`Game box tabs for game: ${game.name}`}
                    tabs={tabs}
                    CommonHeader={_CommonHeader} 
                    overrideActive={active}
                    {...props} />
            )}
        </React.Fragment>
    );
};
