import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const BuyIn = lazy(() => import("@ticket/pages").then(({
    BuyIn
}) => ({default: BuyIn})));
const BuyInSuccess = lazy(() => import("@ticket/pages").then(({
    BuyInSuccess
}) => ({default: BuyInSuccess})));

export type TTicketRoutes = "buyIn" | "buyInSuccess" | "buyInError";

export const ticketRoutes: Record<TTicketRoutes, ILayoutRoute> = {
    buyIn: {
        _id: 1,
        patterns: ["/buy-in/:_gameId"],
        url: "/tickets/buy-in/:_gameId",
        element: BuyIn,
        protect: false,
        urlBuilder: (params) => `/tickets/buy-in/${params?._gameId}`
    },
    buyInSuccess: {
        _id: 2,
        patterns: ["/buy-in/:_gameId/success"],
        url: "/tickets/buy-in/:_gameId/success",
        element: BuyInSuccess,
        protect: false,
        urlBuilder: (params) => `/tickets/buy-in/${params?._gameId}/success`
    },
    buyInError: {
        _id: 3,
        patterns: ["/buy-in/:_gameId/error"],
        url: "/tickets/buy-in/:_gameId/error",
        element: BuyInSuccess,
        protect: false,
        urlBuilder: (params) => `tickets/buy-in/${params?._gameId}/error`
    }
};
