export type TDevice = "mobile" 
    | "tablet" 
    | "pad" 
    | "laptop" 
    | "desktop"
    | "widescreen";
    
export type TSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"; 

export const devices: Record<TDevice, string> = {
    mobile: "576px",
    tablet: "768px",
    pad: "992px",
    laptop: "1200px",
    desktop: "1400px",
    widescreen: "1920px"
};

export const breakpoints: Record<TSize, string> = {
    xxs: `(min-width: 1px)`,
    xs: `(min-width: ${devices.mobile})`,
    sm: `(min-width: ${devices.tablet})`,
    md: `(min-width: ${devices.pad})`,
    xl: `(min-width: ${devices.laptop})`,
    lg: `(min-width: ${devices.desktop})`,
    xxl: `(min-width: ${devices.widescreen})`,
};

export const boxFixedWidth = "22rem";