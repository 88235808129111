export type TTranslationNameSpace = "titles" |
    "buttons" |
    "texts" |
    "options" |
    "notes" |
    "sports" | 
    "inputs" |
    "intro" |
    "gamesTutorial" |
    "createAccount" |
    "buyInTutorial" |
    "picksTutorial" |
    "otherPicksTutorial" |
    "newToCrypto" |
    "standingsTutorial" |
    "validations" |
    "gameBoxes" |
    "serverResponses";

export const translationNamespaces: TTranslationNameSpace[] = [
    "titles",
    "buttons",
    "texts", 
    "options",
    "notes",
    "sports",
    "inputs",
    "intro",
    "gamesTutorial",
    "createAccount",
    "buyInTutorial",
    "picksTutorial",
    "otherPicksTutorial",
    "newToCrypto",
    "standingsTutorial",
    "validations",
    "gameBoxes",
    "serverResponses"
];

export type TTranslationLanguages = "es" | "en";

export const supportedLanguages: TTranslationLanguages[] = ["en", "es"];

export type TTranslationResource = Record<
    TTranslationLanguages, 
    Record< 
        TTranslationNameSpace, 
        Record<
            string, 
            Record<
                string, 
                Record<
                    string, 
                    Record<
                        string, 
                        string
                    > | string
                > | string
            > | string
        >
    >
>;

import englishTitles from "./locales/en/titles.json";
import englishButtons from "./locales/en/buttons.json";
import englishNotes from "./locales/en/notes.json";
import englishTexts from "./locales/en/texts.json";
import englishOptions from "./locales/en/options.json";
import englishSports from "./locales/en/sports.json";
import englishInputs from "./locales/en/inputs.json";
import englishIntro from "./locales/en/intro.json";
import englishGamesTutorial from "./locales/en/gamesTutorial.json";
import englishCreateAccount from "./locales/en/createAccount.json";
import englishBuyInTutorial from "./locales/en/buyInTutorial.json";
import englishPicksTutorial from "./locales/en/picksTutorial.json";
import englishOtherPicksTutorial from "./locales/en/otherPicksTutorial.json";
import englishNewToCrypto from "./locales/en/newtoCrypto.json";
import englishStandingsTutorial from "./locales/en/standingsTutorial.json";
import englishGameBoxes from "./locales/en/gameBoxes.json";
import englishValidations from "./locales/en/validations.json";
import englishServerResponses from "./locales/en/serverResponses.json";

import spanishTitles from "./locales/es/titles.json";
import spanishButtons from "./locales/es/buttons.json";
import spanishNotes from "./locales/es/notes.json";
import spanishTexts from "./locales/es/texts.json";
import spanishOptions from "./locales/es/options.json";
import spanishSports from "./locales/es/sports.json";
import spanishInputs from "./locales/es/inputs.json";
import spanishIntro from "./locales/es/intro.json";
import spanishGamesTutorial from "./locales/es/gamesTutorial.json";
import spanishCreateAccount from "./locales/es/createAccount.json";
import spanishBuyInTutorial from "./locales/es/buyInTutorial.json";
import spanishPicksTutorial from "./locales/es/picksTutorial.json";
import spanishOtherPicksTutorial from "./locales/es/otherPicksTutorial.json";
import spanishNewToCrypto from "./locales/es/newtoCrypto.json";
import spanishStandingsTutorial from "./locales/es/standingsTutorial.json";
import spanishGameBoxes from "./locales/es/gameBoxes.json";
import spanishValidations from "./locales/es/validations.json";
import spanishServerResponses from "./locales/es/serverResponses.json";

export const translationResources: TTranslationResource = {
    en: {
        titles: englishTitles,
        texts: englishTexts,
        buttons: englishButtons,
        notes: englishNotes,
        options: englishOptions,
        sports: englishSports,
        inputs: englishInputs,
        intro: englishIntro,
        gamesTutorial: englishGamesTutorial,
        createAccount: englishCreateAccount,
        buyInTutorial: englishBuyInTutorial,
        picksTutorial: englishPicksTutorial,
        otherPicksTutorial: englishOtherPicksTutorial,
        newToCrypto: englishNewToCrypto,
        standingsTutorial: englishStandingsTutorial,
        gameBoxes: englishGameBoxes,
        validations: englishValidations,
        serverResponses: englishServerResponses
    },
    es: {
        titles: spanishTitles,
        texts: spanishTexts,
        buttons: spanishButtons,
        notes: spanishNotes,
        options: spanishOptions,
        sports: spanishSports,
        inputs: spanishInputs,
        intro: spanishIntro,
        gamesTutorial: spanishGamesTutorial,
        createAccount: spanishCreateAccount,
        buyInTutorial: spanishBuyInTutorial,
        picksTutorial: spanishPicksTutorial,
        otherPicksTutorial: spanishOtherPicksTutorial,
        newToCrypto: spanishNewToCrypto,
        standingsTutorial: spanishStandingsTutorial,
        gameBoxes: spanishGameBoxes,
        validations: spanishValidations,
        serverResponses: spanishServerResponses
    }
};