import React, { 
    lazy, Suspense 
} from "react";
import { AppContextProvider } from "@app/context";
const AppLayout = lazy(() => import("@app/routes").then(({
    AppLayout
}) => ({
    default: AppLayout
})));
import { Loader } from "@common/components";
import i18n from "@app/i18next/i18n";
import { createRoot, Root } from "react-dom/client";
import { I18nextProvider } from "react-i18next";

const container: HTMLDivElement | null = document.querySelector("#app");
if (!container) throw Error("no div with id app 😢");
const root: Root = createRoot(container);

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <AppContextProvider>
                <Suspense fallback={(
                    <Loader block />
                )}>
                    <AppLayout />
                </Suspense>
            </AppContextProvider>
        </I18nextProvider>
    </React.StrictMode>
);
