import { Box, HorizontalSlider, Text } from "@common/components";
import { ScoringRules, HomeRuleSlide, homeRules, IRuleSlide } from "@app/components";
import { useEndlessSlider } from "@common/hooks";
import React, { FunctionComponent, useMemo } from "react";
import { TRulesProps } from "./Rules.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const Rules: FunctionComponent<TRulesProps> = ({
    ...props
}) => {
    const {t: translateTitle} = useTranslation<TTranslationNameSpace>("titles");
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const rules: IRuleSlide[] = useMemo(() => {
        return homeRules.map((rule) => {
            if (rule.name === "SCORING") {
                rule.children = (
                    <React.Fragment>
                        <Text
                            size="md"
                            text={translateText("rules.possibleScores")}
                            caps
                            variant="special" 
                            style={{
                                margin: "1.5rem 0 1rem 0",
                                textAlign: "center",
                                width: "100%"
                            }}/>
                        <ScoringRules />
                    </React.Fragment>
                );
            }
            return rule;
        });
    }, [translateText]);
    const {
        current,
        next,
        prev
    } = useEndlessSlider(
        rules
    );
    return (
        <Box
            full
            aria-label="rules section"
            direction="column"
            style={{
                alignItems: "center",
                padding: "1.5rem 0"
            }}>
            <Text
                component="h2"
                variant="title"
                size="xl"
                text={translateTitle("rules")}
                aria-label="rules header"
                style={{
                    textAlign: "center",
                    marginTop: "0",
                    marginBottom: "1.5rem"
                }} />
            {current && (
                <React.Fragment>
                    <HorizontalSlider
                        selectorName="rules subject"
                        selectorDescription="to navigate through all type of rules."
                        current={translateText("rules." + current.name || "")}
                        onNext={next}
                        onPrev={prev} />
                    <HomeRuleSlide
                        item="rule"
                        slide={current} />
                </React.Fragment> )}
        </Box>
    );
};
