import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import ChainBackend from "i18next-chained-backend";
// import LocalStorageBackend from "i18next-localstorage-backend";
// import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { supportedLanguages, translationNamespaces, translationResources } from "./i18n.types";


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: translationResources,
        // debug: true,
        ns: translationNamespaces,
        supportedLngs: supportedLanguages,
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
            convertDetectedLanguage: (lng: string) => {
                return lng.split("-")[0];
            }
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;