import styled from "styled-components";
import { TResponseNoteProps } from "./ResponseNote.types";

export const StyledResponseNote = styled("div").attrs<Partial<TResponseNoteProps>>({
    role: "log",
})`
    display: flex;
    flex-flow: column nowrap;
    max-width: 344px;
    height: max-content;
    width: 100%;
    padding: 0.5rem;
    background-color: ${props => props.theme.palette.backgroundContext};
    border: 3px solid ${props => props.theme.palette.borderContext};
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    position: sticky;
    pointer-events: auto;
`;
