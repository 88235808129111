import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ISignUpFormInputs, TSignUpFormProps } from "./SignUpForm.types";
import { Box, Form, IField, IFormButton, Text } from "@common/components";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TFetchUsersData } from "@auth/hooks/useFetchUsers/useFetchUsers.types";
import { useFetchUsers } from "@auth/hooks";
import { TUserBody } from "@auth/types";
import { appRoutes } from "@app/routes";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const SignUpForm: FunctionComponent<TSignUpFormProps> = ({
    address,
    onSuccess,
    ...props
}) => {
    const {t: translateValidation} = useTranslation<TTranslationNameSpace>("validations");
    const {t: translateInput} = useTranslation<TTranslationNameSpace>("inputs");
    const {t: translateText} = useTranslation<TTranslationNameSpace>("texts");
    const {t: translateButton} = useTranslation<TTranslationNameSpace>("buttons");
    const navigate = useNavigate();
    const [userData, setUserData] = useState<TFetchUsersData>({
        method: "POST",
        trigger: false,
        bypassAuth: true
    });
    const {
        code: userCode,
        error: userError,
        loading
    } = useFetchUsers(userData);
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<ISignUpFormInputs>>();
    const fields: IField<ISignUpFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            mode: "input",
            name: "handle",
            validations: {
                required: translateValidation("required.handle"),
                validate: (value: string) => value.length > 1 
                    ? true 
                    : translateValidation("validate.handleLength")
            },
            defaultValue: "",
            modeProps: {
                placeholder: translateInput("handle.placeholder")
            }
        }, {
            row: 2,
            mode: "input",
            name: "address",
            validations: {
                required: translateValidation("required.address"),
                validate: (value: string) => value.length === 42
                    ? true
                    : translateValidation("validate.addressLength")
            },
            defaultValue: address ? address : "",
            modeProps: {
                autoComplete: "none",
                hidden: address ? true : false,
                placeholder: translateInput("wallet.placeholder")
            }
        }, {
            row: 3,
            mode: "input",
            name: "password",
            validations: {
                required: translateValidation("required.password"),
                validate: (value: string) => value.length >= 8 
                    ? true 
                    : translateValidation("validate.passwordLength") 
            },
            defaultValue: "",
            modeProps: {
                type: "password",
                autoComplete: "new-password",
                placeholder: translateInput("password.placeholder")
            }
        }, {
            row: 4,
            mode: "input",
            name: "confirm",
            validations: {
                required: translateValidation("required.confirmPassword"),
                validate: (value: string) => currentValues?.password === value 
                    ? true 
                    : translateValidation("validate.passwordMatch")
            },
            defaultValue: "",
            modeProps: {
                type: "password",
                autoComplete: "new-password",
                placeholder: translateInput("confirmPassword.placeholder")
            }
        }, {
            row: 5,
            mode: "input",
            name: "email",
            defaultValue: "",
            modeProps: {
                type: "email",
                placeholder: translateInput("emailOptional.placeholder")
            }
        }];
        return _fields;
    }, [currentValues, address, translateInput, translateValidation]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: address
                ? translateButton("next")
                : translateButton("signUp"),
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "regular",
                variant: "white",
                loaderLength: "regular"
            },
            nature: address
                ? "action"
                : "accept"
        }];
        return _buttons;
    }, [loading, address, translateButton]);
    const onSubmit: SubmitHandler<ISignUpFormInputs> = useCallback(
        async(payload) => {
            delete payload.confirm;
            const _userBody: TUserBody = {
                address: address ? address : payload.address,
                handle: payload.handle,
                password: payload.password,
                email: payload.email || undefined
            };
            setUserData((prev) => ({
                ...prev,
                data: _userBody,
                trigger: true
            }));
        }, [address]);
    useEffect(() => {
        if (!userCode && !userError) return;
        setUserData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (userCode === 201) {
            if (onSuccess) {
                if (!currentValues) return;
                onSuccess({
                    handle: currentValues.handle as string,
                    password: currentValues.password as string
                });
                return;
            }
            navigate(appRoutes.home.url);
        }
    }, [navigate, userCode, onSuccess, currentValues, userError]);
    return (
        <Box
            full
            style={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0
            }}
            direction="column">
            {address && (
                <Box
                    full
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "1rem"
                    }}>
                    <Text
                        size="md"
                        text={translateText("signUp.almostDone.0")}
                        variant="regular"
                        caps
                        style={{textAlign: "center"}} />
                    <Text
                        caps
                        size="xs"
                        variant="regular"
                        style={{margin: "0.5rem 0 1rem 0"}}
                        text={translateText("signUp.almostDone.1")} />
                </Box>
            )}
            <Form
                aria-label="sign-up-form"
                name={`sign up form`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}>
                <Text
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        textAlign: "center",
                        marginTop: "1rem",
                        padding: "0 1rem"
                    }}
                    text={translateText("signUp.emailTerms")} />
            </Form>
        </Box>
    );
};
