import { Avatar, Box, Text } from "@common/components";
import React, { FunctionComponent } from "react";
import { TPlayerPickProps } from "./PlayerPick.types";

export const PlayerPick: FunctionComponent<TPlayerPickProps> = ({
    home,
    away,
    pick,
    style,
    size,
    textSize,
    ...props
}) => {
    return (
        <Box
            full
            loose
            direction="row"
            style={{
                padding: 0,
                ...style
            }}
            {...props}>
            {home && away && pick && (
                <React.Fragment>
                    <Box
                        direction="column"
                        full
                        style={{
                            padding: 0,
                            alignItems: "center"
                        }}>
                        <Avatar
                            squared
                            size={size || "xs"}
                            item="team"
                            avatar={{
                                avatar: home.avatar 
                            }} />
                        <Text
                            text={pick.homeGoals?.toString() || "-"}
                            size={textSize || "xxs"} />
                    </Box>
                    <Box
                        direction="column"
                        full
                        style={{
                            padding: 0,
                            alignItems: "center"
                        }}>
                        <Avatar
                            squared
                            size={size || "xs"}
                            item="team"
                            avatar={{
                                avatar: away.avatar 
                            }} />
                        <Text
                            text={pick.awayGoals?.toString() || "-"}
                            size={textSize || "xxs"} />
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};
