import React, { FunctionComponent } from "react";
import { TNoResultBoxProps } from "./NoResultsBox.types";
import { Box, Donkey, Icon, ItemBox, Terminal } from "@common/components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { parseTo } from "@utils/parsers";

export const NoResultsBox: FunctionComponent<TNoResultBoxProps> = ({
    resource="results",
    ...props
}) => {
    const {t: translateText} = useTranslation<TTranslationNameSpace, "noResults">(
        "texts", {
            keyPrefix: "noResults"
        });
    return (
        <ItemBox
            aria-label={`no ${resource} box`}
            direction="column"
            {...props}>
            <Box
                full
                style={{
                    justifyContent: "center"
                }}>
                <Icon
                    size="huge"
                    nature="shocked">
                    <Donkey nature="shocked" />
                </Icon>
            </Box>
            <Box
                full
                style={{
                    alignItems: "center"
                }}>
                <Terminal
                    item="placeholder"
                    content={translateText(`${parseTo(resource, "camel", "regular")}`)}
                    noScroll
                    caps />
            </Box>
        </ItemBox>
    );
};