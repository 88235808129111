import React, { FunctionComponent, useMemo } from "react";
import { TTicketDescriptionProps } from "./TicketDescription.types";
import { Box, Text } from "@common/components";
import { TGameDetail } from "@ticket/pages/BuyIn/BuyIn.types";
import { boxFixedWidth } from "@theme";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const TicketDescription: FunctionComponent<TTicketDescriptionProps> = ({
    game,
    ...props
}) => {
    const {t: translateText} = useTranslation<
        TTranslationNameSpace,
        "ticketDescription"
    >("texts", {keyPrefix: "ticketDescription"});
    const gameDetail: TGameDetail = useMemo(() => {
        if (!game) return [];
        const _detail: typeof gameDetail = [
            ["game", "game-name", `${game.edition.tournament.name} ${game.edition.name} ${game.name}`],
            ["entryFee", "game-entry-fee", `ETH ${game.entryFee.toFixed(2)}`],
            ["gameWallet", "game-wallet", game.address?.toString() || ""]
        ];
        return _detail;
    }, [game]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0,
                alignItems: "center"
            }}>
            {gameDetail.map(([label, ariaLabel, value], index) => {
                return (
                    <Box
                        full
                        direction="column"
                        style={{
                            maxWidth: boxFixedWidth,
                            padding: "1rem 0.5rem 0.5rem 0.5rem"
                        }}
                        key={index}>
                        <Text
                            caps
                            component="p"
                            text={translateText(label)}
                            variant="regular"
                            size="sm"
                            style={{fontWeight: "bold"}} />
                        <Text
                            aria-label={`${ariaLabel}: ${value}`}
                            caps={ariaLabel !== "game-wallet"}
                            component="p"
                            text={value}
                            variant="regular"
                            size="xs" />
                    </Box>
                );
            })}
        </Box>
    );
};
