import React, { FunctionComponent } from "react";
import { TTicketDetailProps } from "./TicketDetail.types";
import { Box, Text } from "@common/components";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const TicketDetail: FunctionComponent<TTicketDetailProps> = ({
    ticket,
    game,
    ...props
}) => {
    const {t: translateTicketDetail} = useTranslation<
        TTranslationNameSpace,
        "ticketDetail"
    >("gameBoxes", {keyPrefix: "ticketDetail"});
    const theme = useTheme();
    return (
        <Box
            full
            direction="column"
            style={{
                padding: "0.5rem 0 0 0",
                alignItems: "center",
                borderTop: `dotted 2px ${theme.palette.textSpecial}`
            }}>
            <Text
                text={game.edition.tournament.name}
                caps
                size="sm"
                component="h4" />
            <Text
                text={game.edition.name}
                caps
                size="xs"
                component="p" />
            <Text
                text={game.name}
                caps
                size="xs"
                component="p" />
            <Text
                text={`@${ticket.player.handle}`}
                size="xs"
                component="p" 
                style={{
                    textAlign: "right"
                }} />
            <Box
                full
                direction="column"
                style={{
                    padding: "0.5rem 0 0 0",
                    margin: "0.5rem 0",
                    borderTop: `dotted 2px ${theme.palette.textSpecial}`
                }}>
                <Text
                    caps
                    text={translateTicketDetail("hash")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`hash: ${ticket.receipt?.hash}`}
                    text={ticket.receipt?.hash as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translateTicketDetail("from")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`from: ${ticket.receipt?.from}`}
                    text={ticket.receipt?.from as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translateTicketDetail("to")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`to: ${ticket.receipt?.to}`}
                    text={ticket.receipt?.to as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translateTicketDetail("amount")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`amount: ${ticket.receipt?.amount}`}
                    text={ticket.receipt?.amount as string}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
            <Box
                full
                direction="column"
                style={{
                    padding: 0,
                    margin: "0.5rem 0"
                }}>
                <Text
                    caps
                    text={translateTicketDetail("date")}
                    component="p"
                    size="xs"
                    variant="special" />
                <Text
                    aria-label={`date: ${ticket.receipt?.date}`}
                    text={new Date(ticket.receipt?.date || ticket.receipt?.createdAt || "")
                        .toLocaleString(undefined, { dateStyle: "full" })}
                    component="p"
                    size="xs"
                    variant="regular"
                    style={{
                        marginTop: "0.2rem",
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap"
                    }} />
            </Box>
        </Box>
    );
};
