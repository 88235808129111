import React, { useCallback, useEffect, useState } from "react";
import { TSearchBarProps } from "./SearchBar.types";
import { Box } from "../Box";
import { Input } from "../Input";
import { Button } from "../Button";
import { useTriStateSwitch } from "@common/hooks";
import { SearchBarDropdown } from "./SearchBar.dropdown";
import { TSearchBarDropdownItem } from "./SearchBar.dropdown.types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function SearchBar<ItemType extends object>({
    mode,
    items,
    onFilterChange,
    style,
    filterBy,
    disabled,
    searchBy,
    onSearch,
    onClearSearch,
    ...props
}: TSearchBarProps<ItemType>): React.ReactComponentElement<"div"> {
    const {t: translateSearchBar} = useTranslation<
        TTranslationNameSpace,
        "searchBar"
    >("inputs", {keyPrefix: "searchBar"});
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "searchBar"
    >("buttons", {keyPrefix: "searchBar"});
    const [state, setState] = useState<string>("");
    const [searchOn, setSearchOn] = useState<boolean>();
    const [filterTags, setFilterTags] = useState<ItemType[]>([]);
    const {state: listIsOpen, flip} = useTriStateSwitch();
    const [dropDownItems, setDropDownItems] = useState<TSearchBarDropdownItem[]>();
    const filterItems = useCallback((currentInput: string) => {
        if (!filterBy) return;
        const _items: TSearchBarDropdownItem[] | undefined = items?.filter(
            (item) => 
                (item[filterBy] as string).includes(currentInput) &&
                !filterTags?.find((tag) => tag[filterBy] === item[filterBy]) // leave out items that are already used as filterTags
        ).map(item => ({
            label: item[filterBy] as string,
            action: (event) => {
                setState("");
                setFilterTags((prev) => ([
                    ...prev,
                    item
                ]));
            }
        }));
        if (listIsOpen && _items?.length === 0) flip();
        if (!listIsOpen && _items && _items.length > 0) flip();
        setDropDownItems(_items);
        setState(currentInput);
    }, [items, filterBy, filterTags, flip, listIsOpen]);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        // check new size of input value
        // if less than 2, just update the input value
        if (event.currentTarget.value.length < 2) {
            if (listIsOpen) flip();
            setState(event.currentTarget.value);
            if (mode === "filter") setDropDownItems(undefined);
            return;
        }
        // if search mode just update the input value
        if (mode === "search") return setState(event.currentTarget.value);
        // if 2 or more characters, then check search bar mode
        // if filter mode, use items and input value to filter items by key
        // and feed this to the dropdown list state
        filterItems(event.currentTarget.value);
        return;
    }, [mode, filterItems, flip, listIsOpen]);
    const dropdownRef = React.createRef<HTMLUListElement>();
    const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            if (mode === "filter") return setFilterTags([]);
            // if disabled && "search", state has value and input
            // is disabled, this button should clean state
            // and use callback to clearSearch
            if (searchOn) {
                onClearSearch && onClearSearch();
                setState("");
                setSearchOn(false);
            } else {
                // otherwise, use callback to search
                onSearch && onSearch(state);
                setSearchOn(true);
            }
        }, [mode, onClearSearch, onSearch, searchOn, state]);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current?.contains(event.target as Node)) return;
            if (listIsOpen) flip && flip();
        };
        if (state) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [state, flip, dropdownRef, listIsOpen]);
    useEffect(() => {
        if (filterTags.length > 0) {
            onFilterChange && onFilterChange(filterTags);
        } else {
            onFilterChange && onFilterChange(undefined);
        }
    }, [filterTags, onFilterChange]);
    return (
        <Box
            full
            loose
            direction="column"
            style={{
                padding: "1rem 0 0 0",
                ...style
            }}
            {...props}>
            <Box
                full
                loose
                style={{
                    padding: 0
                }}>
                <Box
                    direction="column"
                    style={{
                        width: "auto",
                        padding: 0,
                        position: "relative"
                    }}>
                    <Input
                        mode="input"
                        fontSize="xs" // make dynamic?
                        name={mode === "filter" ? "filter bar" : "search bar"}
                        modeProps={{
                            role: "searchbox",
                            placeholder: mode === "filter" 
                                ? translateSearchBar("placeholder.filter") 
                                : translateSearchBar("placeholder.search", {
                                    searchBy: searchBy as string
                                }),
                            disabled: mode === "filter"
                                ? disabled
                                : searchOn
                        }}
                        value={state}
                        onChange={handleChange} />
                    {listIsOpen && <SearchBarDropdown
                        items={dropDownItems || []}
                        flip={flip}
                        ref={dropdownRef}
                        state={listIsOpen}
                    />}
                </Box>
                <Button
                    label={mode === "filter" 
                        ? translateButton("clear") 
                        : searchOn
                            ? translateButton("clear")  
                            : translateButton("search")}
                    caps
                    onClick={handleButtonClick}
                    nature={mode === "filter" 
                        ? "decline" 
                        : searchOn
                            ? "decline"
                            : "action"}
                    size="xs" // make dynamic?
                    style={{
                        margin: "0 0 0 1rem",
                        padding: "0.8rem 1rem"
                    }}
                    disabled={mode === "filter"
                        ? false
                        : state.length < 2}
                    // loading
                />
            </Box>
            {filterTags?.length > 0 && (
                <Box
                    full
                    style={{
                        marginTop: "0.5rem",
                        // backgroundColor: theme.palette.backgroundNav,
                        padding: "0 0.5rem",
                        justifyContent: "start"
                    }}>
                    {filterBy && filterTags.map((tag, index) => (
                        <Button
                            key={index} 
                            size="xs" 
                            nature="accept"
                            aria-label="item-tag"
                            onClick={(event) => setFilterTags((prev) => prev.filter(
                                (_tag, _index) => _index !== index
                            ))}
                            style={{
                                margin: "0.3rem 0.2rem"
                            }}>
                            {tag[filterBy] as string | number}
                        </Button>
                    ))}
                </Box>
            )}
            {props.children}
        </Box>
    );
};
