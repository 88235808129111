import React, { FunctionComponent } from "react";
import { Box, Text, Icon, Arrow } from "@common/components";
import { THorizontalSliderProps } from "./HorizontalSlider.types";

export const HorizontalSlider: FunctionComponent<THorizontalSliderProps> = ({
    current,
    onNext,
    onPrev,
    layer=1,
    selectorName="anon",
    selectorDescription="",
    ...props
}) => {
    return (
        <Box
            direction="row"
            loose
            style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem 0.5rem",
                textAlign: "center"
            }}
        >
            <Icon 
                aria-label="previous item"
                tabIndex={0}
                nature={layer === 1 ? "top-left" : "sub-left"}
                role="button"
                size={layer === 1 ? "md" : "sm"}
                onClick={onPrev}>
                <Arrow nature={layer === 1 ? "top-left" : "sub-left"} />
            </Icon>
            <Box
                role="listbox"
                aria-label={`${selectorName} selector`}
                aria-roledescription={selectorDescription}
                style={{
                    padding: 0
                }}>
                <Text
                    caps
                    role="option"
                    aria-selected="true"
                    variant={layer === 1
                        ? "title"
                        : "special"}
                    size={layer === 1
                        ? "lg"
                        : "md"}
                    text={current} />
            </Box>
            <Icon 
                aria-label="next item"      
                tabIndex={0}
                nature={layer === 1 ? "top-right" : "sub-right"}
                role="button"
                size={layer === 1 ? "md" : "sm"}
                onClick={onNext}>
                <Arrow nature={layer === 1 ? "top-right" : "sub-right"} />
            </Icon>
        </Box>
    );
};
