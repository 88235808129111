import styled from "styled-components";
import { TTerminalProps } from "./Terminal.types";
import { Box } from "../Box";

export const StyledTerminal = styled(Box)<
    Partial<TTerminalProps>
>`
    color: ${props => props.theme.palette.textRegular};
    background-color: ${props => props.theme.palette.terminalBg};
    width: 100%;
    height: auto;
    max-height: ${props => !props.noScroll
        ? "9rem"
        : "auto"};
    overflow-y: auto;
    align-items: flex-start;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    font-family: ${props => props.theme.fonts.main};
    border: 2px solid ${props => props.theme.palette.borderTheme};
    box-shadow: 5px 5px 0px 0px #22222299 inset, -5px -5px 0px 0px #22222266 inset;
    white-space: pre-wrap;
`;