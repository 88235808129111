export type TEndpointName = "users" 
    | "whoAmI"
    | "logins"
    | "games"
    | "tickets"
    | "competitionsApiMatches"
    | "picks"
    | "userAvatars" 
    | "gameMatches"
    | "validations"
    | "passwordResetRequests";
    // | "federations"
    // | "countries"
    // | "federationAvatars"
    // | "teams"
    // | "teamAvatars"
    // | "competitions"
    // | "competitionAvatars"
    // | "editions"
    // | "matches";

export const endpointsURI: Record<TEndpointName, string> = {
    users: "users",
    whoAmI: "who-am-i",
    logins: "logins",
    games: "games",
    tickets: "tickets",
    competitionsApiMatches: "matches",
    picks: "picks",
    userAvatars: "user-avatars",
    gameMatches: "game-matches",
    validations: "validations",
    passwordResetRequests: "password-reset-requests"
    // federations: "federations",
    // countries: "countries",
    // federationAvatars: "federation-avatars",
    // teams: "teams",
    // teamAvatars: "team-avatars",
    // competitions: "competitions",
    // competitionAvatars: "competition-avatars",
    // editions: "editions",
    // matches: "matches"
};
