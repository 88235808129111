import { authenticate, unauthenticate, useAppDispatch } from "@app/context";
import { useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { useEffect, useState } from "react";
import { ICheckUser, TCheckUserData, TCheckUserHookData } from "./useCheckUser.types";
import { TUserResponse } from "@auth/types";
import { getFromCookie } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useCheckUser({
    trigger
}: TCheckUserHookData): ICheckUser {
    const appDispatch = useAppDispatch();
    const [complete, setComplete] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const [checkUserData, setCheckUserData] = useState<TCheckUserData>({
        endpointURI: endpoints.whoAmI.url,
        method: "GET",
        successMessage: translateNote("welcomeBack"),
        inform: false,
        trigger: false,
        auth: true
    });
    const {
        response,
        body,
        code,
        fetch,
        loading,
        error
    } = useFetch<TCheckUserData, TUserResponse>(checkUserData);
    useEffect(() => {
        if (!trigger) return;
        if (!getFromCookie("csrf_token")) {
            setComplete(true);
            unauthenticate(appDispatch);
            return;
        }
        setCheckUserData((prev) => ({
            ...prev,
            trigger: true
        }));
    }, [trigger, appDispatch]);
    useEffect(() => {
        if(!response && !error) return;
        setComplete(true);
        if (response?.status === 200 && body) {
            authenticate(
                body as TUserResponse, 
                appDispatch
            );
            return;
        }
        unauthenticate(appDispatch);
    }, [response, body, error, appDispatch]);
    return {
        response,
        body,
        code,
        fetch,
        loading,
        error,
        complete
    };
};
