import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { TGamePlayersProps } from "./GamePlayers.types";
import { Box, Button, ITableHeader, ITableRow, Loader, Paginated, SearchBar, Table, Text } from "@common/components";
import { TTicketResponse } from "@ticket/types";
import { TFetchTicketsData } from "@ticket/hooks/useFetchTickets/useFetchTickets.types";
import { useFetchTickets } from "@ticket/hooks/useFetchTickets";
import { TicketDetail } from "@ticket/components";
import { usePagination } from "@common/hooks";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GamePlayers: FunctionComponent<TGamePlayersProps> = ({
    game,
    ...props
}) => {
    const {t: translateGamePlayer} = useTranslation<
        TTranslationNameSpace,
        "gamePlayers"
    >("gameBoxes", {keyPrefix: "gamePlayers"});
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "gameBox"
    >("buttons", {keyPrefix: "gameBox"});
    const [ticketsData, setTicketsData] = useState<TFetchTicketsData>({
        method: "GET",
        qs: `_gameId=${game.id}`,
        trigger: true
    });
    const {
        code: ticketsCode,
        loading: ticketsLoading,
        paginatedResponse
    } = useFetchTickets(ticketsData);
    const {
        page,
        perPage,
        total,
        items: paginatedTickets,
        previousPage,
        nextPage
    } = usePagination<TTicketResponse>({
        items: paginatedResponse?.items as TTicketResponse[],
        total: paginatedResponse?.total,
        dynamic: true
    });
    const [showTicket, setShowTicket] = useState<TTicketResponse>();
    const ticketRows: ITableRow[] | undefined = useMemo(() => {
        // if (!tickets) return;
        if (!paginatedTickets || paginatedTickets.length === 0) return;
        // const _tickets: typeof tickets = tickets.filter(_t => showTicket 
        const _tickets: typeof paginatedTickets = paginatedTickets.filter(_t => showTicket 
            ? _t.id === showTicket.id
            : _t
        );
        const _ticketRows: typeof ticketRows = _tickets.map((_ticket, index) => ({
            values: [
                `@${_ticket.player.handle}`,
                <Button
                    key={index}
                    aria-label={`toggle ticket button for ${_ticket.player.handle}`}
                    caps
                    size="xs"
                    nature={showTicket
                        ? showTicket.id === _ticket.id
                            ? "accept"
                            : "info"
                        : "info"
                    }
                    label={translateButton("ticket")}
                    onClick={(event) => setShowTicket(showTicket
                        ? undefined
                        : _ticket)} 
                    style={{
                        marginLeft: "auto"
                    }}/>
            ]
        }));
        return _ticketRows;
    }, [paginatedTickets, showTicket, translateButton]);
    const ticketHeaders: ITableHeader[] = useMemo(() => {
        const _headers: typeof ticketHeaders = [{
            alignment: "left",
            width: "70%"
        }, {
            alignment: "right",
            width: "30%"
        }];
        return _headers;
    }, []);
    const onSearch = useCallback((search: string): void => setTicketsData((prev) => ({
        ...prev,
        trigger: true,
        qs: `_gameId=${game.id}&handle=${search}`,
        page: 1,
        perPage: 10
    })), [game]);
    const onClearSearch = useCallback(() => {
        setTicketsData((prev) => ({
            ...prev,
            trigger: true,
            qs: `_gameId=${game.id}`,
            page: 1,
            perPage: 10
        }));
        setShowTicket(undefined);
    }, [game]);
    useEffect(() => {
        if (!page) return;
        if (page) {
            setTicketsData((prev) => ({
                ...prev,
                page: page,
                perPage: perPage,
                trigger: true
            }));
        }
    }, [page, perPage]);
    useEffect(() => {
        if (!ticketsCode) return;
        setTicketsData((prev) => ({
            ...prev,
            trigger: false
        }));
    }, [ticketsCode]);
    return (
        <Box
            full
            direction="column"
            style={{
                alignItems: "center",
                padding: 0
            }}>
            <SearchBar
                mode="search"
                searchBy={translateGamePlayer("handle")}
                onSearch={onSearch}
                onClearSearch={onClearSearch}>
                {paginatedResponse && (
                    <Paginated
                        hide={showTicket !== undefined}
                        onPrevious={previousPage}
                        onNext={nextPage}
                        total={total}
                        page={page}
                        perPage={perPage}>
                        <Table
                            aria-label="players table"
                            headers={ticketHeaders || []}
                            rows={ticketRows || []} />
                    </Paginated>
                )}
            </SearchBar>
            {ticketsLoading && <Loader inBox />}
            {showTicket && (
                <TicketDetail
                    game={game}
                    ticket={showTicket} />
            )}
            {ticketRows?.length === 0 && (
                <Text   
                    text={translateGamePlayer("noPlayers")}
                    variant="regular"
                    size="sm"
                    style={{
                        textAlign: "center",
                        margin: "1rem 0"
                    }} />
            )}
        </Box>
    );
};
