import React, { FunctionComponent } from "react";
import { TTeamScoreProps } from "./TeamScore.types";
import { Avatar, Box, Input } from "@common/components";
import { useTheme } from "styled-components";
import { fontSizes } from "@theme";

export const TeamScore: FunctionComponent<TTeamScoreProps> = ({
    team,
    score,
    away,
    style,
    ...props
}) => {
    const theme = useTheme();
    return (
        <Box
            direction="column"
            style={{
                padding: 0,
                ...style
            }}
            {...props}>
            <Box
                aria-label="away-score-box"
                direction="column"
                style={{
                    alignItems: "center",
                    padding: "1rem 0"
                }}>
                <Input
                    modeProps={{
                        "aria-label": `${away ? "away" : "home"} score: ${score}`
                    }}
                    // aria-label={`${away ? "away" : "home"} score`}
                    mode="input"
                    type="number"
                    value={score !== undefined
                        ? score
                        : "-"}
                    onChange={() => null}
                    name="score" 
                    disabled={true}
                    style={{
                        padding: "0.5rem",
                        width: "2.5rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                        fontSize: fontSizes.xs,
                        marginBottom: "1rem",
                        textAlign: "center",
                        color: theme.palette.terminalFg,
                        backgroundColor: theme.palette.terminalBg,
                    }}/>
                <Avatar
                    squared
                    item="team"
                    size="teamBadge"
                    avatar={{
                        avatar: team?.avatar
                    }} />
            </Box>
        </Box>
    );
};