import { TAvatarResponse } from "@common/components/Avatar";
import { IFetchAvatars, TFetchAvatarsData } from "./useFetchAvatars.types";
import { useEffect, useState } from "react";
import { useFetch } from "../useFetch/useFetch";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchAvatars<
    AvatarResponse extends TAvatarResponse
>({
    endpointURI,
    method,
    data,
    auth=true,
    inform=true,
    trigger,
    _avatarId,
    options,
    successMessage,
    ...props
}: TFetchAvatarsData): IFetchAvatars<AvatarResponse> {
    const [fired, setFired] = useState<boolean>();
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "successMessages"
    >("notes", {keyPrefix: "successMessages"});
    const [hookData, setHookData] = useState<TFetchAvatarsData>({
        endpointURI: endpointURI,
        method: method,
        auth: auth,
        inform: inform,
        options: {
            ...options,
            body: data
        },
        successMessage: successMessage
            ? successMessage
            : translateNote("avatarUpdated"),
        ignoreHeaders: true,
        trigger: false
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<FormData, AvatarResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: endpointURI,
                method: method,
                auth: auth,
                inform: inform,
                data: data,
                options: {
                    ...options,
                    body: data
                },
                ignoreHeaders: true,
                successMessage: successMessage
                    ? successMessage
                    : translateNote("avatarUpdated"),
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) setHookData((prev) => ({
                ...prev,
                trigger: false
            }));
        };
    }, [
        fired,
        _avatarId,
        method,
        auth,
        inform,
        data,
        options,
        successMessage,
        endpointURI,
        translateNote
    ]);
    return {
        response,
        body,
        code,
        loading,
        error,
        fetch
    };
}