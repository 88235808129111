import { useCheckUser } from "@auth/hooks/useCheckUser";
import { Loader } from "@common/components";
import { ILayoutRoute, TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";

export const CheckUser: FunctionComponent<TLayoutProps & Partial<ILayoutRoute>> = ({
    children,
    ...props
}) => {
    const {complete} = useCheckUser({trigger: true});
    return (
        <React.Fragment>
            {complete 
                ? children
                : <Loader block />}
        </React.Fragment>
    );
};
