import { TFetchData, useFetch } from "@common/hooks";
import { useEffect, useState } from "react";
import { IFetchValidations, TFetchValidationsData, TValidationNature, validationNatures, validationSuccessMessages } from "./useFetchValidations.types";
import { endpoints } from "@common/endpoints";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export function useFetchValidations({
    token,
    nature,
    trigger,
    ...props
}: TFetchValidationsData): IFetchValidations {
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace, 
        "validations"
    >("notes", {keyPrefix: "validations"});
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<
        TFetchData<Record<string, never>>
    >({
        endpointURI: endpoints.validations.url,
        method: "GET",
        inform: true,
        informGet: true,
        trigger: false,
        auth: true,
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<Record<string, never>, {message: string}>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: endpoints.validations.buildUrl({
                    token: token,
                    nature: validationNatures[nature as TValidationNature]
                }),
                trigger: true,
                successMessage: translateNote(
                    validationSuccessMessages[nature as TValidationNature]
                ),
                auth: true,
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [fired, token, nature, translateNote]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};
