import { addResponseNote, useNotesDispatch } from "@app/context";
import { useCallback, useEffect, useState } from "react";
import { IFetch, TFetchData, TPaginatedResponse } from "./useFetch.types";
import { getFromCookie } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";
import { parseServerResponse } from "@utils/parsers";

export function useFetch<
    ReqBody extends object, 
    ResBody extends (object | string)
>({
    endpointURI,
    method="GET",
    data,
    auth,
    options={},
    trigger=false,
    successMessage,
    inform=true,
    informGet=false,
    ignoreHeaders
}: TFetchData<ReqBody>): IFetch<ResBody> {
    const {t: translateServerResponse} = useTranslation<TTranslationNameSpace>("serverResponses");
    const notesDispatch = useNotesDispatch();
    const [loading, setLoading] = useState<boolean>();
    const [response, setResponse] = useState<Response>();
    const [paginatedResponse, setPaginatedResponse] = useState<TPaginatedResponse<ResBody>>();
    const [body, setBody] = useState<ResBody>();
    const [error, setError] = useState<Error>();
    const [fired, setFired] = useState<boolean>();
    const _fetch = useCallback(async(
        url?: string,
        moreOptions?: RequestInit
    ): Promise<[Response, ResBody | ResBody[]] | undefined[]> => {
        setLoading(true);
        // method
        if (!('method' in options)) options.method = method;
        // headers
        if (!('headers' in options) && !ignoreHeaders) options.headers = {
            "Content-Type": "application/json"
        };
        if (auth) {
            // add x-csrf-token header
            const csrfToken = getFromCookie("csrf_token");
            if (csrfToken) options.headers = {
                ...options.headers,
                "X-CSRF-TOKEN": csrfToken
            };
        };
        // creds
        if (!('credentials' in options)) options.credentials = auth
            ? "include"
            : "omit";
        // body
        if (!('body' in options) && !['GET', 'DELETE'].includes(options.method || 'GET')) options.body = JSON.stringify(data);
        // url
        let uri: string = endpointURI;
        if (url) uri = url;
        let _response: Response; 
        let _body: ResBody;
        try {
            _response = await fetch(uri, Object.assign(options, moreOptions));
            _body = options.method !== "DELETE" 
                ? await _response.json()
                : "";
            if (inform && (options.method !== 'GET' || informGet)) {
                addResponseNote({
                    code: _response.status,
                    message: _response.status >= 200 && _response.status < 300
                        ? successMessage || "success!"
                        : translateServerResponse(
                            ...parseServerResponse(_response.status, _body.toString())
                        )
                }, notesDispatch);
            }
            setBody(_body);
            setResponse(_response);
            setLoading(false);
            const _page = _response.headers.get('pagination-page');
            const _perPage = _response.headers.get('pagination-limit');
            const _total = _response.headers.get('pagination-count');
            setPaginatedResponse({
                page:  _page
                    ? parseInt(_page)
                    : undefined,
                perPage: _perPage
                    ? parseInt(_perPage)
                    : undefined,
                total: _total
                    ? parseInt(_total)
                    : undefined,
                items: _body,
            });
            return [_response, _body];
        } catch(_error) {
            if (inform) {
                addResponseNote({
                    code: 500,
                    message: translateServerResponse(
                        ...parseServerResponse(500, (_error as Error).message)
                    )
                }, notesDispatch);
            }
            setError(_error as Error);
            setLoading(false);
            return [undefined, undefined];
        }
    }, [
        method, 
        auth, 
        data, 
        endpointURI, 
        successMessage, 
        inform, 
        informGet,
        options,
        notesDispatch,
        ignoreHeaders,
        translateServerResponse
    ]);
    useEffect(() => {
        if (trigger) {
            setFired(true);
            setLoading(undefined);
            setResponse(undefined);
            setPaginatedResponse(undefined);
            setBody(undefined);
            setError(undefined);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            _fetch();
            setFired(false);
        }
    }, [fired, _fetch]);
    return {
        response: response,
        code: response?.status,
        body: body,
        loading: loading,
        fetch: _fetch,
        error: error,
        paginatedResponse: paginatedResponse
    };
};
