import React, { FunctionComponent } from "react";
import { TIconBadgeProps } from "./IconBadge.types";
import { Box } from "@common/components/Box";
import { Text } from "@common/components/Text";
import { Icon } from "../Icon";

export const IconBadge: FunctionComponent<TIconBadgeProps> = ({
    text,
    textSize,
    textWeight,
    textVariant,
    textStyles,
    caps=true,
    heading,
    stretch=true,
    horizontal=false,
    nature,
    size,
    ...props
}) => {
    return (
        <Box 
            aria-label="iso-badge"
            direction={horizontal ? "row" : "column"}
            style={{
                width: stretch ? "auto" : "100%",
                flexGrow: 1,
                padding: "0.5rem 0",
                alignItems: "center",
                ...props.style
            }}>
            {heading && (
                <Text
                    text={caps 
                        ? heading?.toUpperCase()
                        : heading}
                    component="p"
                    variant={textVariant}
                    size={textSize || "sm"}
                    style={{
                        marginBottom: "0.2rem",
                        fontWeight: textWeight,
                        ...textStyles
                    }} />
            )}
            <Icon
                style={{
                    marginTop: heading
                        ? 0
                        : horizontal
                            ? 0
                            : "0.5rem",
                    marginRight: horizontal
                        ? "0.5rem"
                        : 0
                }}
                role={"figure"}
                name={nature}
                size={size || "sm"}>
                {props.children}
            </Icon>
            <Text
                text={caps 
                    ? text?.toUpperCase() || ""
                    : text || ""}
                component="p"
                variant={textVariant}
                size={textSize || "sm"}
                style={{
                    marginTop: "0.2rem",
                    fontWeight: textWeight,
                    ...textStyles
                }} />
        </Box>
    );
};