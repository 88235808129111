export function translateDate({
    ms,
    days,
    start=new Date()
}: {
    ms?: number;
    days?: number;
    start?: Date;
}): Date {
    if (typeof start === "string") start = new Date(start);
    if (ms) return new Date(start.getTime() + ms);
    if (days) start.setDate(start.getDate() + days);
    return start;
};

export function getTimeTo(
    date: Date | string,
    lng: "es" | "en" | string = "en"
): string {
    if (!date) return "";
    const msLeft: number = typeof date === "string"
        ? new Date(date).getTime() - new Date().getTime()
        : date.getTime() - new Date().getTime();
    const sec = 1000;
    const min = 60 * sec;
    const hour = 60 * min;
    const day = 24 * hour;
    const week = 7 * day;
    if (msLeft < 0) return lng === "en" 
        ? "0 SECS"
        : "0 SEGS";
    if (msLeft > week) return `${Math.floor(msLeft / week)} ${Math.floor(msLeft / week) === 1
        ? lng === "en" ? "WEEK" : "SEMANA"
        : lng === "en" ? "WEEKS" : "SEMANAS"}`;
    if (msLeft > day) return `${Math.floor(msLeft / day)} ${Math.floor(msLeft / day) === 1
        ? lng === "en" ? "DAY" : "DÍA"
        : lng === "en" ? "DAYS": "DÍAS"}`;
    if (msLeft > hour) return `${Math.floor(msLeft / hour)} ${Math.floor(msLeft / hour) === 1
        ? lng === "en" ? "HOUR" : "HORA"
        : lng === "en" ? "HOURS" : "HORAS"}`;
    if (msLeft >  min) return `${Math.floor(msLeft / min)} ${Math.floor(msLeft / min) === 1
        ? lng === "en" ? "MIN" : "MIN"
        : lng === "en" ? "MINS" : "MINS"}`;
    return `${Math.floor(msLeft / sec)} ${Math.floor(msLeft / sec) === 1
        ? lng === "en" ? "SEC" : "SEG"
        : lng === "en" ? "SECS" : "SEGS"}`;
};

export function hoursLeft(
    targetDate: Date | string,
    onlyNumbers = false
): string {
    const msLeft: number = new Date(targetDate).getTime() - new Date().getTime();
    const sec = 1000;
    const min = 60 * sec;
    const hour = 60 * min;
    if (msLeft < hour) {
        if (onlyNumbers) return "0";
        return "LESS THAN AN HOUR!";
    }
    if (!onlyNumbers) return `${Math.floor(msLeft / hour)} ${Math.floor(msLeft / hour) === 1
        ? "HOUR"
        : "HOURS"}`;
    return Math.floor(msLeft / hour).toFixed(0);
};

export function isImminent(
    date: string | Date
) {
    const _date = new Date(date);
    return _date.getTime() < translateDate({
        start: new Date(),
        ms: 10 * 60 * 1000
    }).getTime();
};
