import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IPickFormInputs, TGameMatchBoxProps } from "./GameMatchBox.types";
import { Box, Button, Form, IField, IFormButton, ScoringStarSet, TScore, Text } from "@common/components";
import { getTimeTo, isImminent } from "@utils/helpers";
import { MatchStatusesEnum } from "@common/enums";
import { TeamScore } from "@competitionsApi/components";
import { useTheme } from "styled-components";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TFetchPicksData, useFetchPicks } from "@game/hooks";
import { fontSizes } from "@theme";
import { TPickResponse } from "@game/types";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const GameMatchBox: FunctionComponent<TGameMatchBoxProps> = ({
    gameMatch,
    competitionsMatch,
    showingPicks,
    setShowPicksFor,
    userPick,
    publicView,
    ...props
}) => {
    const {i18n} = useTranslation();
    const {t: translateText} = useTranslation<
        TTranslationNameSpace
    >("texts");
    const {t: translateButton} = useTranslation<
        TTranslationNameSpace,
        "gameMatchBox"
    >("buttons", {keyPrefix: "gameMatchBox"});
    const {t: translateValidation} = useTranslation<
        TTranslationNameSpace
    >("validations");
    const {t: translateGameMatch} = useTranslation<
        TTranslationNameSpace,
        "gameMatchBox"    
    >("gameBoxes", {keyPrefix: "gameMatchBox"});
    const [pick, setPick] = useState<TPickResponse>();
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<IPickFormInputs>>();
    const theme = useTheme();
    const [pickData, setPickData] = useState<TFetchPicksData>({
        method: "PATCH",
        _pickId: userPick?._id,
        trigger: false,
        inform: true
    });
    const [getPickData, setGetPickData] = useState<TFetchPicksData>({
        method: "GET",
        _pickId: userPick?._id,
        trigger: false,
        auth: true,
        inform: false
    });
    const {
        code: getPickCode,
        loading: getPickLoading,
        body: getPickBody
    } = useFetchPicks(getPickData);
    const {
        code: pickCode,
        loading: pickLoading,
        error: pickError,
        body: pickBody
    } = useFetchPicks(pickData);
    const fields: IField<IPickFormInputs>[] = useMemo(() => {
        if (!pick) return [];
        const _fields: typeof fields = [{
            name: "homeGoals",
            row: 1,
            validations: {
                required: translateValidation(
                    "required.homeTeamScore"
                ),
                validate: (value: string) => parseInt(value) < 0
                    ? translateValidation(
                        "validate.negativeScore"
                    )
                    : true
            },
            defaultValue: pick.homeGoals || 0,
            mode: "input",
            removeStepper: true,
            modeProps: {
                type: "number",
                placeholder: "0",
                disabled: (
                    competitionsMatch.status !== MatchStatusesEnum.PLANNED ||
                    isImminent(competitionsMatch.startsAt) || publicView
                ),
                step: 1,
                min: 0,
                max: 99,
                style: {
                    padding: "0.5rem",
                    fontSize: fontSizes.xs,
                    textAlign: "center",
                    width: "2.5rem",
                    minWidth: "2.5rem",
                    maxWidth: "2.5rem",
                    margin: "0 auto"
                }
            }
        }, {
            name: "awayGoals",
            row: 1,
            validations: {
                required: translateValidation(
                    "required.awayTeamScore"
                ),
                validate: (value: string) => parseInt(value) < 0
                    ? translateValidation(
                        "validate.negativeScore"
                    )
                    : true
            },
            defaultValue: pick.awayGoals || 0,
            mode: "input",
            removeStepper: true,
            modeProps: {
                type: "number",
                placeholder: "0",
                disabled: (
                    competitionsMatch.status !== MatchStatusesEnum.PLANNED ||
                    isImminent(competitionsMatch.startsAt) || publicView
                ),
                step: 1,
                min: 0,
                max: 99,
                style: {
                    padding: "0.5rem",
                    fontSize: fontSizes.xs,
                    textAlign: "center",
                    width: "2.5rem",
                    minWidth: "2.5rem",
                    maxWidth: "2.5rem",
                    margin: "0 auto"
                }
            }
        }];
        return _fields;
    }, [pick, competitionsMatch, publicView, translateValidation]);
    const hasChanged: boolean = useMemo(() => {
        if (!pick) return false;
        if (!currentValues) return false;
        for (const key in currentValues) {
            if (
                currentValues[key as keyof IPickFormInputs] !==
                pick[key as keyof IPickFormInputs]
            ) return true;
        }
        return false;
    }, [pick, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        if (publicView || isImminent(competitionsMatch.startsAt)) return [];
        const _buttons: typeof buttons = [{
            row: 1,
            type: "submit",
            label: translateButton("update"),
            size: "xs",
            caps: true,
            loading: pickLoading || getPickLoading,
            nature: hasChanged ? "accept" : "disabled",
            style: {
                padding: "0.3rem 0.6rem"
            },
            disabled: !hasChanged
        }];
        return _buttons;
    }, [
        publicView, 
        competitionsMatch, 
        pickLoading, 
        getPickLoading, 
        hasChanged,
        translateButton]);
    const onSubmit: SubmitHandler<IPickFormInputs> = useCallback(
        async(payload) => {
            if (publicView) return;
            if (!hasChanged) return;
            setPickData((prev) => ({
                ...prev,
                method: "PATCH",
                _pickId: pick?._id,
                data: payload,
                trigger: true
            }));
        }, [hasChanged, pick, publicView]);
    const started: boolean = useMemo(() => {
        return [
            MatchStatusesEnum.PLAYING, 
            MatchStatusesEnum.FINISHED, 
            MatchStatusesEnum.CONFIRMED
        ].includes(competitionsMatch.status);
    }, [competitionsMatch]);
    useEffect(() => {
        if (!pickCode && !pickError) return;
        setPickData((prev) => ({
            ...prev, 
            trigger: false
        }));
        if (pickCode === 200) {
            setGetPickData((prev) => ({
                ...prev,
                method: "GET",
                _pickId: (pickBody as TPickResponse).id,
                trigger: true
            }));
        }
    }, [pickCode, pickBody, pickError]);
    useEffect(() => {
        if (!userPick) return;
        setPick(userPick);
    }, [userPick]);
    useEffect(() => {
        if (!getPickCode) return;
        setGetPickData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (getPickCode === 200) {
            setPick(getPickBody as TPickResponse);
        }
    }, [getPickCode, getPickBody]);
    useEffect(() => {
        if (!pick) return;
        setCurrentValues({
            awayGoals: pick.awayGoals,
            homeGoals: pick.homeGoals
        });
    }, [pick]);
    return (
        <Box
            full
            loose
            direction="row"
            style={{
                padding: "1rem 0 0 0",
                margin: "1rem 0",
                justifyContent: "space-between",
                alignItems: !pick ? "stretch" : "center",
                borderTop: `dotted 2px ${theme.palette.textSpecial}`
            }}>
            <TeamScore
                team={competitionsMatch.home}
                score={competitionsMatch.homeGoals}
                style={{
                    justifyContent: "start"
                }} />
            <Box
                full
                direction="column"
                style={{
                    padding: "1rem 0",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                <Box
                    full
                    direction="column"
                    style={{
                        alignItems: "center",
                        padding: 0
                    }}>
                    {competitionsMatch.status === MatchStatusesEnum.PLANNED && (
                        <React.Fragment>
                            <Text
                                caps
                                text={translateGameMatch("startsIn")}
                                variant="special"
                                size="xs" />
                            <Text
                                aria-label={`starts at: ${competitionsMatch.startsAt}`}
                                caps
                                text={getTimeTo(
                                    competitionsMatch.startsAt as Date,
                                    i18n.language
                                )}
                                variant="regular"
                                size="xs" />
                        </React.Fragment>
                    )}
                    {started && (
                        <React.Fragment>
                            <Button
                                size="xs"
                                caps
                                label={translateText(`matchStatus.${competitionsMatch.status}`)}
                                style={{
                                    backgroundColor: competitionsMatch.status === MatchStatusesEnum.PLAYING
                                        ? theme.palette.btnOption
                                        : competitionsMatch.status === MatchStatusesEnum.FINISHED
                                            ? theme.palette.btnAction
                                            : theme.palette.btnAccept,
                                    color: competitionsMatch.status === MatchStatusesEnum.PLAYING
                                        ? theme.palette.itemLightText
                                        : competitionsMatch.status === MatchStatusesEnum.FINISHED
                                            ? theme.palette.itemLightText
                                            : theme.palette.itemDarkText                                    
                                }}
                                disabled />
                            {pick?.points !== undefined && (
                                <ScoringStarSet
                                    score={pick.points as TScore}
                                    size="sm"
                                    doubleDeck />
                            )}
                        </React.Fragment>
                    )}
                </Box>
                {/* show pick button */}
                {!pick && (
                    <React.Fragment>
                        {competitionsMatch.startsAt && (
                            isImminent(competitionsMatch.startsAt) ||
                            started
                        ) && (
                            <Box
                                full
                                direction="column"
                                style={{
                                    padding: 0,
                                    alignItems: "center"
                                }}>
                                <Button
                                    caps
                                    label={translateButton("showPicks")}
                                    size="xs"
                                    nature={showingPicks ? "accept" : "disabled"}
                                    pushed={showingPicks}
                                    onClick={(event) =>setShowPicksFor && setShowPicksFor((prev) => (prev 
                                        ? undefined 
                                        : gameMatch && {
                                            _gameMatchId: gameMatch._id,
                                            competitionsMatch: competitionsMatch
                                        }))}
                                    style={{padding: "0.3rem 0.5rem"}} />
                            </Box>
                        )}
                    </React.Fragment>
                )}
                {pick && (
                    <Box
                        full
                        direction="column"
                        style={{
                            padding: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: started ? "0.2rem" : "1rem"
                        }}>
                        <Form
                            name={`pick-form ${pick.id}`}
                            aria-label="pick form"
                            fields={fields}
                            buttons={buttons}
                            onSubmit={onSubmit}
                            setCurrentValues={setCurrentValues} />
                    </Box>
                )}
            </Box>
            <TeamScore
                away
                team={competitionsMatch.away}
                score={competitionsMatch.awayGoals}
                style={{
                    justifyContent: "start"
                }} />
        </Box>
    );
};
