import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Text, Thing, Icon } from "@common/components";
import { Box } from "../Box";
import { NoteNaturesEnum, TNoteNature, TResponseNoteProps, noteNatures } from "./ResponseNote.types";
import { StyledResponseNote } from "./ResponseNote.styles";
import { dismissResponseNote, useNotesDispatch } from "@app/context";
import { useTranslation } from "react-i18next";
import { TTranslationNameSpace } from "@app/i18next/i18n.types";

export const ResponseNote: FunctionComponent<TResponseNoteProps> = ({
    hash,
    code,
    message,
    ...props
}) => {
    const {t: translateNote} = useTranslation<
        TTranslationNameSpace,
        "natures"
    >("notes", {keyPrefix: "natures"});
    const notesDispatch = useNotesDispatch();
    const nature: TNoteNature  = useMemo(() => {
        const _code = Math.floor(code / 100).toString();
        return noteNatures[_code];
    }, [code]);
    useEffect(() => {
        setTimeout(() => {
            dismissResponseNote(hash, notesDispatch);
        }, 3000);
    }, [hash, notesDispatch]);
    const noteLabel: string = useMemo(() => {
        return `${(Object.entries(NoteNaturesEnum).find(
            ([key, value]) => value === nature.text
        ) as [NoteNaturesEnum, string])[0].toLowerCase()}-note`;
    }, [nature]);
    return (
        <StyledResponseNote
            aria-label={noteLabel} 
            {...props}>
            <Box 
                direction="row" 
                loose
                style={{
                    justifyContent: "space-between",
                    paddingTop: "1rem"
                }}>
                <Text 
                    size="sm" 
                    variant={nature.variant}
                    text={translateNote(nature.text)} />
                <Icon 
                    onClick={(e: React.MouseEvent) => dismissResponseNote(hash, notesDispatch)}
                    size="mini"
                    style={{
                        cursor: "pointer"
                    }}>
                    <Thing nature={nature.iconNature} />
                </Icon>
            </Box>
            <Box style={{paddingTop: "0"}}>
                <Text text={message} />
            </Box>
        </StyledResponseNote>
    );
};
