import { endpointsURI } from "./endpoint.types";
import { TEndpoint } from "@common/types";

export const apiURL: string = process.env.API_URL || 'http://localhost:5001/api';
export const competitionsApiURL: string = process.env.COMPETITIONS_API_URL || 'http://localhost:5000/api';

export const endpoints: Record<
    keyof typeof endpointsURI,
    TEndpoint
> = {
    users: {
        url: `${apiURL}/${endpointsURI.users}`,
        buildUrl({
            _userId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.users}`;
            if (_userId) str += `/${_userId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    whoAmI: {
        url: `${apiURL}/${endpointsURI.whoAmI}`,
        buildUrl() {
            return `${apiURL}/${endpointsURI.whoAmI}`;
        }
    },
    logins: {
        url: `${apiURL}/${endpointsURI.logins}`,
        buildUrl() {
            return `${apiURL}/${endpointsURI.logins}`;
        }
    },
    games: {
        url: `${apiURL}/${endpointsURI.games}`,
        buildUrl({
            _gameId,
            qs,
            standings
        }) {
            let str = `${apiURL}/${endpointsURI.games}`;
            if (_gameId) str += `/${_gameId}${standings
                ? "/standings"
                : ""}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    tickets: {
        url: `${apiURL}/${endpointsURI.tickets}`,
        buildUrl({
            _ticketId,
            qs,
            picks,
            page="1",
            perPage="10"
        }) {
            let str = `${apiURL}/${endpointsURI.tickets}`;
            if (_ticketId) str += `/${_ticketId}${picks
                ? "/picks"
                : ""}`;
            if (qs) str += `?${qs}&page=${page}&perPage=${perPage}`;
            return str;
        }
    },
    gameMatches: {
        url: `${apiURL}/${endpointsURI.gameMatches}`,
        buildUrl({
            _gameMatchId,
            qs,
            picks,
            page="1",
            perPage="10"
        }) {
            let str = `${apiURL}/${endpointsURI.gameMatches}`;
            if (_gameMatchId) str += `/${_gameMatchId}${picks
                ? "/picks"
                : ""}`;
            if (qs) str += `?${qs}&page=${page}&perPage=${perPage}`;
            return str;
        }
    }, 
    competitionsApiMatches: {
        url: `${competitionsApiURL}/${endpointsURI.competitionsApiMatches}`,
        buildUrl({
            _matchId,
            qs
        }) {
            let str = `${competitionsApiURL}/${endpointsURI.competitionsApiMatches}`;
            if (_matchId) str += `/${_matchId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    picks: {
        url: `${apiURL}/${endpointsURI.picks}`,
        buildUrl({
            _pickId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.picks}`;
            if (_pickId) str += `/${_pickId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    }, 
    userAvatars: {
        url: `${apiURL}/${endpointsURI.userAvatars}`,
        buildUrl({
            _avatarId
        }) {
            let str = `${apiURL}/${endpointsURI.userAvatars}`;
            if (_avatarId) str += `/${_avatarId}`;
            return str;
        }
    },
    validations: {
        url: `${apiURL}/${endpointsURI.validations}`,
        buildUrl({
            token,
            nature
        }) {
            let str = `${apiURL}/${endpointsURI.validations}`;
            if (!token || !nature) throw new Error("no token or nature for validation... 😐");
            str += `?token=${token}&nature=${nature}`;
            return str;
        }
    },
    passwordResetRequests: {
        url: `${apiURL}/${endpointsURI.passwordResetRequests}`,
        buildUrl() {
            const str = `${apiURL}/${endpointsURI.passwordResetRequests}`;
            return str;
        }
    }
};
