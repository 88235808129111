import { TSize } from "@theme";
import { ComponentPropsWithRef } from "react";
import { TThingProps } from "./Thing";
import { TDonkeyProps } from "./Donkey";
import { TArrowProps } from "./Arrow";
import { TTrophyProps } from "./Trophy";

export type TIconSize = TSize 
    | "mini" 
    | "huge" 
    | "giant" 
    | "flag"
    | "teamBadge";

export type TIconProps = ComponentPropsWithRef<"svg"> & {
    size?: TIconSize;
    nature?: TIconNature["nature"]; // TThingProps["nature"];
};

export type TIconNature = TThingProps 
    | TDonkeyProps | TArrowProps | TTrophyProps;

export const iconSizes: Record<TIconSize, string> = {
    xxs: "8px",
    mini: "16px",
    xs: "24px",
    sm: "32px",
    md: "48px",
    lg: "64px",
    xl: "96px",
    xxl: "128px",
    huge: "256px",
    giant: "512px",
    flag: "80px",
    teamBadge: "80px"
};
