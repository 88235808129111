import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TUserPicksProps } from "./UserPIcks.types";
import { Box, Loader } from "@common/components";
import { TPickResponse } from "@game/types";
import { TFetchPicksData, useFetchPicks } from "@game/hooks";
import { GameMatchBox } from "../GameMatchBox";
import { TGameMatchBoxProps } from "../GameMatchBox/GameMatchBox.types";

export const UserPicks: FunctionComponent<TUserPicksProps> = ({
    // fetch to get @/picks?_ticketId=
    ticket,
    game,
    competitionsMatches,
    ...props
}) => {
    const [picks, setPicks] = useState<TPickResponse[]>();
    const [picksData, setPicksData] = useState<TFetchPicksData>({
        method: "GET",
        qs: `_ticketId=${ticket?._id}`,
        trigger: false
    });
    const {
        code: picksCode,
        body: picksBody
    } = useFetchPicks(picksData);
    const picksAndMatches: Required<
        Pick<
            TGameMatchBoxProps, 
            "competitionsMatch" | "userPick"
    >>[] | undefined = useMemo(() => {
        if (!competitionsMatches) return;
        const _picksAndMatches: typeof picksAndMatches = competitionsMatches.map(
            (_competitionsMatch) => {
                const _gameMatch = game.matches.find(
                    (_gm) => _gm.match._matchId === _competitionsMatch._id
                );
                return {
                    competitionsMatch: _competitionsMatch,
                    userPick: picks?.find(_pk => _pk._gameMatchId === _gameMatch?._id) as TPickResponse
                };
            }
        );
        return _picksAndMatches;
    }, [picks, competitionsMatches, game]);
    useEffect(() => {
        if (!ticket) return;
        setPicksData((prev) => ({
            ...prev,
            qs: `_ticketId=${ticket?._id}`,
            trigger: true,
            options: {
                cache: "default"
            }
        }));
    }, [ticket]);
    useEffect(() => {
        if (!picksCode) return;
        setPicksData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (picksCode === 200) {
            setPicks(picksBody as TPickResponse[]);
        }
    }, [picksCode, picksBody]);
    return (
        <React.Fragment>
            {!picksAndMatches ? ( 
                <Loader inBox />
            ) : (
                <Box
                    full
                    direction="column"
                    style={{
                        padding: 0
                    }}>
                    {picksAndMatches.map((pick, index) => (
                        <GameMatchBox
                            key={index}
                            competitionsMatch={pick.competitionsMatch}
                            userPick={pick.userPick} />
                    ))}
                </Box>
            )}
        </React.Fragment>
    );
};
